import { setFocus } from '@noriginmedia/norigin-spatial-navigation';
import List from 'components/List/GenericList';
import ARTWORK_SIZES from 'constants/artwork-sizes';
import ARTWORK_TYPES from 'constants/artwork-types';
import CONTENT_TYPE_TO_ARTWORK_TYPE from 'constants/content-type-to-artwork-type';
import CONTENT_TYPES from 'constants/content-types';
import Button from 'elements/Button';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import useBackButton from 'hooks/useBackButton';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  addToFavorites,
  fetchMeApps,
  getApp,
  removeFromFavorites
} from 'store/features/apps/actions';
// import { hideLoader, setLoaderProgress } from 'store/features/loader/actions';
import { ActionSource, executeAction } from 'utils/actions';
import artworkImage from 'utils/artwork-image';
import resizeImage from 'utils/resize-image';
import { hideMenu } from '../../store/features/menu/menuSlice';
import {
  ArtworkImage,
  ButtonsWrapper,
  DetailsDescriptionWrapper,
  DetailsInnerWrapper,
  DetailsWrapper,
  ListWrapper,
  LogoImage,
  LogoImageWrapper,
  NameGenreDescriptionText,
  NameGenreDescriptionWrapper,
  Synopsis
} from './Details.styles';
import { useTranslation } from 'react-i18next';

interface AppListProps {
  content_list_app_name?: string;
  content_list_app_type?: string;
}

const AppList: FC<AppListProps> = ({
  content_list_app_name,
  content_list_app_type
}) => {
  if (!content_list_app_name || !content_list_app_type) {
    return null;
  }

  return (
    <ListWrapper>
      <List
        id={'app-details-list'}
        prefix={'details'}
        isBelowFocused={false}
        listTitle={''}
        data={{
          id: 'app-details-list',
          name: content_list_app_name,
          type: content_list_app_type
        }}
        onFocus={() => console.log('==> focus list')}
        listIndex={1}
        type={ARTWORK_TYPES.LANDSCAPE}
      />
    </ListWrapper>
  );
};

interface DetailsProps {
  type: CONTENT_TYPES;
  contentId: any;
}

const Details: FC<DetailsProps> = ({ type, contentId, ...props }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectedApp = useAppSelector((state) => state.apps.selectedApp);

  const isFavorite = useAppSelector((state) => {
    return state.apps.favorites.data.collection.find(
      (app: any) => app.id === Number(contentId)
    );
  });
  const isLoading = useAppSelector((state) => {
    return state.apps.selectedApp.isLoading;
  });
  const dispatch = useAppDispatch();

  useBackButton(() => {
    navigate(-1);
  }, true);

  useEffect(() => {
    dispatch(hideMenu({ hintVisible: false }));
    // @ts-ignore
    dispatch(getApp(contentId));
  }, [contentId]);

  useEffect(() => {
    if (!isLoading) {
      setFocus('app-fav-button');
    }
  }, [isLoading]);

  const artworkType = CONTENT_TYPE_TO_ARTWORK_TYPE[CONTENT_TYPES.app];

  const src = resizeImage(artworkImage(selectedApp) || null, {
    width: String(Math.round(ARTWORK_SIZES[artworkType]?.width * 2)),
    height: String(Math.round(ARTWORK_SIZES[artworkType]?.height * 2))
  });

  const handleFavorites = (id: number, add: boolean) => {
    if (isLoading) {
      return;
    }
    const request = add
      ? dispatch(addToFavorites({ id }))
      : dispatch(removeFromFavorites({ id }));
    request.then(() => {
      dispatch(fetchMeApps()).then(() => {
        if (add) {
          navigate(`/`, {
            state: { editMode: true, appId: id }
          });
        }
      });
    });
  };

  /**  TODO: This is to ensure that details are painted only if selected app
   * coincides with the contentId. This issue happens because navigating though the
   * app baners outside of details updates selected app, and when this component
   * is rendered, it tries to paint the content for that app, before waiting for details
   * about the actually selected app to be fetched.
   */
  const isSelectedApp = selectedApp && selectedApp.id === Number(contentId);

  return isSelectedApp ? (
    <DetailsWrapper
      bg={selectedApp?.background_image || ''}
      key={`app-${contentId}`}
    >
      <DetailsInnerWrapper>
        <DetailsDescriptionWrapper>
          {selectedApp.logo ? (
            <LogoImageWrapper>
              <LogoImage src={selectedApp.logo} alt="Banner Logo" />
            </LogoImageWrapper>
          ) : (
            <ArtworkImage src={src} type={artworkType} />
          )}
          <Synopsis>{selectedApp?.description || ''}</Synopsis>
          <NameGenreDescriptionWrapper>
            <NameGenreDescriptionText>
              {selectedApp?.name || ''}
            </NameGenreDescriptionText>
            <NameGenreDescriptionText>
              {selectedApp?.classification?.description || ''}
            </NameGenreDescriptionText>
          </NameGenreDescriptionWrapper>
        </DetailsDescriptionWrapper>
        <ButtonsWrapper>
          <Button
            title={
              isFavorite
                ? t('app.details.removeFromFavorites')
                : t('app.details.addToFavorites')
            }
            focusKey={'app-fav-button'}
            // @ts-ignore
            handleClick={() => handleFavorites(selectedApp.id, !isFavorite)}
          />
          <Button
            title={t('app.details.open')}
            focusKey={'app-open-button'}
            handleClick={() =>
              executeAction('launch-app', {
                app: selectedApp,
                source: ActionSource.STORE
              })
            }
          />
        </ButtonsWrapper>
        <AppList
          // @ts-ignore
          content_list_app_name={selectedApp.content_list_app_name}
          // @ts-ignore
          content_list_app_type={selectedApp.content_list_app_type}
        />
      </DetailsInnerWrapper>
    </DetailsWrapper>
  ) : null;
};

export default Details;
