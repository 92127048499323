import { createSlice } from '@reduxjs/toolkit';
import { ACTION_TYPES } from 'store/features/modals/actions';

const initialState = {
  modal: {
    type: '',
    data: null,
    returnFocusKey: ''
  }
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => action.type === ACTION_TYPES.SHOW_MODAL,
        (state, action) => {
          state.modal.type = action.payload.modalType;
          state.modal.data = action.payload.modalData;
          state.modal.returnFocusKey = action.payload.returnFocusKey;
        }
      )
      .addMatcher(
        (action) => action.type === ACTION_TYPES.REMOVE_MODAL,
        (state) => {
          state.modal = {
            type: '',
            data: null,
            returnFocusKey: ''
          };
        }
      );
  }
});

export default modalsSlice.reducer;
