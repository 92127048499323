import React from 'react';
import theme from 'styles/theme';
import scaleSize from 'utils/scale-size';

type IconProps = {
  isFocused: boolean;
};

const SettingsIcon: React.FC<IconProps> = ({ isFocused }) => {
  const size = scaleSize(32);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
    >
      <mask
        id="mask0_212_177"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="-1"
        y="-1"
        width="34"
        height="34"
      >
        <path
          d="M33 -1H-1V33H33V-1Z"
          fill={isFocused ? theme.colors.white : theme.colors.white06}
        />
      </mask>
      <g mask="url(#mask0_212_177)">
        <mask
          id="mask1_212_177"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="-1"
          y="-1"
          width="34"
          height="34"
        >
          <path
            d="M33 -1H-1V33H33V-1Z"
            fill={isFocused ? theme.colors.white : theme.colors.white06}
          />
        </mask>
        <g mask="url(#mask1_212_177)">
          <path
            d="M24.6084 11.5906L21.4634 8.45625L27.7428 2.1875H21.4634L18.329 5.32188V11.5906L21.4634 14.725H27.7428L30.8771 11.5906V5.32188L24.6084 11.5906Z"
            fill={isFocused ? theme.colors.white : theme.colors.white06}
          />
          <path
            d="M12.0114 17.9157L2.60507 27.322L5.738 30.4549L15.1443 21.0486L12.0114 17.9157Z"
            fill={isFocused ? theme.colors.white : theme.colors.white06}
          />
          <path
            d="M15.3648 11.8168L12.2324 14.9502L27.742 30.4544L30.8743 27.3209L15.3648 11.8168Z"
            fill={isFocused ? theme.colors.white : theme.colors.white06}
          />
          <path
            d="M4.25792 3.48519L1.125 6.61812L9.24656 14.7397L12.3795 11.6068L4.25792 3.48519Z"
            fill={isFocused ? theme.colors.white : theme.colors.white06}
          />
          <path
            d="M13.4309 10.5494L7.04523 4.15313L9.01085 2.1875H16.6502L13.4309 10.5494Z"
            fill={isFocused ? theme.colors.white : theme.colors.white06}
          />
        </g>
      </g>
    </svg>
  );
};

export default SettingsIcon;
