import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isBlurred: false,
  color: '#000000'
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleBlur: (state, { payload }) => {
      state.isBlurred = payload;
    },
    setColor: (state, { payload }) => {
      state.color = payload;
    }
  },
  extraReducers: (builder) => {}
});

export default appSlice.reducer;
