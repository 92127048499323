import api from 'api/api';

export const getChannels = () => {
  return api({
    endpoint: '/channels',
    queryParams: {
      page: 1,
      per_page: 100 //TODO add lazy loading, remove hardcoded value
    }
  });
};

export const getChannelsGenres = () => {
  return api({
    endpoint: '/genres',
    queryParams: {
      type: 'channel',
      page: 1,
      per_page: 100 //TODO add lazy loading, remove hardcoded value
    }
  });
};

export const getChannelPrograms = (channelId: string) => {
  return api({
    endpoint: `/contents/${channelId}/contents`,
    queryParams: { type: 'channel_program' }
  });
};

export const getChannelsByGenre = (genreId: number) => {
  return api({
    endpoint: `/genres/${genreId}/contents`,
    queryParams: { type: 'channels' }
  });
};

export const getFavoriteChannels = () => {
  return api({
    endpoint: '/me/favorites',
    withAuthHeader: true,
    queryParams: { type: 'channel' }
  });
};

export const addChannelToFavorite = (channelId: number) => {
  return api({
    method: 'post',
    endpoint: '/me/favorites',
    withAuthHeader: true,
    queryParams: {
      content_id: channelId.toString(),
      like: true
    }
  });
};

export const addChannelToWatched = (
  channelId: number,
  time_in_seconds: number
) => {
  return api({
    method: 'post',
    endpoint: '/me/watcheds',
    withAuthHeader: true,
    queryParams: {
      content_id: channelId.toString(),
      time_in_seconds
    }
  });
};

export const deleteChannelFromFavorite = (channelId: number) => {
  return api({
    method: 'delete',
    endpoint: '/me/favorites',
    withAuthHeader: true,
    queryParams: {
      content_id: channelId.toString()
    }
  });
};

export const getEPGByGenre = (
  genre: number,
  date: string,
  hour: number,
  page?: number,
  perPage?: number
) => {
  return api({
    endpoint: '/epg',
    queryParams: {
      genre,
      date: date,
      hour: hour,
      page: page || 1,
      per_page: perPage || 100
    }
  });
};

export const getProgramsByChannel = (
  channelId: number,
  date: string,
  hour: number
) => {
  return api({
    endpoint: `/epg/${channelId}`,
    queryParams: {
      channelId: String(channelId),
      date: date,
      hour: hour
    }
  });
};
