import { useEffect } from 'react';

export const useUserInteraction = (callback: () => void) => {
  useEffect(() => {
    const handleUserInteraction = () => {
      callback();
    };

    window.addEventListener('keyup', handleUserInteraction);
    window.addEventListener('mousemove', handleUserInteraction);

    return () => {
      window.removeEventListener('keyup', handleUserInteraction);
      window.removeEventListener('mousemove', handleUserInteraction);
    };
  });

  return;
};

export default useUserInteraction;
