import React from 'react';
import theme from 'styles/theme';
import scaleSize from 'utils/scale-size';

type IconProps = {
  isFocused: boolean;
};

const SearchIcon: React.FC<IconProps> = ({ isFocused }) => {
  const size = scaleSize(26);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g>
        <mask
          id="mask0_1276_7460"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="26"
          height="26"
        >
          <path
            d="M26 0H0V26H26V0Z"
            fill={isFocused ? theme.colors.white : theme.colors.white06}
          />
        </mask>
        <g mask="url(#mask0_1276_7460)">
          <mask
            id="mask1_1276_7460"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="26"
            height="26"
          >
            <path
              d="M26 0H0V26H26V0Z"
              fill={isFocused ? theme.colors.white : theme.colors.white06}
            />
          </mask>
          <g mask="url(#mask1_1276_7460)">
            <path
              d="M16.2501 -1.51022e-05C14.5073 -0.00232843 12.7959 0.463129 11.2944 1.34779C9.7929 2.23244 8.55633 3.50387 7.71377 5.02939C6.8712 6.55491 6.45351 8.27861 6.50427 10.0206C6.55504 11.7626 7.0724 13.4591 8.00237 14.9329L0.476186 22.4607C0.325221 22.6116 0.205466 22.7908 0.123761 22.988C0.0420556 23.1852 0 23.3966 0 23.61C0 23.8235 0.0420556 24.0348 0.123761 24.232C0.205466 24.4292 0.325221 24.6084 0.476186 24.7593L1.24237 25.5247C1.54711 25.8293 1.96036 26.0005 2.39125 26.0005C2.82214 26.0005 3.23539 25.8293 3.54013 25.5247L11.0663 17.9969C12.3455 18.8036 13.7948 19.3017 15.2997 19.4518C16.8046 19.6019 18.3238 19.3999 19.7371 18.8617C21.1504 18.3235 22.4192 17.4639 23.4431 16.3509C24.467 15.2378 25.2179 13.9019 25.6365 12.4486C26.0551 10.9953 26.1299 9.46463 25.8549 7.97748C25.58 6.49034 24.9629 5.08754 24.0524 3.87999C23.1419 2.67245 21.9629 1.69326 20.6088 1.01986C19.2546 0.346456 17.7624 -0.00270417 16.2501 -1.51022e-05ZM16.2501 16.25C14.9645 16.25 13.7078 15.8688 12.6389 15.1545C11.5699 14.4403 10.7368 13.4251 10.2448 12.2374C9.75287 11.0497 9.62415 9.74277 9.87496 8.4819C10.1258 7.22102 10.7448 6.06283 11.6539 5.15379C12.5629 4.24475 13.7211 3.62568 14.982 3.37488C16.2429 3.12408 17.5498 3.2528 18.7375 3.74477C19.9252 4.23674 20.9404 5.06986 21.6546 6.13878C22.3688 7.2077 22.7501 8.46441 22.7501 9.74998C22.7481 11.4733 22.0627 13.1255 20.8441 14.344C19.6255 15.5626 17.9734 16.248 16.2501 16.25Z"
              fill={isFocused ? theme.colors.white : theme.colors.white06}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SearchIcon;
