import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';
import { convertKeysToCamelCase } from '../../../utils/helpers';
import { RootState } from 'store/configure-store';

interface Params {
  genreId?: string;
  listId?: string;
  channelId?: string;
  page?: number;
  perPage?: number;
}

interface Pagination {
  currentPage: number;
  nextPage: number | null;
  perPage: number;
  totalCount: number;
  totalPages: number;
  prevPage: number;
}

interface FetchGridResponse {
  collection: Array<any>;
  pagination: Pagination;
}

type FetchGridArgument = {
  type: string;
  params: Params;
};

interface GridContent {
  id: string;
}

interface GridPagination {
  currentPage: number;
  nextPage: number | null;
  prevPage: number | null;
  totalPages: number;
  totalCount: number;
  perPage: number;
}

const getEndpoint = (type: string, params: Params): string => {
  if (params.genreId) {
    return `/genres/${params.genreId}/contents`;
  } else if (params.listId) {
    return `/lists/${params.listId}/items`;
  } else if (params.channelId) {
    return `/contents/${params.channelId}/contents`;
  }
  return '/contents';
};

export const fetchGrid = createAsyncThunk<
  { collection: GridContent[]; pagination: GridPagination },
  FetchGridArgument,
  { state: RootState }
>(
  'grids/fetchGrid',
  async (
    { type, params }: FetchGridArgument,
    { rejectWithValue }
  ): Promise<FetchGridResponse | ReturnType<typeof rejectWithValue>> => {
    const endpoint = getEndpoint(type, params);
    try {
      const res = await api({
        endpoint,
        queryParams: {
          type,
          page: params.page || 1,
          per_page: params.perPage || 50
        }
      });
      return {
        collection: res.collection,
        pagination: convertKeysToCamelCase(res.pagination)
      };
    } catch (error: any) {
      if (error && error.message) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred.');
      }
    }
  }
);
