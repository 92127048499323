import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';
import { RootState } from 'store/configure-store';

export const tvSignIn = createAsyncThunk<
  { deviceUid: string; id: number; email: string; authToken?: string },
  string,
  { state: RootState }
>('auth/tvSignIn', async (deviceUid: string, { rejectWithValue }) => {
  try {
    const response = await api({
      method: 'post',
      endpoint: '/tv_sign_in',
      queryParams: {
        device_uuid: deviceUid
      }
    });

    return { deviceUid, ...response };
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.errors[0] || error.message);
  }
});

export const tvSignUp = createAsyncThunk<
  { deviceUid: string; id: number; email: string; authToken?: string },
  string,
  { state: RootState }
>('auth/tvSignUp', async (deviceUid: string, { rejectWithValue }) => {
  try {
    const response = await api({
      method: 'post',
      endpoint: '/tv_sign_up',
      queryParams: {
        device_uuid: deviceUid
      }
    });
    return { deviceUid, ...response };
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.errors[0] || error.message);
  }
});
