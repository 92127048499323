import {
  pause as pauseFocusableNavigation,
  resume as resumeFocusableNavigation,
  setFocus
} from '@noriginmedia/norigin-spatial-navigation';
import { searchCategories } from 'components/Search/SearchCategories';
import CONTENT_TYPES from 'constants/content-types';
import { Direction } from 'constants/rc-direction';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import useBackButton from 'hooks/useBackButton';
import useNavigation from 'hooks/useNavigation';
import { useOSKBVisibility } from 'hooks/useOSKBVisibility';
import usePathname from 'hooks/usePathname';
import usePrevious from 'hooks/usePrevious';
import useTabVisibility from 'hooks/useTabVisibility';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetFocusData } from 'store/features/focuses/focusesSlice';
import { fetchGenres } from 'store/features/genres/action';
import { removeListsByPrefix } from 'store/features/lists/actions';
import { hideLoader, setLoaderProgress } from 'store/features/loader/actions';
import { hideMenu, showMenu } from 'store/features/menu/menuSlice';
import theme from 'styles/theme';
import {
  ClearButton,
  GridWrapper,
  StyledFocusableInput,
  StyledFocusableInputWrapper,
  StyledGrid,
  StyledInput,
  StyledListsContainer,
  Wrapper
} from './Search.styles';
import { useTranslation } from 'react-i18next';

const { CloseIcon } = theme.icons;
const MIN_QUERY_LENGTH = 2;
const INPUT_FOCUS_KEY = 'input-focus-key';
const SEARCH_PREFIX = 'Search_';

const queryReady = (query: string): boolean => {
  return !!query && query.trim().length > MIN_QUERY_LENGTH;
};

function Search() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const genres = useAppSelector((state) => state.genres);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsQuery = searchParams.get('q') ?? '';

  const [query, setQuery] = useState(paramsQuery);
  const previousQuery = usePrevious(query);

  const { pathname } = usePathname();
  const { focusLast } = useNavigation({});
  const { isTabVisible } = useTabVisibility();
  const { isKeyboardVisible } = useOSKBVisibility();

  function exitSearchScreen() {
    inputRef.current?.blur();
    navigate(-1);
  }

  useEffect(() => {
    if (!isTabVisible) {
      exitSearchScreen();
    }
  }, [isTabVisible]);

  useEffect(() => {
    if (inputRef.current && isKeyboardVisible) {
      pauseFocusableNavigation();
    } else {
      resumeFocusableNavigation();
    }
  }, [isKeyboardVisible]);

  function backHandler(e: KeyboardEvent) {
    if (!isKeyboardVisible) {
      e.preventDefault();
      exitSearchScreen();
    }
  }

  useBackButton(backHandler, true, [isKeyboardVisible]);

  useEffect(() => {
    paramsQuery && setQuery(paramsQuery);
    paramsQuery && setSearchParams({ q: paramsQuery }, { replace: true });
  }, [paramsQuery]);

  useEffect(() => {
    if (previousQuery && query !== previousQuery) {
      dispatch(resetFocusData({ page: pathname }));
      dispatch(
        removeListsByPrefix({ prefix: `${SEARCH_PREFIX}${previousQuery}_` })
      );
    }
  }, [query, previousQuery]);

  useEffect(() => {
    dispatch(setLoaderProgress(100));
    dispatch(hideLoader());
    dispatch(fetchGenres({ type: CONTENT_TYPES.movie }));
    focusLast();

    dispatch(hideMenu({ hintVisible: false }));
    onFocus();

    return () => {
      dispatch(showMenu());
    };
  }, []);

  function onInput(event: React.ChangeEvent<HTMLInputElement>) {
    const query = event.target.value;
    setQuery(query);
    setSearchParams({ q: query }, { replace: true });
  }

  function onInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    const isInputSelectionValid =
      inputRef?.current && inputRef?.current?.selectionStart !== null;
    const cursorPosition = inputRef?.current?.selectionStart ?? 0;

    const ignoreInputEvent =
      !isKeyboardVisible && !isInputSelectionValid && query.length <= 0;

    const ignoreCursorMove =
      cursorPosition <= 0 || cursorPosition >= query.length;

    if (ignoreInputEvent || ignoreCursorMove) {
      return;
    }

    function moveInputCursor(shift: number) {
      event.preventDefault();

      inputRef.current?.setSelectionRange(
        cursorPosition + shift,
        cursorPosition + shift
      );
    }

    const keyHandlers: { [key: string]: () => void } = {
      ArrowLeft: () => moveInputCursor(-1),
      ArrowRight: () => moveInputCursor(1)
    };
    const keyHandlerCallback = keyHandlers[event.key];

    keyHandlerCallback?.();
  }

  function shouldAllowArrowPress(directionStr: string) {
    const direction = Direction[directionStr as keyof typeof Direction];
    const cursorPosition = inputRef.current?.selectionStart ?? -1;
    const isCursorBewteenStartAndEndOfQuery =
      cursorPosition >= 0 && cursorPosition <= query.length;

    const shouldPreventArrowPress =
      direction === Direction.RIGHT &&
      isKeyboardVisible &&
      query.length > 0 &&
      isCursorBewteenStartAndEndOfQuery;

    return !shouldPreventArrowPress;
  }

  function onFocus() {
    if (inputRef.current && document.activeElement !== inputRef.current) {
      inputRef.current.focus();
    }
  }

  function onBlur() {
    if (inputRef.current && !isKeyboardVisible) {
      inputRef.current.blur();
    }
  }

  function onManualFocus() {
    setFocus(INPUT_FOCUS_KEY);
  }

  function clearInput() {
    setQuery('');
    setSearchParams({ q: '' }, { replace: true });
    onFocus();
  }

  return (
    <Wrapper>
      <StyledFocusableInputWrapper>
        <StyledFocusableInput
          onFocus={onFocus}
          onBlur={onBlur}
          onArrowPress={shouldAllowArrowPress}
          focusKey={INPUT_FOCUS_KEY}
        >
          <StyledInput
            onFocus={onManualFocus}
            ref={inputRef}
            type="text"
            value={query}
            onChange={onInput}
            onKeyDown={onInputKeyDown}
            placeholder={t('search.input.placeholder')}
          />
        </StyledFocusableInput>
        <ClearButton onEnterPress={clearInput}>
          <CloseIcon />
        </ClearButton>
      </StyledFocusableInputWrapper>
      <GridWrapper>
        {!queryReady(query) && (
          <StyledGrid
            preventScroll
            showArtworkTitle
            gridData={genres}
            type={CONTENT_TYPES.genre}
          />
        )}
        {queryReady(query) && (
          <StyledListsContainer
            shouldToggleMenuVisibility={false}
            prefix={`${SEARCH_PREFIX}${query}_`}
            defaultFocusKey={INPUT_FOCUS_KEY}
            query={query}
            data={searchCategories}
            key={query}
          />
        )}
      </GridWrapper>
    </Wrapper>
  );
}

export default Search;
