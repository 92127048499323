import CONTENT_TYPES from 'constants/content-types';

interface Item {
  image?: {
    artwork_portrait?: string;
    artworkPortrait?: string;
    artwork_landscape?: string;
    artwork_landscape_focused?: string;
    artworkLandscape?: string;
    default?: string;
    high?: string;
    image?: string;
  };
  images?: {
    artwork_portrait?: string;
    artworkPortrait?: string;
    artwork_landscape?: string;
    artwork_landscape_focused?: string;
    artworkLandscape?: string;
    default?: string;
    high?: string;
    image?: string;
  };
  icon?: string;
  type?: string;
}

const DEFAULT_CONTENT_TYPES = [
  CONTENT_TYPES.movie,
  CONTENT_TYPES.season,
  CONTENT_TYPES.channel
];
const DEFAULT_SERVICE_TYPES = [CONTENT_TYPES.service, CONTENT_TYPES.app];

const getDefaultImage = (item?: Item, focusedImage?: boolean) => {
  const icon = item?.icon;
  const images = item?.images || item?.image;
  if (!images && !icon) {
    return null;
  }

  if (focusedImage) {
    return images?.artwork_landscape_focused;
  }

  if (DEFAULT_SERVICE_TYPES.includes(item.type as CONTENT_TYPES)) {
    return icon;
  } else if (DEFAULT_CONTENT_TYPES.includes(item.type as CONTENT_TYPES)) {
    return images?.artwork_portrait || images?.artworkPortrait;
  }

  return (
    images?.artwork_landscape ||
    images?.artworkLandscape ||
    images?.high ||
    images?.image
  );
};

export default getDefaultImage;
