import React from 'react';

type IconProps = {
  isFocused: boolean;
};

const WatchTVIcon: React.FC<IconProps> = ({ isFocused }) => {
  return (
    <svg
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_747_1382)">
        <mask
          id="mask0_747_1382"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="76"
          height="76"
        >
          <path d="M76 0H0V76H76V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_747_1382)">
          <path
            d="M0 12.9984V46.2484C0.00751795 48.7656 1.01083 51.1776 2.7908 52.9576C4.57076 54.7376 6.98276 55.7408 9.5 55.7484H66.5C69.0172 55.7408 71.4293 54.7376 73.2092 52.9576C74.9892 51.1776 75.9925 48.7656 76 46.2484V12.9984H0Z"
            fill={isFocused ? 'white' : 'gray'}
          />
          <path
            d="M47.538 59.812C46.4241 58.5865 44.8661 58.2516 41.5625 58.2516H34.4375C31.1339 58.2516 29.6495 58.5865 28.538 59.812L26.125 63.0016H49.875L47.538 59.812Z"
            fill={isFocused ? 'white' : 'gray'}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_747_1382">
          <rect width="76" height="76" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WatchTVIcon;
