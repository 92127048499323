import SPECIAL_APPS from './special-apps';

const DEEPLINK_APPS: {
  [key: string]: App;
} = {
  netflix: {
    id: 172,
    code: SPECIAL_APPS.netflix,
    name: 'Netflix',
    icon: 'https://dev01-gaea.titanos.tv:443/img/kgcq59mnxsiznbe25ujicah04eqq',
    type: 'app',
    classification: {
      id: 9,
      name: 'APTA',
      description: 'Universal: suitable for all'
    },
    image_dominant_color: '#FF000D',
    url: 'localfunction:gotonetflix',
    app_type: 'NetTV',
    resolution: '720p',
    content_list_app_type: null,
    content_list_app_name: 'Recomendaciones de Netflix'
  },
  PrimeVideo: {
    id: 216,
    code: SPECIAL_APPS.prime_video,
    name: 'Prime Video',
    icon: 'https://dev01-gaea.titanos.tv:443/img/pu4au7e2my4likzherohg0juu10v',
    type: 'app',
    classification: {
      id: 9,
      name: 'APTA',
      description: 'Universal: suitable for all'
    },
    image_dominant_color: '#00A6FF',
    url: 'localfunction:gotoaiv',
    app_type: 'NetTV',
    resolution: '720p',
    content_list_app_type: null,
    content_list_app_name: 'Recomendaciones de Prime'
  },
  disney: {
    id: 5,
    code: SPECIAL_APPS.disney,
    name: 'Disney+',
    icon: 'https://dev01-gaea.titanos.tv:443/img/1hp9u3cwetdqgkvklbnp89em7vvt',
    type: 'app',
    classification: {
      id: 9,
      name: 'APTA',
      description: 'Universal: suitable for all'
    },
    image_dominant_color: '#0055FF',
    url: 'localfunction:gotodisney',
    app_type: 'NetTV',
    resolution: '720p',
    content_list_app_type: null,
    content_list_app_name: 'Recomendaciones de Disney+'
  },
  fvp: {
    id: 8,
    code: SPECIAL_APPS.freeview_explore,
    name: 'FreeView Play',
    icon: 'https://dev01-gaea.titanos.tv:443/img/yr5vkp46kfvlu80kugf615crtroc',
    type: 'app',
    position: 7,
    classification: {
      id: 9,
      name: 'APTA',
      description: 'Universal: suitable for all'
    },
    image_dominant_color: '#FF2200',
    url: 'localfunction:GotoFVPApp:UKTV Play:http://tvapp.uktv.co.uk/?brand=default&model=fvp',
    app_type: 'NetTV',
    resolution: '720p',
    content_list_app_type: null,
    content_list_app_name: 'FreeView Play'
  },
  bbciplayer: {
    id: 9,
    code: SPECIAL_APPS.bbc_iplayer,
    name: 'BBC iPlayer',
    icon: 'https://dev01-gaea.titanos.tv:443/img/tx21k358xtpm2fq89qblzad751x6',
    type: 'app',
    position: 8,
    classification: {
      id: 9,
      name: 'APTA',
      description: 'Universal: suitable for all'
    },
    image_dominant_color: '#A200FF',
    url: 'https://www.live.bbctvapps.co.uk/tap/iplayer?origin=portal',
    app_type: 'NetTV',
    resolution: '720p',
    content_list_app_type: null,
    content_list_app_name: 'BBC iPlayer'
  },
  all4: {
    id: 10,
    code: SPECIAL_APPS.all_4,
    name: 'All 4',
    icon: 'https://dev01-gaea.titanos.tv:443/img/7xgav9o2oj5ud6jkgpgb5eai2iqh',
    type: 'app',
    position: 9,
    classification: {
      id: 9,
      name: 'APTA',
      description: 'Universal: suitable for all'
    },
    image_dominant_color: '#9DFF00',
    url: 'localfunction:GotoFVPApp:All4 Player:https://fvc-p06.channel4.com/fvc-webapp/index.html',
    app_type: 'NetTV',
    resolution: '720p',
    content_list_app_type: null,
    content_list_app_name: 'All 4'
  },
  itvhub: {
    id: 402,
    name: 'ITVX',
    code: SPECIAL_APPS.itv_hub,
    icon: 'https://dev01-gaea.titanos.tv:443/img/bywkj6v5gruzwgrj2hyhoa12kihm',
    type: 'app',
    classification: {
      id: 1,
      name: 'U',
      description: 'Universal: suitable for all'
    },
    image_dominant_color: '#000000',
    url: 'localfunction:GotoFVPApp:ITVX',
    app_type: 'NetTV',
    resolution: '720p',
    content_list_app_type: 'itvhub_list',
    content_list_app_name: 'ITVX'
  },
  my5: {
    id: 378,
    name: 'My5',
    code: SPECIAL_APPS.demand_5,
    icon: 'https://dev01-gaea.titanos.tv:443/img/92w6pnotsgu3y9ylilt5zuypjqbr',
    type: 'app',
    classification: {
      id: 1,
      name: 'U',
      description: 'Universal: suitable for all'
    },
    image_dominant_color: '#000000',
    url: 'localfunction',
    app_type: 'NetTV',
    resolution: '720p',
    content_list_app_type: 'my5_list',
    content_list_app_name: 'My5'
  }
};

export default DEEPLINK_APPS;
