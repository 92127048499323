import {
  createAction,
  createSlice,
  CreateSliceOptions,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  addToFavChannels,
  deleteFromFavChannels,
  fetchChannelPrograms,
  fetchChannels,
  fetchChannelsByGenre,
  fetchChannelsGenres,
  fetchEPGbyGenre,
  fetchFavoriteChannels,
  fetchProgramsByChannel
} from 'store/features/channels/action';
import Local from 'utils/storage/local';
import { Channel, Content, Genre } from 'store/types';

interface ChannelState {
  channels: Channel[];
  genres: Genre[];
  epgByGenre: { [genreId: number]: Channel[] };
  currentChannelId: string | null;
  channelIdFromProgram: string | null;
  isLoading: boolean;
  error: { message: string } | null;
  currentGenreId?: number;
  currentChannelPrograms: Content[];
  channelsByGenre: Channel[];
  favoriteChannels: Channel[];
}

const initialState: ChannelState = {
  channels: [],
  genres: [],
  epgByGenre: {},
  currentChannelPrograms: [],
  channelsByGenre: [],
  favoriteChannels: [],
  currentChannelId: Local.getLastWatchedChannelId(),
  channelIdFromProgram: null,
  isLoading: false,
  error: null,
  currentGenreId: undefined
};

const channelOptions: CreateSliceOptions<ChannelState, {}, 'channels'> = {
  name: 'channels',
  initialState,
  reducers: {
    setCurrentChannelId: (
      state: ChannelState,
      { payload }: PayloadAction<{ channelId: string }>
    ) => {
      state.currentChannelId = payload.channelId;
      Local.setLastWatchedChannelId(payload.channelId);
    },
    setCurrentChannelIdFromProgram: (
      state: ChannelState,
      { payload }: PayloadAction<{ channelId: string }>
    ) => {
      state.channelIdFromProgram = payload.channelId;
    },
    setGenreId: (
      state: ChannelState,
      { payload }: PayloadAction<{ genreId: number }>
    ) => {
      state.currentGenreId = payload.genreId;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChannels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchChannels.fulfilled,
        (state, { payload }: ReturnType<typeof fetchChannels.fulfilled>) => {
          state.isLoading = false;
          state.error = null;
          state.channels = payload.collection;
        }
      )
      .addCase(
        fetchChannels.rejected,
        (state, { error }: ReturnType<typeof fetchChannels.rejected>) => {
          state.isLoading = false;
          state.error = { message: error.message ?? '' };
        }
      )
      .addCase(fetchProgramsByChannel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchProgramsByChannel.fulfilled,
        (
          state,
          { payload, meta }: ReturnType<typeof fetchProgramsByChannel.fulfilled>
        ) => {
          state.isLoading = false;
          state.error = null;
          const channelIndex = state.channels.findIndex(
            (channel) => channel.id === meta.arg
          );
          if (channelIndex !== -1) {
            state.channels[channelIndex].timeline = payload?.timeline;
          }
        }
      )
      .addCase(
        fetchProgramsByChannel.rejected,
        (
          state,
          { error }: ReturnType<typeof fetchProgramsByChannel.rejected>
        ) => {
          state.isLoading = false;
          state.error = { message: error.message ?? '' };
        }
      )
      .addCase(fetchChannelsGenres.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchChannelsGenres.fulfilled,
        (
          state,
          { payload }: ReturnType<typeof fetchChannelsGenres.fulfilled>
        ) => {
          state.isLoading = false;
          state.genres = payload.collection;
          state.currentGenreId = state.currentGenreId
            ? state.currentGenreId
            : payload?.collection[0]?.id;
          state.error = null;
        }
      )
      .addCase(
        fetchChannelsGenres.rejected,
        (state, { error }: ReturnType<typeof fetchChannelsGenres.rejected>) => {
          state.isLoading = false;
          state.error = { message: error.message ?? '' };
        }
      )
      .addCase(fetchChannelPrograms.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchChannelPrograms.fulfilled,
        (
          state,
          { payload }: ReturnType<typeof fetchChannelPrograms.fulfilled>
        ) => {
          state.isLoading = false;
          state.currentChannelPrograms = payload.collection;
          state.error = null;
        }
      )
      .addCase(
        fetchChannelPrograms.rejected,
        (
          state,
          { error }: ReturnType<typeof fetchChannelPrograms.rejected>
        ) => {
          state.isLoading = false;
          state.error = { message: error.message ?? '' };
        }
      )
      .addCase(fetchChannelsByGenre.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchChannelsByGenre.fulfilled,
        (
          state,
          { payload }: ReturnType<typeof fetchChannelsByGenre.fulfilled>
        ) => {
          state.isLoading = false;
          state.channelsByGenre = payload;
          state.error = null;
        }
      )
      .addCase(
        fetchChannelsByGenre.rejected,
        (
          state,
          { error }: ReturnType<typeof fetchChannelsByGenre.rejected>
        ) => {
          state.isLoading = false;
          state.error = { message: error.message ?? '' };
        }
      )
      .addCase(fetchEPGbyGenre.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchEPGbyGenre.fulfilled,
        (
          state,
          { payload, meta }: ReturnType<typeof fetchEPGbyGenre.fulfilled>
        ) => {
          const { collection } = payload;
          state.isLoading = false;
          state.epgByGenre[meta.arg] = collection;

          state.error = null;
        }
      )
      .addCase(
        fetchEPGbyGenre.rejected,
        (state, { error }: ReturnType<typeof fetchEPGbyGenre.rejected>) => {
          state.isLoading = false;
          state.error = { message: error.message ?? '' };
        }
      )
      .addCase(fetchFavoriteChannels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchFavoriteChannels.fulfilled,
        (
          state,
          { payload }: ReturnType<typeof fetchFavoriteChannels.fulfilled>
        ) => {
          state.isLoading = false;
          state.favoriteChannels = payload.collection;
          state.error = null;
        }
      )
      .addCase(
        fetchFavoriteChannels.rejected,
        (
          state,
          { error }: ReturnType<typeof fetchFavoriteChannels.rejected>
        ) => {
          state.isLoading = false;
          state.error = { message: error.message ?? '' };
        }
      )
      .addCase(addToFavChannels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        addToFavChannels.fulfilled,
        (
          state: ChannelState,
          { payload, meta }: ReturnType<typeof addToFavChannels.fulfilled>
        ) => {
          const id = meta.arg;
          const channel = state.channels.find((i) => i.id == id);
          if (channel) {
            state.favoriteChannels.push(channel);
          }
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        addToFavChannels.rejected,
        (
          state: ChannelState,
          { error }: ReturnType<typeof addToFavChannels.rejected>
        ) => {
          state.isLoading = false;
          state.error = { message: error.message ?? '' };
        }
      )

      .addCase(deleteFromFavChannels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        deleteFromFavChannels.fulfilled,
        (
          state: ChannelState,
          { payload, meta }: ReturnType<typeof deleteFromFavChannels.fulfilled>
        ) => {
          const id = meta.arg;
          state.favoriteChannels = state.favoriteChannels.filter(
            (i) => i.id != id
          );
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        deleteFromFavChannels.rejected,
        (
          state: ChannelState,
          { error }: ReturnType<typeof deleteFromFavChannels.rejected>
        ) => {
          state.isLoading = false;
          state.error = { message: error.message ?? '' };
        }
      );
  }
};

// @ts-ignore
export const selectCurrentChannel = (state, currentChannelIndex) => {
  return state.channels.channels[currentChannelIndex];
};

export const setCurrentChannelId = createAction<{ channelId: string }>(
  'channels/setCurrentChannelId'
);

export const setCurrentChannelIdFromProgram = createAction<{
  channelId: string;
}>('channels/setCurrentChannelIdFromProgram');
export const setCurrentChannelIndex = createAction<{ index: number }>(
  'channels/setCurrentChannelIndex'
);

export const setGenreId = createAction<{ genreId: number }>(
  'channels/setGenreId'
);

const channelsSlice = createSlice(channelOptions);
export default channelsSlice.reducer;
