import React, { useEffect } from 'react';
import styled from 'styled-components';
import Focusable from 'components/Navigation/Focusable';
import { setFocus } from '@noriginmedia/norigin-spatial-navigation';
import scaleSize from 'utils/scale-size';

type ImageModalData = {
  data: {
    target_id: string;
  };
};

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.grey3};
  overflow: hidden;
`;

const ContentWrapper = styled(Focusable)<{ bg: string }>`
  width: ${({ theme }) => theme.sizes.fullWidth};
  height: ${scaleSize(1080)}px;
  background-size: cover;
  background-image: url(${({ bg }) => bg});
  position: relative;
`;

const MODAL_FOCUS_KEY = 'image-modal-focus-key';

const ImageModal: React.FC<ImageModalData> = ({ data }) => {
  useEffect(() => {
    setFocus(MODAL_FOCUS_KEY);
  }, []);
  return (
    <ModalWrapper>
      <ContentWrapper focusKey={MODAL_FOCUS_KEY} bg={data?.target_id}>
        <></>
      </ContentWrapper>
    </ModalWrapper>
  );
};

export default ImageModal;
