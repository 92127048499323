import React from 'react';
import scaleSize from 'utils/scale-size';
import theme from 'styles/theme';

type IconProps = {
  isFocused: boolean;
};

const HeartIcon: React.FC<IconProps> = ({ isFocused }) => {
  return (
    <svg
      width={scaleSize(32)}
      height={scaleSize(32)}
      viewBox="0 0 32 32"
      fill={'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={isFocused ? '0.8' : '0.6'}
        d="M30.1865 13.0959L30.1865 13.096C29.8133 15.0071 28.7649 16.9433 27.2975 18.814C25.837 20.676 24.0211 22.3975 22.224 23.8753C20.431 25.3496 18.6858 26.5576 17.3873 27.3974C16.8298 27.758 16.3566 28.0494 16 28.2636C15.6434 28.0494 15.1702 27.758 14.6127 27.3974C13.3142 26.5576 11.569 25.3496 9.77601 23.8753C7.97887 22.3975 6.16302 20.676 4.70246 18.814C3.23515 16.9433 2.18668 15.0071 1.81348 13.096L1.81346 13.0959C1.01927 9.03051 1.82225 6.14679 3.21158 4.312C4.60971 2.46561 6.72052 1.51585 8.88887 1.51585H8.93622L8.98347 1.51287C10.1149 1.44137 11.2448 1.66879 12.2577 2.17126C13.2705 2.67369 14.1295 3.43276 14.7482 4.36985L16 6.26589L17.2518 4.36985C17.8705 3.43276 18.7295 2.67369 19.7423 2.17126C20.7552 1.66879 21.8851 1.44137 23.0165 1.51287L23.0638 1.51585H23.1111C25.2795 1.51585 27.3903 2.46561 28.7884 4.312C30.1778 6.14679 30.9807 9.03051 30.1865 13.0959Z"
        stroke={isFocused ? theme.colors.white : theme.colors.gray05}
        strokeWidth="3"
      />
    </svg>
  );
};

export default HeartIcon;
