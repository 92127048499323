export default function secondsToHoursMinutesSeconds(
  seconds: number | undefined
): string | null {
  if (seconds === 0 || seconds === undefined) {
    return null;
  } else if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const hoursString = `${hours}h`;
    const minutesString = minutes > 0 ? `${minutes}m` : '';
    return `${hoursString} ${minutesString}`;
  } else if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes}m`;
  } else {
    return `${seconds}s`;
  }
}
