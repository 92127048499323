import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import scaleSize from 'utils/scale-size';
import { getCurrentTimeInHoursAndMinutes } from './getCurrentTime';

const CurrentTimeWrapper = styled.div`
  font-size: ${scaleSize(32)}px;
  font-weight: 800;
  margin-top: -${scaleSize(5)}px;
  padding: ${scaleSize(8)}px ${scaleSize(24)}px;
  color: ${({ theme }) => theme.colors.white03};
`;

const ONE_SECOND_IN_MS = 1000;

const CurrentTime: React.FC = () => {
  const [currentTime, setCurrentTime] = useState(
    getCurrentTimeInHoursAndMinutes()
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = getCurrentTimeInHoursAndMinutes();
      setCurrentTime(currentTime);
    }, ONE_SECOND_IN_MS);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return <CurrentTimeWrapper>{currentTime}</CurrentTimeWrapper>;
};

export default CurrentTime;
