import styled, { keyframes, css } from 'styled-components';
import scaleSize from 'utils/scale-size';
import Text from 'elements/Text';

interface SizeProps {
  height: number;
}
interface ListPlaceholderItemsProps {
  width: number;
  marginRight: number;
  height: number;
}

interface ListWrapperProps extends React.HTMLProps<HTMLDivElement> {
  editMode?: boolean;
  hasMetadata?: boolean;
  isFocused: boolean;
  isBelowFocused: boolean;
  isStatic?: boolean;
  listIndex?: number;
  listOffset?: number;
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ListWrapper = styled.div<ListWrapperProps>`
  position: relative;
  ${({ theme }) => theme.margin('xxl', 'left')};
  ${({ theme }) => theme.margin('s', 'bottom')};
  transform: translateY(
    ${({
      isFocused,
      theme,
      isBelowFocused,
      hasMetadata,
      isStatic,
      listOffset
    }) => {
      // I had a metldown at this point and this had to be done asap, sorry...
      if (isStatic && isFocused) {
        return `-${scaleSize(theme.marginSizes.l)}px`;
      } else if (isStatic) {
        return 0;
      }
      if (listOffset) {
        return `${listOffset}px`;
      }
      if (isFocused) {
        return `${scaleSize(-(hasMetadata ? theme.marginSizes.xs : 0))}px`;
      }
      return isBelowFocused
        ? `${scaleSize(theme.marginSizes.xl * 2)}px`
        : `${scaleSize(theme.marginSizes.m * -2)}px`;
    }}
  );

  ${({ theme }) =>
    theme.transition({
      value: 'transform',
      timing: theme.timings.l,
      easing: theme.easings.ease
    })};

  ${({ editMode }) =>
    editMode &&
    css`
      z-index: 222;
      &:before {
        content: '';
        top: -100vh;
        left: -50vw;
        position: absolute;
        width: 200vw;
        height: 200vh;
        background: rgba(0, 0, 0, 0.8);
        animation: ${fadeIn};
        animation-duration: 400ms;
        animation-iteration-count: 1;
      }
    `};
`;

export const ListPlaceholder = styled.div<SizeProps>`
  width: 100vw;
  height: ${({ height }) => `${height}px`};
  ${({ theme }) => theme.margin('xxl', 'left')};
`;

export const ListPlaceholderTitle = styled.div`
  min-width: ${scaleSize(150)}px;
  ${({ theme }) => theme.margin('xs', 'bottom')};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ListPlaceholderItems = styled.div<ListPlaceholderItemsProps>`
  width: 100%;
  height: ${({ height }) => `${height}px`};
  background: repeating-linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.gray07},
    ${({ theme }) => theme.colors.gray07} ${({ width }) => `${width}px`},
    transparent ${({ marginRight }) => `${marginRight}px`},
    transparent ${({ width, marginRight }) => `${width + marginRight}px`}
  );
`;

export const ListItemsWrapper = styled.div`
  white-space: nowrap;
  ${({ theme }) =>
    theme.transition({
      value: 'transform',
      timing: theme.timings.l,
      easing: theme.easings.ease
    })};
`;

export const ListTitleInnerWrapper = styled.div<{
  isFocused?: boolean;
  withGroups?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  ${({ theme }) => theme.margin('xs', 'bottom')};
  ${({ theme, withGroups, isFocused }) =>
    theme.padding(withGroups && isFocused ? 'm' : 0, 'top')};
  opacity: ${({ isFocused }) => (isFocused ? 1 : 0.6)};
  transform: translateY(
    ${({ isFocused, withGroups, theme }) =>
      isFocused ? -scaleSize(14) - (withGroups ? theme.marginSizes.m : 0) : 0}px
  );
  ${({ theme }) =>
    theme.transition({
      value: 'transform',
      timing: theme.timings.s,
      easing: theme.easings.ease
    })};
`;

export const ListTitle = styled(Text).attrs({ as: 'div' })<{}>`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: ${scaleSize(24)}px;
  position: relative;
`;

export const BrandedImageWrapper = styled.div`
  ${({ theme }) => theme.margin('xs', 'left')};
  height: ${scaleSize(50)}px;
  overflow: hidden;
`;

export const BrandedImage = styled.img`
  height: 100%;
  object-fit: scale-down;
`;
