import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import Focusable from 'components/Navigation/Focusable';
import theme from 'styles/theme';
import { formatDate, roundToNearestHalfHour } from 'utils/helpers';

import { addHours, differenceInSeconds, parseISO } from 'date-fns';

import BaseTitle from 'elements/DescText';
import BaseText from 'elements/DescText';
import scaleSize from 'utils/scale-size';
import ProgressBar from 'elements/ProgressBar';
import { useAppDispatch } from 'hooks/hooks';
import { setCurrentChannelIdFromProgram } from 'store/features/channels/channelsSlice';

const MIN_VISIBLE_DURATION_PERCENTAGE = 2;

const getDuration = (program: any, hours: number) => {
  const now = roundToNearestHalfHour(new Date());
  const then = addHours(now, hours);
  const start = parseISO(program.startTime);
  const end = parseISO(program.endTime);
  if (start.getTime() < now.getTime()) {
    return differenceInSeconds(end, start) - differenceInSeconds(now, start);
  } else if (end.getTime() > then.getTime()) {
    return differenceInSeconds(end, start) - differenceInSeconds(end, then);
  } else {
    return differenceInSeconds(end, start);
  }
};

const Title = styled(BaseTitle)<{
  isFocused: boolean;
  isInTimeRange?: boolean;
}>`
  font-size: ${scaleSize(24)}px;
  font-weight: 600;
  color: ${({ isFocused, theme }) =>
    isFocused ? theme.colors.secondary : theme.colors.white06};
  display: -webkit-box;
  -webkit-line-clamp: ${({ isFocused, isInTimeRange }) =>
    isFocused && isInTimeRange ? 1 : 2};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  line-height: ${scaleSize(32)}px;
  font-size: ${scaleSize(24)}px;
`;

const Time = styled(BaseText)<{ isFocused: boolean }>`
  font-size: ${scaleSize(20)}px;
  line-height: ${scaleSize(24)}px;
  color: ${({ isFocused, theme }) =>
    isFocused ? theme.colors.secondaryText : theme.colors.white03};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

const InnerProgramContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: ${scaleSize(16)}px;
`;

const ProgramContainer = styled(Focusable)<{
  duration: number;
  offset?: number;
}>`
  display: flex;
  position: relative;
  width: ${({ duration }) => duration}%;
  left: ${({ offset }) => offset}%;
  ${theme.typography.description};
  border-radius: ${scaleSize(4)}px;
  background-color: ${({ theme }) => theme.colors.gray01};
  border: ${scaleSize(2)}px solid rgba(0, 0, 0, 0);

  &:hover,
  &:focus,
  &.focused {
    background-color: ${({ theme }) => theme.colors.primary};
    z-index: 999;
    border: ${scaleSize(2)}px solid ${({ theme }) => theme.colors.black};
    outline: ${scaleSize(4)}px solid ${({ theme }) => theme.colors.primary};
  }
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: ${scaleSize(8)}px;
`;

interface ProgramProps {
  onClose: () => void;
  program: any;
  hours: number;
  onArrowPress: any;
  onFocus: any;
  extraProps?: Record<string, unknown>;
  itemIndex: number;
  offset: number;
  focusKey: string;
}

const Program: FC<ProgramProps> = ({
  program,
  hours,
  onArrowPress,
  onFocus,
  itemIndex,
  onClose,
  offset,
  focusKey
}) => {
  const dispatch = useAppDispatch();

  const totalDuration = hours * 60;
  const durationMinutesOnTimeline = getDuration(program, hours) / 60;
  const durationPercentageOnTimeline =
    (durationMinutesOnTimeline / totalDuration) * 100;
  const [isFocused, setIsFocused] = useState(false);

  const isInTimeRange =
    new Date() <= parseISO(program.endTime) &&
    new Date() >= parseISO(program.startTime);
  const timeHasPassed = Math.round(
    (differenceInSeconds(new Date(), parseISO(program.startTime)) * 100) /
      differenceInSeconds(
        parseISO(program.endTime),
        parseISO(program.startTime)
      )
  );

  const handleFocus = () => {
    onFocus();
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const handlePress = useCallback(() => {
    dispatch(
      setCurrentChannelIdFromProgram({
        channelId: program.content.parentId.toString()
      })
    );
    onClose();
  }, [program]);

  return (
    <ProgramContainer
      extraProps={{ itemIndex }}
      focusKey={focusKey}
      duration={durationPercentageOnTimeline}
      offset={offset}
      onEnterPress={handlePress}
      onArrowPress={onArrowPress}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      {durationPercentageOnTimeline > MIN_VISIBLE_DURATION_PERCENTAGE && (
        <InnerProgramContainer>
          <Title isFocused={isFocused} isInTimeRange={isInTimeRange}>
            {program.content.title}
          </Title>
          <TimeWrapper>
            <Time isFocused={isFocused}>{`${formatDate(
              program.startTime
            )} - ${formatDate(program.endTime)}`}</Time>
            {isInTimeRange && isFocused && (
              <ProgressBar percent={timeHasPassed} />
            )}
          </TimeWrapper>
        </InnerProgramContainer>
      )}
    </ProgramContainer>
  );
};

export default Program;
