enum CONTENT_TYPES {
  tv_show = 'tv_show',
  movie = 'movie',
  season = 'season',
  genre = 'genre',
  sport_program = 'sport_program',
  channel_program = 'channel_program',
  channel = 'channel',
  episode = 'episode',
  service = 'service',
  youtube_video = 'youtube_video',
  youtube_channel = 'youtube_channel',
  app = 'app',
  tpv_list_item = 'tpv_list_item',
  tpv_list_profile = 'tpv_list_profile'
}

export default CONTENT_TYPES;
