import React from 'react';
import scaleSize from 'utils/scale-size';
import theme from 'styles/theme';

type IconProps = {
  isFocused: boolean;
};

const BackIcon: React.FC<IconProps> = ({ isFocused }) => {
  return (
    <svg
      width={scaleSize(36)}
      height={scaleSize(36)}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={isFocused ? '0.8' : '0.6'}
        d="M2 19L18 3M2 19L18 35M2 19H34"
        stroke={isFocused ? theme.colors.white : theme.colors.gray05}
        strokeWidth="3"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BackIcon;
