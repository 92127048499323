import { useEffect, useRef, useState } from 'react';

const useInterval = (callback: () => void, defaultDelay: number) => {
  const [delay, setDelay] = useState(defaultDelay);
  const savedCallback = useRef(callback);
  const savedInterval = useRef<any>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (delay !== null) {
      savedInterval.current = setInterval(tick, delay);
      return () =>
        savedInterval.current && clearInterval(savedInterval.current);
    }
  }, [delay]);

  const reset = () => {
    if (savedInterval.current) {
      clearInterval(savedInterval.current);
    }

    savedInterval.current = setInterval(() => savedCallback.current(), delay);
  };

  return { setDelay, reset };
};

export default useInterval;
