import ARTWORK_TYPES from 'constants/artwork-types';
import GenericList from './GenericList';
import { ChannelNumber, OnNowWrapper } from './OnNow.styles';
import { useState } from 'react';
import { getOnTVList } from 'devices/TPV/fetchDeviceList';
import COLLECTION_TYPES from 'constants/collection-types';
import useAppRowUpdate from 'hooks/useAppRowUpdate';
import { Direction } from 'constants/rc-direction';
import { updateAllLayouts } from '@noriginmedia/norigin-spatial-navigation';

type Props = {
  onItemFocus: (item: any) => void;
  onArrowPress: (item: any, direction: Direction) => void;
};

export function OnNow({ onItemFocus, onArrowPress }: Props) {
  const [onNowList, setOnNowList] = useState(() => getOnTVList());

  useAppRowUpdate(COLLECTION_TYPES.on_now_list, () => {
    setOnNowList(getOnTVList());

    requestAnimationFrame(() => {
      updateAllLayouts();
    });
  });

  if (!onNowList || onNowList.data.collection.length <= 0) return null;

  return (
    <OnNowWrapper isVisible={true}>
      <GenericList
        itemOverlayComponent={(item) => (
          <ChannelNumber>{item.channelNumber}</ChannelNumber>
        )}
        id={'on_now_list'}
        listFocusKey={'on_now_list'}
        isBelowFocused={false}
        isStatic={true}
        type={ARTWORK_TYPES.LANDSCAPE}
        onFocus={onItemFocus}
        onArrowPress={onArrowPress}
        listIndex={0}
        isFocusable={true}
        prefix={`on-now_`}
        listTitle={'On Now'}
        data={{
          id: 'on_now',
          name: 'On Now',
          type: COLLECTION_TYPES.on_now_list
        }}
        listData={onNowList}
        key={'on-now-list'}
      />
    </OnNowWrapper>
  );
}
