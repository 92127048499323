import React from 'react';
import scaleSize from 'utils/scale-size';
import theme from 'styles/theme';

type IconProps = {
  isFocused: boolean;
};

const InfoIcon: React.FC<IconProps> = ({ isFocused }) => {
  return (
    <svg
      width={scaleSize(32)}
      height={scaleSize(32)}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity={isFocused ? '0.8' : '0.6'}
        cx="16"
        cy="16"
        r="14.5"
        stroke={isFocused ? theme.colors.white : theme.colors.gray05}
        strokeWidth="3"
      />
      <path
        opacity={isFocused ? '0.8' : '0.6'}
        d="M16.153 11.08C15.213 11.08 14.553 10.5 14.553 9.56C14.553 8.66 15.213 8.06 16.153 8.06C17.093 8.06 17.753 8.66 17.753 9.56C17.753 10.5 17.093 11.08 16.153 11.08ZM14.853 23V12.48H17.453V23H14.853Z"
        fill={isFocused ? theme.colors.white : theme.colors.gray05}
      />
    </svg>
  );
};

export default InfoIcon;
