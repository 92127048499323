import React from 'react';
import scaleSize from 'utils/scale-size';

type IconProps = {
  isFocused?: boolean;
  className?: string;
  color?: string;
  size?: {
    width: number;
    height: number;
  };
};

const ChevronIcon: React.FC<IconProps> = ({
  isFocused,
  className,
  color,
  size
}) => {
  return (
    <svg
      width={scaleSize(size?.width || 32)}
      height={scaleSize(size?.height || 32)}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g>
        <path
          d="M6.66669 13.3334L16 22.6667L25.3334 13.3334"
          stroke={color || 'rgba(255, 255, 255, 0.4)'}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ChevronIcon;
