import ReactMarquee from 'react-fast-marquee';

interface Props {
  enabled: boolean;
  text: string;
}
export default function Marquee({ enabled, text }: Props) {
  const normalizedText = text.trim();
  const isEnabled = enabled && !!normalizedText;

  return isEnabled ? (
    <ReactMarquee autoFill delay={0.3}>
      <p style={{ margin: 0, padding: 0, marginRight: '60px' }}>
        {normalizedText}
      </p>
    </ReactMarquee>
  ) : (
    text
  );
}
