import { getDeviceUID as getTPVDeviceUID } from 'devices/TPV';
import { getMockDeviceUID } from 'devices/TPV/mockDeviceInfo';
import Local from 'utils/storage/local';
import Current from 'utils/current';

export async function initializeDevice() {
  const deviceManufacturer = process.env.DEVICE || 'TPV';

  let deviceUID = Current.deviceId();
  if (!deviceUID) {
    switch (deviceManufacturer) {
      case 'TPV':
      default:
        try {
          deviceUID = await getTPVDeviceUID();
          Local.setDeviceUUID(deviceUID);
        } catch (error) {
          console.error('==> Error getting device info:', error);
          deviceUID = getMockDeviceUID();
          Local.setDeviceUUID(deviceUID);
        }
        break;
    }
  }

  return { deviceManufacturer, deviceUID };
}
