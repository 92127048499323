import React, { FC, ReactNode } from 'react';
import {
  useFocusable,
  UseFocusableConfig
} from '@noriginmedia/norigin-spatial-navigation';
import { saveFocus } from 'store/features/navigation/navigationSlice';
import { useAppDispatch } from 'hooks/hooks';
import usePathname from 'hooks/usePathname';

interface FocusableComponentProps extends UseFocusableConfig {
  children: ReactNode;
  className?: string;
  onEnterPress?: () => void;
  onEnterRelease?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  focusKey?: string;
  preventFocusSave?: boolean;
  isFocusable?: boolean;
  extraProps?: Record<string, unknown>;
} 

const Focusable: FC<FocusableComponentProps> = ({
  children,
  className,
  onEnterPress,
  onEnterRelease,
  onFocus,
  onBlur,
  focusKey,
  onArrowPress = () => true,
  preventFocusSave,
  isFocusable = true,
  extraProps = {}
}) => {
  const { pathname } = usePathname();
  const dispatch = useAppDispatch();

  const focusableParams = {
    onFocus: handleFocus,
    onBlur,
    onEnterPress,
    onEnterRelease: onEnterRelease,
    onArrowPress,
    extraProps,
    forgetLastFocusedChild: true,
    focusable: isFocusable,
    ...(focusKey ? { focusKey } : {})
  };
  const {
    ref,
    focused,
    focusKey: finalFocusKey
  } = useFocusable(focusableParams);

  function handleFocus() {
    !preventFocusSave &&
      dispatch(
        saveFocus({
          key: finalFocusKey,
          route: pathname
        })
      );
    onFocus && onFocus();
  }

  return (
    <div
      ref={ref}
      id={`${finalFocusKey}`}
      data-focused={focused ? 'focused' : 'na'}
      className={`${className} ${focused ? 'focused' : ''}`}
      onClick={onEnterRelease || onEnterPress}
      {...extraProps}
    >
      {children}
    </div>
  );
};

export default Focusable;
