import React, { FC, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import styled from 'styled-components';
import { hideLoader, setLoaderProgress } from 'store/features/loader/actions';
import LIST_SIZES from 'constants/list-sizes';
import scaleSize from 'utils/scale-size';
import usePathname from 'hooks/usePathname';
import { hideMenu, showMenu } from 'store/features/menu/menuSlice';
import useNavigation from 'hooks/useNavigation';
import ARTWORK_TYPES from '../../constants/artwork-types';
import COLLECTION_TYPES from '../../constants/collection-types';
import Banners from 'components/Banners/Banners';
import { BANNERS_HEIGHT } from '../../styles/base';
import MeAppsList from './MeAppsList';
import GenericList from './GenericList';
import CUSTOM_LIST_TITLES from '../../constants/custom-list-names';
import isFVPElement from 'utils/is-fvp-app';
import ConnectedInputSourcesProvider from 'providers/ConnectedInputSources';

const ListsWrapper = styled.div<{ hasBanners: boolean }>`
  overflow: hidden;
  padding-top: ${({ hasBanners }) => (!hasBanners ? BANNERS_HEIGHT : 0)}px;
  min-height: ${scaleSize(1080)}px;
`;

const ListsInnerWrapper = styled.div`
  ${({ theme }) =>
    theme.transition({
      value: 'transform',
      timing: theme.timings.s,
      easing: theme.easings.ease
    })};
`;

const OffsetByBanners = styled.div<{ isBannerFocused: boolean }>`
  ${({ theme }) =>
    theme.transition({
      value: 'transform',
      timing: theme.timings.s,
      easing: theme.easings.ease
    })};
  transform: translateY(
    ${({ isBannerFocused }) => (isBannerFocused ? scaleSize(210) : 0)}px
  );
`;

const useCalculateListsTranslate = (
  data: ListData['collection'] = [],
  focusedIndex: number,
  prefix: string,
  hasBanners: boolean
) => {
  const previousLists = data.slice(hasBanners ? 1 : 0, focusedIndex);
  const lists = useAppSelector((state) => state.lists);
  const appsList = useAppSelector((state) => {
    return state.apps.favorites;
  });

  const listsHeight = previousLists.reduce((accumulator, list) => {
    const listId = `${prefix}${list.type}_${list.id}`;
    const listData =
      list.type === COLLECTION_TYPES.me_apps_list
        ? appsList?.data?.collection
        : lists?.[listId]?.data?.collection;
    const hasContents = !!listData?.length;
    const type = getArtworkType(list);
    const { height, marginBottom, titleHeight } = LIST_SIZES[type];

    if (!hasContents) {
      return accumulator;
    }

    return accumulator + height + marginBottom + titleHeight;
  }, 0);

  return listsHeight ? listsHeight + scaleSize(20) : 0;
};

const getArtworkType = (data: any) => {
  let type = ARTWORK_TYPES.LANDSCAPE;

  if (
    [COLLECTION_TYPES.me_apps_list, COLLECTION_TYPES.apps_list].includes(
      data.type
    )
  ) {
    type = ARTWORK_TYPES.SERVICE;
  }

  return type;
};

const listHasBanners = (pageLists: ListData): boolean => {
  return (
    pageLists?.collection?.some(
      (list: { name: string; type?: string }) => list.type === 'banner_list'
    ) ?? false
  );
};

interface ListData {
  collection: any[];
  name?: string;
  id?: string;
}

interface ListsContainerProps {
  data: ListData;
  prefix: string;
  defaultFocusKey?: string;
  query?: string;
  shouldToggleMenuVisibility?: boolean;
  className?: string;
}

const ListsContainer: FC<ListsContainerProps> = ({
  data,
  shouldToggleMenuVisibility = true,
  prefix,
  defaultFocusKey,
  query,
  className
}) => {
  const { pathname } = usePathname();
  const { focusLast } = useNavigation({
    defaultFocusKey: defaultFocusKey || 'focusable-list-1'
  });
  const [focusedListIndex, setFocusedListIndex] = useState(0);
  const [listsOffsetAfterIndex, setListsOffsetAfterIndex] = useState({
    index: 0,
    offset: 0
  });
  const [listsLoaded, setListsLoaded] = useState({});
  const [isBannerFocused, setIsBannerFocused] = useState(false);
  const dispatch = useAppDispatch();
  const hasBanners = listHasBanners(data);
  const translateY = useCalculateListsTranslate(
    data.collection,
    focusedListIndex,
    prefix,
    hasBanners
  );

  useEffect(() => {
    if (translateY === 0) {
      shouldToggleMenuVisibility && dispatch(showMenu());
    } else {
      shouldToggleMenuVisibility && dispatch(hideMenu({ hintVisible: true }));
    }
  }, [translateY]);

  useEffect(() => {
    const loaded = Object.keys(listsLoaded).length;
    const progress = Math.round((loaded / data.collection.length) * 100);
    !isNaN(progress) && dispatch(setLoaderProgress(progress));
    if (progress === 100) {
      dispatch(hideLoader());
      focusLast();
    }
  }, [listsLoaded]);

  const onListLoad = useCallback((listIndex: number) => {
    setListsLoaded((current) => ({
      ...current,
      [listIndex]: true
    }));
  }, []);

  const handleFocus = useCallback(
    (listIndex: number, itemIndex?: number) => {
      setFocusedListIndex(listIndex);
    },
    [pathname]
  );

  let banners = null;

  if (hasBanners) {
    banners = data.collection[0];
  }

  return (
    <ListsWrapper className={className} hasBanners={hasBanners}>
      <ListsInnerWrapper
        style={{ transform: `translate3d(0, -${translateY}px, 0)` }}
      >
        {banners && (
          <Banners
            id={banners.id}
            prefix={prefix}
            listIndex={0}
            isPaused={focusedListIndex > 1}
            isBannerFocused={isBannerFocused}
            onBlur={() => {
              setIsBannerFocused(false);
            }}
            onFocus={() => {
              handleFocus(0);
              setIsBannerFocused(true);
            }}
          />
        )}
        <OffsetByBanners isBannerFocused={isBannerFocused}>
          {data.collection.map((list: any, listIndex: number) => {
            if (hasBanners && listIndex === 0) {
              return null;
            }

            // TODO: Refactor to identify that any app row does not need to show title
            const isMeApps = list.type === COLLECTION_TYPES.me_apps_list;
            const isStoreApps = list.type === COLLECTION_TYPES.app_list;
            const isSearchApps = list.type === COLLECTION_TYPES.apps_list;
            const shouldDisplayTitle =
              !isSearchApps &&
              !isMeApps &&
              !isStoreApps &&
              !isFVPElement(list.type);
            const listTitle = list.name || CUSTOM_LIST_TITLES[list.type];
            const ListComponent = isMeApps ? MeAppsList : GenericList;

            return (
                <ListComponent
                  key={`list-${list.name || list.id}-${list.type}`}
                  id={list.id}
                  data={list}
                  listOffset={
                    listIndex > listsOffsetAfterIndex.index
                      ? listsOffsetAfterIndex.offset
                      : 0
                  }
                  setListsOffsetAfterIndex={setListsOffsetAfterIndex}
                  isBelowFocused={listIndex > focusedListIndex}
                  listTitle={listTitle}
                  query={query}
                  prefix={prefix}
                  listIndex={listIndex}
                  onFocus={handleFocus}
                  onLoad={onListLoad}
                  type={getArtworkType(list)}
                  showArtworkTitle={shouldDisplayTitle}
                />
            );
          })}
        </OffsetByBanners>
      </ListsInnerWrapper>
    </ListsWrapper>
  );
};

export default ListsContainer;
