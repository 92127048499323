import React from 'react';
import scaleSize from 'utils/scale-size';
import theme from 'styles/theme';

type IconProps = {
  isFocused: boolean;
};

const EpgIcon: React.FC<IconProps> = ({ isFocused }) => {
  return (
    <svg
      width={scaleSize(32)}
      height={scaleSize(32)}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={isFocused ? '0.8' : '0.6'}>
        <path
          d="M1.33398 16H20.0006M30.6673 16H24.0006"
          stroke={isFocused ? theme.colors.white : theme.colors.gray05}
          strokeWidth="3"
        />
        <path
          d="M1.33398 6.6665H8.00065M30.6673 6.6665H12.0007"
          stroke={isFocused ? theme.colors.white : theme.colors.gray05}
          strokeWidth="3"
        />
        <path
          d="M1.33398 25.3335H8.00065M30.6673 25.3335H12.0007"
          stroke={isFocused ? theme.colors.white : theme.colors.gray05}
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default EpgIcon;
