import { css } from 'styled-components';
import Local from 'utils/storage/local';

export const animationsEnabled = Local.getAnimations();

export const timings = {
  s: 200,
  l: 400
};

export const easings = {
  ease: 'ease',
  in: 'ease-in',
  out: 'ease-out',
  inOut: 'ease-in-out'
};

export interface TransitionArgs {
  value: string;
  timing: 's' | 'l';
  easing: 'ease' | 'in' | 'out' | 'inOut';
}
export const transition = (values: TransitionArgs[] | TransitionArgs) => {
  if (!animationsEnabled) {
    return '';
  }
  const createTransition = (value: string, timing: string, easing: string) => {
    return `${value} ${timing}ms ${easing}`;
  };

  if (Array.isArray(values)) {
    return css`
      transition: ${values
        .map(({ value, timing, easing }) =>
          createTransition(value, timing, easing)
        )
        .join(', ')};
    `;
  }

  return css`
    transition: ${createTransition(values.value, values.timing, values.easing)};
  `;
};
