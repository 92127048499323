import React from 'react';
import scaleSize from 'utils/scale-size';
import theme from 'styles/theme';

type IconProps = {
  isFocused: boolean;
};

const HeartAddIcon: React.FC<IconProps> = ({ isFocused }) => {
  return (
    <svg
      width={scaleSize(32)}
      height={scaleSize(32)}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={isFocused ? '0.8' : '0.6'}
        d="M29.9342 15.4606C28.3985 23.2589 16.1176 30 16.1176 30C16.1176 30 3.83683 23.2589 2.30114 15.4606C0.787406 7.77646 5.24075 3.76387 9.84312 3.76387C11.0729 3.6868 12.3016 3.93186 13.4038 4.47407C14.506 5.01628 15.4424 5.83626 16.1176 6.85048C16.7929 5.83626 17.7293 5.01628 18.8315 4.47407M27.8824 0V6.09375M27.8824 12.1875V6.09375M27.8824 6.09375H21.7647M27.8824 6.09375H34"
        stroke={isFocused ? theme.colors.white : theme.colors.gray05}
        strokeWidth="3"
      />
    </svg>
  );
};

export default HeartAddIcon;
