import styled from "styled-components";
import scaleSize from "utils/scale-size";
import Text from 'elements/Text';


export const ListGroupTitle = styled(Text).attrs({ as: 'div' }) <{
    translateX: number;
    isCurrentGroup: boolean;
}>`
  transform: translateX(${({ translateX }) => translateX}px);
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: ${scaleSize(290)}px;
  font-size: ${scaleSize(20)}px;
  ${({ theme }) =>
        theme.transition({
            value: 'transform',
            timing: theme.timings.l,
            easing: theme.easings.ease
        })};
  color: ${({ theme }) => theme.colors.white};
  opacity: ${({ isCurrentGroup }) => (isCurrentGroup ? 1 : 0.5)};
  font-weight: 400;
  text-overflow: ellipsis;
`;

export const ListGroupTitleGroups = styled.div<{ isFocused: boolean }>`
  position: absolute;
  max-width: ${scaleSize(200)}px;
  bottom: -${({ theme }) => theme.marginSizes.s}px;
  left: 0;
  opacity: ${({ isFocused }) => +isFocused};
  ${({ theme }) =>
        theme.transition({
            value: 'opacity',
            timing: theme.timings.s,
            easing: theme.easings.ease
        })};
`;

export const ListGroupTitlePlaceholder = styled.span`
  visibility: hidden;
`;