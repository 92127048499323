enum ARTWORK_TYPES {
  LANDSCAPE = 'LANDSCAPE',
  GENRE = 'GENRE',
  LANDSCAPE_4_3 = 'LANDSCAPE_4_3',
  LANDSCAPE_XL = 'LANDSCAPE_XL',
  LANDSCAPE_TPV = 'LANDSCAPE_TPV',
  SQUARE_TPV = 'SQUARE_TPV',
  PORTRAIT = 'PORTRAIT',
  SERVICE = 'SERVICE',
  NA = 'NA'
}

export default ARTWORK_TYPES;
