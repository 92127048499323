import CONTENT_TYPES from 'constants/content-types';
import MODAL_TYPES from 'constants/modal-types';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchBanners } from 'store/features/banners/actions';
import { showModal } from 'store/features/modals/actions';
import { sizes } from 'styles/base';
import resizeImage from 'utils/resize-image';
import { getApp } from 'store/features/apps/actions';
import { ActionSource, executeAction } from 'utils/actions';
import {
  Banner,
  BannerDataWrapper,
  BannerInnerWrapper,
  BannerItemsWrapper,
  BannerText,
  BannerTitle,
  BannersWrapper,
  FakeButton,
  FakeFocusable,
  GradientOverlayHorizontal,
  GradientOverlayVertical,
  HidableData,
  Indicator,
  Indicators,
  LogoImage,
  LogoImageWrapper,
  OpacityOverlay,
  ProgressBar,
  ProgressBarWrapper,
  SlidingBanner
} from './Banners.styles';

const BANNER_SWITCH_TIMER = 10000;

interface BannersProps {
  id: string;
  prefix: string;
  onLoad?: (listIndex: number) => void;
  listIndex: number;
  onFocus: (listIndex: number) => void;
  onBlur: () => void;
  isBannerFocused: boolean;
  isPaused: boolean;
}

interface BannerType {
  id: string;
  image: string;
  text: string;
  title: string;
  target_id: number;
  target_type: string;
  call_to_action: string;
  logo?: string;
}
const Banners: FC<BannersProps> = ({
  id,
  prefix,
  onLoad,
  listIndex,
  onFocus,
  isPaused,
  onBlur,
  isBannerFocused
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // TODO: a very dirty and quick fix
  // We should replace the menuOpacity action with
  // editMode and use that in different menu and here.
  // The problem is that for some reason when the banners are switched while in the edit mode
  // the button and progress bar appear on top of the overlay sometimes.
  // This only happens on the TV, not reproducible in the browser
  const menuOpacity = useAppSelector((state) => state.menu.menuOpacity);
  const isBlurred = useAppSelector((state) => state.app.isBlurred);
  const banners =
    useAppSelector((state: any) => state.banners[prefix]?.data?.collection) ||
    [];
  const selectedApp = useAppSelector((state) => state.apps.selectedApp);

  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const intervalId = useRef<number | null>(null);

  useEffect(() => {
    if (!isPaused) {
      intervalId.current = setInterval(() => {
        setCurrentBannerIndex(
          (prevIndex: number) => (prevIndex + 1) % banners.length
        );
      }, BANNER_SWITCH_TIMER) as unknown as number;

      return () => {
        if (intervalId.current !== null) {
          clearInterval(intervalId.current);
        }
      };
    }
  }, [banners, isPaused]);

  useEffect(() => {
    dispatch(fetchBanners({ id, prefix })).then(() => {
      onLoad && onLoad(listIndex);
    });
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isBannerFocused, banners.length]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (isBannerFocused) {
      if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
        if (intervalId.current !== null) {
          clearInterval(intervalId.current);
        }

        setCurrentBannerIndex((prevIndex) => {
          return e.key === 'ArrowRight'
            ? (prevIndex + 1) % banners.length
            : (prevIndex - 1 + banners.length) % banners.length;
        });

        intervalId.current = setInterval(() => {
          setCurrentBannerIndex(
            (prevIndex: number) => (prevIndex + 1) % banners.length
          );
        }, BANNER_SWITCH_TIMER) as unknown as number;
      }
    }
  };
  //it is the old implementation but maybe we'll need it in the future
  // const handleCallToAction = useCallback(() => {
  //   const banner = banners[currentBannerIndex];
  //   if (banner.target_type === CONTENT_TYPES.app) {
  //     navigate(`/details/${banner.target_type}/${banner.target_id}`, {
  //       state: { app: { id: banner.target_id, ...banner } }
  //     });
  //   } else if (banner.target_type === 'image') {
  //     dispatch(showModal(MODAL_TYPES.IMAGE_FULL_SCREEN, banner, ''));
  //   }
  // }, [currentBannerIndex, banners]);
  useEffect(() => {
    const banner = banners[currentBannerIndex];
    // @ts-ignore
    const contentId = banner?.target_id;
    // @ts-ignore
    if (banner && banner.target_type === CONTENT_TYPES.app && contentId) {
      // @ts-ignore
      dispatch(getApp(contentId));
    }
  }, [currentBannerIndex, banners]);

  const handleCallToAction = useCallback(() => {
    const banner = banners[currentBannerIndex];
    if (banner.target_type === CONTENT_TYPES.app) {
      executeAction('launch-app', {
        app: selectedApp,
        source: ActionSource.BANNER
      });
    } else if (banner.target_type === 'image') {
      dispatch(showModal(MODAL_TYPES.IMAGE_FULL_SCREEN, banner, ''));
    }
  }, [currentBannerIndex, banners, selectedApp]);

  const onArrowPress = (direction: string, props: object) => {
    return direction !== 'left' && direction !== 'right';
  };

  const onHandleFocus = () => {
    onFocus(listIndex);
  };

  const onHandleBlur = () => {
    onBlur();
  };

  return (
    <BannersWrapper
      style={{ opacity: menuOpacity }}
      className={isBannerFocused ? 'focused' : ''}
      isBlurred={isBlurred}
    >
      <FakeFocusable
        focusKey={`fake-banner-button`}
        onEnterPress={handleCallToAction}
        //@ts-ignore
        onArrowPress={onArrowPress}
        onFocus={onHandleFocus}
        onBlur={onHandleBlur}
      >
        {' '}
      </FakeFocusable>
      <BannerItemsWrapper
        currentBannerIndex={currentBannerIndex}
        bannersLength={banners.length}
      >
        {banners.map((banner: BannerType) => (
          <SlidingBanner key={`banner-${banner.id}`}>
            <Banner
              src={resizeImage(banner.image, { width: sizes.fullWidth })}
            />
            <GradientOverlayVertical />
            <GradientOverlayHorizontal />
            <BannerInnerWrapper>
              <BannerDataWrapper>
                {banner.logo && (
                  <LogoImageWrapper>
                    <LogoImage src={banner.logo} alt="Banner Logo" />
                  </LogoImageWrapper>
                )}

                {banner.title && <BannerTitle>{banner.title}</BannerTitle>}
                <HidableData>
                  {banner.text && <BannerText>{banner.text}</BannerText>}
                  <FakeButton
                    title={
                      banner.call_to_action
                        ? banner.call_to_action
                        : 'Learn more'
                    }
                    className={isBannerFocused ? 'focused' : ''}
                    isFocusable={false}
                    focusKey={'fake-banner-button'}
                    handleClick={() => {}}
                  />
                </HidableData>
              </BannerDataWrapper>
            </BannerInnerWrapper>
            <OpacityOverlay />
          </SlidingBanner>
        ))}
      </BannerItemsWrapper>
      {banners.length > 1 && (
        <Indicators>
          {banners.map((b: BannerType, index: number) =>
            index === currentBannerIndex && !isPaused ? (
              <ProgressBarWrapper key={`progress-bar-${index}`}>
                <ProgressBar key={`progress-${currentBannerIndex}`} />
              </ProgressBarWrapper>
            ) : (
              <Indicator key={`indicator-${index}`} active={false} />
            )
          )}
        </Indicators>
      )}
    </BannersWrapper>
  );
};

export default Banners;
