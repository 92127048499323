import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';
import { convertKeysToCamelCase } from 'utils/helpers';
import { RootState } from 'store/configure-store';

interface GenresDataType {
  collection: any[]; // TODO: replace 'any' with exact type of collection's items
  pagination: any;
}

export const fetchGenres = createAsyncThunk<
  GenresDataType,
  { type?: string },
  { state: RootState }
>('genres/fetchGenres', async ({ type }, { rejectWithValue }) => {
  try {
    const response = await api({
      endpoint: `/genres`,
      queryParams: {
        type,
        per_page: 100
      }
    });
    return convertKeysToCamelCase(response);
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.errors[0] || error.message);
  }
});
