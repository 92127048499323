import styled from 'styled-components';
import scaleSize from 'utils/scale-size';
import Focusable from 'components/Navigation/Focusable';
import ListsContainer from 'components/List/ListsContainer';
import Grid from 'components/Grid/Grid';

export const Wrapper = styled(Focusable)`
  ${({ theme }) => theme.margin('xxl', 'top')};
  display: flex;
  flex-direction: column;
`;

export const StyledFocusableInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ClearButton = styled(Focusable)`
  width: ${scaleSize(80)}px;
  height: ${scaleSize(80)}px;
  background: #161616;
  border-radius: 50%;
  display: flex;
  justify-self: flex-end;
  align-items: center;
  justify-content: center;
  border: solid 1px ${({ theme }) => theme.colors.white03};
  svg {
    stroke: white;
  }

  &.focused {
    background: white;
    svg {
      stroke: black;
    }
  }
`;

export const StyledFocusableInput = styled(Focusable)`
  z-index: 3;
  ${({ theme }) => theme.margins([0, 'l', 0, 'xxl'])};
  width: calc(
    100% -
      ${({ theme }) =>
        theme.marginSizes.xxl * 2 + theme.marginSizes.l + scaleSize(80)}px
  );
  height: ${scaleSize(112)}px;
  border: solid ${scaleSize(4)}px ${({ theme }) => theme.colors.white1};
  border-radius: ${scaleSize(8)}px;

  &.focused {
    outline: solid ${scaleSize(4)}px ${({ theme }) => theme.colors.primary};
    border: solid ${scaleSize(4)}px transparent;
  }
`;
export const StyledInput = styled.input`
  ${({ theme }) => theme.padding('s', 'left')};
  ${({ theme }) => theme.typography.medium_header};
  width: 70vw;
  height: ${scaleSize(112)}px;
  background: transparent;
  border: none;
  border-radius: ${scaleSize(2)}px;

  &::placeholder {
    font-size: ${scaleSize(60)}px;
    color: ${({ theme }) => theme.colors.gray02};
  }
  outline: none;
`;

export const StyledListsContainer = styled(ListsContainer)`
  padding-top: ${({ theme }) => theme.marginSizes.s}px;
`;

export const StyledGrid = styled(Grid)`
  position: relative;
  margin-top: 0;
`;

export const GridWrapper = styled.div`
  ${({ theme }) => theme.margin('xxl', 'top')};
`;
