import React, { useEffect, useRef } from 'react';
import ShakaPlayer from './ShakaWrapper';
import muxjs from 'mux.js';
import 'shaka-player/dist/controls.css';
import scaleSize from 'utils/scale-size';

type ExtendedWindow = Window & typeof globalThis & { muxjs: typeof muxjs };

(window as ExtendedWindow).muxjs = muxjs;

const WATCH_THRESHOLD = 1;

interface VideoPlayerProps {
  stream: {
    streamUrl?: string;
    imgSrc?: string;
  };
  onSaveToWatched: (v: number, t: number) => void;
  channelId: number;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  stream,
  onSaveToWatched,
  channelId
}) => {
  const { streamUrl, imgSrc } = stream;
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const currentTimeRef = useRef<number>(0); // Ref to keep track of currentTime

  useEffect(() => {
    const timeInSeconds = videoRef.current?.currentTime || 0;
    timeInSeconds > WATCH_THRESHOLD &&
      onSaveToWatched(channelId, Math.round(timeInSeconds));
  }, [streamUrl]);

  useEffect(() => {
    const videoElement = videoRef.current;
    const updateTime = () => {
      currentTimeRef.current = videoElement?.currentTime || 0;
    };
    videoElement?.addEventListener('timeupdate', updateTime);
    return () => {
      videoElement?.removeEventListener('timeupdate', updateTime);
      currentTimeRef.current > WATCH_THRESHOLD &&
        onSaveToWatched(channelId, Math.round(currentTimeRef.current));
    };
  }, []);

  if (!streamUrl) {
    return null;
  }
  return (
    <ShakaPlayer
      key={streamUrl}
      src={streamUrl}
      poster={imgSrc}
      width={`${scaleSize(1920)}px`}
      height={`${scaleSize(1080)}px`}
      autoPlay
    />
  );
};

export default VideoPlayer;
