import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from 'hooks/hooks';
import MODAL_TYPES from 'constants/modal-types';
import DeeplinkModal from 'components/Modals/DeeplinkModal';
import ImageModal from 'components/Modals/ImageModal';
import ThemeModal from 'components/Modals/ThemeModal';
import MarketsModal from 'components/Modals/MarketsModal';
import VersionModal from 'components/Modals/VersionModal';
import DevToolsModal from 'components/Modals/DevToolsModal';
import { removeModal } from 'store/features/modals/actions';
import {
  FocusContext,
  setFocus,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MODAL_TYPE_COMPONENT = {
  [MODAL_TYPES.DEV_ACTIONS]: DevToolsModal,
  [MODAL_TYPES.DEEPLINK]: DeeplinkModal,
  [MODAL_TYPES.IMAGE_FULL_SCREEN]: ImageModal,
  [MODAL_TYPES.THEME]: ThemeModal,
  [MODAL_TYPES.MARKETS]: MarketsModal,
  [MODAL_TYPES.VERSION]: VersionModal
};

const Modal: React.FC = () => {
  const modal = useAppSelector((state) => state.modals.modal);
  const { ref, focusKey, focusSelf } = useFocusable({
    focusable: !!(modal.type && modal.data),
    isFocusBoundary: true
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (modal.data && modal.type !== MODAL_TYPES.IMAGE_FULL_SCREEN) {
      focusSelf();
    }
  }, [modal.data]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const { key } = e;
      if (!modal.type || !MODAL_TYPES[modal.type]) {
        return;
      }
      // TODO: use keys from device
      if (key === 'Backspace') {
        e.preventDefault();
        dispatch(removeModal());
        modal.returnFocusKey && setFocus(modal.returnFocusKey);
      }
      if (key === 'Enter' && modal.type === MODAL_TYPES.IMAGE_FULL_SCREEN) {
        e.preventDefault();
        dispatch(removeModal());
        modal.returnFocusKey && setFocus(modal.returnFocusKey);
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [modal.type]);

  if (!modal.type || !modal.data) {
    return null;
  }

  if (!MODAL_TYPES[modal.type]) {
    console.log('==> Unknown modal type', modal.type);
    return null;
  }

  const ModalComponent = MODAL_TYPE_COMPONENT[modal.type];

  return (
    <FocusContext.Provider value={focusKey}>
      <ModalWrapper ref={ref}>
        <ModalComponent
          data={modal.data}
          closeModal={() => dispatch(removeModal())}
        />
      </ModalWrapper>
    </FocusContext.Provider>
  );
};

export default Modal;
