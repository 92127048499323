import { createSlice, CreateSliceOptions } from '@reduxjs/toolkit';
import { fetchGrid } from 'store/features/grids/actions';

interface GridContent {
  id: string;
}

interface GridPagination {
  currentPage: number;
  nextPage?: number | null;
  prevPage?: number | null;
  totalPages: number;
  totalCount: number;
  perPage: number;
}

interface GridStatus {
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage?: string;
  pagination?: GridPagination;
  data: {
    collection: GridContent[];
  };
}

type GridState = {
  [key: string]: GridStatus;
};

const initialState: GridState = {};

const sliceOptions: CreateSliceOptions<GridState, {}, 'grids'> = {
  name: 'grids',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchGrid.pending,
        (state, { meta }: ReturnType<typeof fetchGrid.pending>) => {
          const defaultGridStatus: GridStatus = {
            isLoading: false,
            isSuccess: false,
            data: { collection: [] }
          };
          const { type } = meta.arg;
          const key = `${type}-${JSON.stringify(meta.arg.params)}`;
          state[key] = state[key] || defaultGridStatus;
          state[key].isLoading = true;
        }
      )
      .addCase(
        fetchGrid.fulfilled,
        (
          state: GridState,
          { payload, meta }: ReturnType<typeof fetchGrid.fulfilled>
        ) => {
          const { type } = meta.arg;
          const key = `${type}-${JSON.stringify(meta.arg.params)}`;
          const existingCollectionIds = state[key]?.data?.collection
            ? new Set(
                state[key].data.collection.map((item: GridContent) => item.id)
              )
            : new Set();
          const newCollectionItems = payload?.collection?.filter(
            (item: GridContent) => !existingCollectionIds.has(item.id)
          );
          const collection = [
            ...(state[key]?.data?.collection || []),
            ...newCollectionItems
          ];
          state[key].isLoading = false;
          state[key].isSuccess = true;
          state[key].pagination = {
            ...payload.pagination,
            prevPage: payload.pagination.prevPage || null
          };
          state[key].data = { collection };
        }
      )
      .addCase(
        fetchGrid.rejected,
        (
          state: GridState,
          { payload, meta }: ReturnType<typeof fetchGrid.rejected>
        ) => {
          const { type } = meta.arg;
          const key = `${type}-${JSON.stringify(meta.arg.params)}`;
          state[key].isLoading = false;
          state[key].isSuccess = false;
          state[key].errorMessage = payload as string;
        }
      );
  }
};

const gridsSlice = createSlice(sliceOptions);
export default gridsSlice.reducer;
