import React, { FC } from 'react';
import styled from 'styled-components';
import scaleSize from 'utils/scale-size';

const Container = styled.div`
  height: ${scaleSize(4)}px;
  width: 100%;
  position: relative;
`;

const BaseBox = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: ${scaleSize(4)}px;
  ${({ theme }) =>
    theme.transition({
      value: 'width',
      timing: 10000,
      easing: theme.easings.inOut
    })};
`;

const Background = styled(BaseBox)`
  background-color: ${({ theme }) => theme.colors.white005};
  width: 100%;
`;

const Progress = styled(BaseBox)<{ percent: number }>`
  background-color: ${({ theme }) => theme.colors.secondaryText};
  width: ${({ percent }) => percent}%;
`;

const ProgressBar: FC<{ percent: number }> = ({ percent }) => {
  return (
    <Container>
      <Background />
      <Progress percent={percent} />
    </Container>
  );
};

export default ProgressBar;
