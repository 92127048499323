export function getDeviceUID(): Promise<string> {
  return new Promise((resolve, reject) => {
    let timer: number;

    // @ts-ignore
    window.onDeviceInfoReady((DeviceInfo: any) => {
      console.log('==> onDeviceInfoReady', DeviceInfo);
      const deviceId = DeviceInfo?.Product?.deviceID;
      clearTimeout(timer);
      resolve(deviceId);
    });

    timer = window.setTimeout(() => {
      reject(new Error('Device info is not available'));
    }, 2000);
  });
}
