import CONTENT_TYPES from 'constants/content-types';
import CONTENT_TYPE_TO_ARTWORK_TYPE from 'constants/content-type-to-artwork-type';
import ARTWORK_SIZES from 'constants/artwork-sizes';

const calculateListTitleTranslate = (
  items: any,
  start: number,
  end: number,
  focusedIndex: number
) => {
  let previousItems: Array<any> = [];
  let direction = 1;

  if (focusedIndex <= end) {
    previousItems = items.slice(focusedIndex, start);
  } else if (focusedIndex > end) {
    previousItems = items.slice(end, focusedIndex);
    direction = -1;
  }

  return (
    previousItems.reduce(
      (accumulator: number, item: { type: CONTENT_TYPES }) => {
        const artworkType =
          CONTENT_TYPE_TO_ARTWORK_TYPE[CONTENT_TYPES[item.type]];
        const { width, marginRight } = ARTWORK_SIZES[artworkType];
        return accumulator + width + marginRight;
      },
      0
    ) * direction
  );
};

export default calculateListTitleTranslate;
