import CollectionTypes from 'constants/collection-types';

export const APP_ROW_UPDATES: DefaultKeyStringMap = {
  [CollectionTypes.netflix_list]: 'Netflix',
  [CollectionTypes.prime_video_list]: 'PrimeVideo',
  [CollectionTypes.bbc_list]: 'bbciplayer',
  [CollectionTypes.itvhub_list]: 'itvhub',
  [CollectionTypes.all4_list]: 'all4',
  [CollectionTypes.on_now_list]: 'OnNow',
  [CollectionTypes.freeview_list]: 'fvp'
};

export const APP_ROW_UPDATES_CLEAR: DefaultKeyStringMap = {
  [CollectionTypes.netflix_list]: 'Netflix_clear',
  [CollectionTypes.prime_video_list]: 'PrimeVideo_clear'
};
