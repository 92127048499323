import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';

export interface FocusData {
  focusKey?: string;
  focusedListIndex?: number;
  [key: string]: any;
}

export interface FocusState {
  [page: string]: FocusData;
}

export interface SaveFocusPayload {
  page: string;
  itemIndex?: number;
  listIndex?: number;
  focusKey?: string;
}

export interface ResetFocusPayload {
  page: string;
}

export const focusSlice = createSlice({
  name: 'focuses',
  initialState: {} as FocusState,
  reducers: {
    saveFocusData: (state, { payload }: PayloadAction<SaveFocusPayload>) => {
      const { page, itemIndex = 0, listIndex, focusKey } = payload;

      state[page] = {
        ...(state[page] || {}),
        ...(focusKey && { focusKey }),
        ...(typeof listIndex !== 'undefined' && {
          focusedListIndex: listIndex,
          [listIndex.toString()]: { focusItemIndex: itemIndex, focusKey }
        })
      };
    },
    resetFocusData: (state, { payload }: PayloadAction<ResetFocusPayload>) => {
      const { page } = payload;
      if (state[page]) {
        const newState: FocusData = {
          focusedListIndex: 0
        };
        Object.keys(state[page]).forEach((listIndex) => {
          if (listIndex !== 'focusedListIndex' && listIndex !== 'focusKey') {
            newState[listIndex.toString()] = 0;
          }
        });
        state[page] = newState;
      }
    }
  }
});

export const saveFocusData = createAction<SaveFocusPayload>(
  'focuses/saveFocusData'
);

export const resetFocusData = createAction<ResetFocusPayload>(
  'focuses/resetFocusData'
);

export default focusSlice.reducer;
