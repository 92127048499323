import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import ChannelList, { Line } from './ChannelList';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import Text from 'elements/Text';
import { differenceInMinutes, parseISO } from 'date-fns';
import { roundToNearestHalfHour } from 'utils/helpers';
import TimeLine from './TimeLine';
import { setGenreId } from 'store/features/channels/channelsSlice';
import {
  CHANNEL_LIST_ITEM_HEIGHT,
  EPG_TIME_SLOT_WIDTH
} from '../../../constants';
import {
  useFocusable,
  FocusContext,
  setFocus
} from '@noriginmedia/norigin-spatial-navigation';
import { Direction } from 'constants/rc-direction';

export const TimeTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 20%;
  right: 0;
  z-index: 5;
  background-color: ${({ theme }) => theme.colors.black03};
  display: flex;
  flex-direction: column;
`;
export const TimeTableInnerWrapper = styled.div`
  overflow: hidden;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    z-index: 23;
    background: linear-gradient(rgba(0, 0, 0, 0.8), transparent);
  }
`;

export const GenreTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
`;

const getFocusedProgram = (epgByGenre: any, currentChannelId: any) => {
  const currentTime = new Date();
  let fallbackProgram = null;

  for (let genre in epgByGenre) {
    let channels = epgByGenre[genre];

    for (let channel of channels) {
      // Check for the current channel and its timeline
      if (channel.id === Number(currentChannelId) && channel?.timeline) {
        for (let program of channel?.timeline) {
          const startTime = new Date(program.startTime);
          const endTime = new Date(program.endTime);
          if (currentTime >= startTime && currentTime <= endTime) {
            return program;
          }
        }
      }

      if (!fallbackProgram && channel?.timeline) {
        for (let program of channel?.timeline) {
          const startTime = new Date(program.startTime);
          const endTime = new Date(program.endTime);
          if (currentTime >= startTime && currentTime <= endTime) {
            fallbackProgram = program;
            break;
          } else if (currentTime >= startTime) {
            fallbackProgram = program;
            break;
          }
        }
      }
    }
  }

  return fallbackProgram;
};

interface TimeTableProps {
  isVisible: boolean;
  onClose: () => void;
}

const TimeTable: FC<TimeTableProps> = ({ isVisible, onClose }) => {
  const dispatch = useAppDispatch();

  const genres = useAppSelector((state) => state.channels?.genres);
  const epg = useAppSelector((state) => state.channels?.epgByGenre);
  const currentChannelId = useAppSelector(
    (state) => state.channels?.currentChannelId
  );
  const genreId = useAppSelector((state) => state.channels?.currentGenreId);

  const { focusKey, focusSelf } = useFocusable({});

  const [translateY, setTranslateY] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [currentItem, setCurrentItem] = useState(0);
  const [currentItemHor, setCurrenItemHor] = useState(0);
  const [itemHorWidth, setItemHorWidth] = useState(0);

  const [hours, setHours] = useState(6); // initial hours value = 6
  const [start, setStart] = useState(new Date().toUTCString());

  const [totalChannelsPerGenre, setTotalChannelsPerGenre] = useState<number[]>(
    []
  );
  const [channelHeights, setChannelHeights] = useState([]);

  useEffect(() => {
    let index = genres.findIndex((g) => g.id === genreId);
    let translateY = channelHeights
      .slice(0, index)
      .reduce((sum, height) => sum + (height || 0), 0);
    setTranslateY(-translateY);
  }, [genreId, channelHeights]);

  const reportHeight = (height: number, genreId: number) => {
    setChannelHeights((oldHeights) => {
      let newHeights = [...oldHeights];
      let index = genres.findIndex((g) => g.id === genreId);
      // @ts-ignore
      newHeights[index] = height;
      return newHeights;
    });
  };

  useEffect(() => {
    const currentProgram = getFocusedProgram(epg, currentChannelId);
    const focusKey = `${currentProgram?.content?.id}-parentId-${currentProgram?.content?.parentId}`;
    !currentProgram ? focusSelf() : setFocus(focusKey);
  }, [focusSelf]);

  useEffect(() => {
    const totalChannels = genres.map((genre) => epg[genre.id]?.length);
    setTotalChannelsPerGenre(totalChannels);
  }, [epg, genres]);

  useEffect(() => {
    setTranslateY(-currentItem * Number(CHANNEL_LIST_ITEM_HEIGHT));
  }, [currentItem]);

  useEffect(() => {
    const translateX = -itemHorWidth > -1300 ? -itemHorWidth : -1300;
    setTranslateX(translateX);
  }, [currentItemHor, itemHorWidth]);

  const onFocus = (
    index: number,
    indexHr: number,
    pr: any,
    genreListIndex: number,
    genre: any
  ) => {
    const start = roundToNearestHalfHour(parseISO(pr.startTime));
    const now = roundToNearestHalfHour(new Date());
    const difInMin = differenceInMinutes(start, now);
    const difInPx =
      (difInMin * (EPG_TIME_SLOT_WIDTH * hours * 2)) / (hours * 60);
    const previousGenresChannelCount = totalChannelsPerGenre
      .slice(0, genreListIndex)
      .reduce((a, b) => a + b, 0);
    setCurrentItem(previousGenresChannelCount + index);
    setCurrenItemHor(indexHr);

    if (start.getTime() > now.getTime()) {
      setItemHorWidth(difInPx);
    } else {
      setItemHorWidth(0);
    }
    dispatch(setGenreId({ genreId: genre.id }));
  };

  const onArrowPress = (
    direction: any,
    itemIndex: number,
    horizontalList: any[],
    index: any,
    channels: any
  ) => {
    if (direction === Direction.UP) {
      return index > 0;
    } else if (direction === Direction.DOWN) {
      return index < channels.length - 1;
    }
    return true;
  };

  const offsetPercentage = Math.round(
    (differenceInMinutes(new Date(), roundToNearestHalfHour(new Date())) / 30) *
      100
  );
  if (!isVisible) {
    return null;
  }

  return (
    <FocusContext.Provider value={focusKey}>
      <TimeTableWrapper>
        <Line offset={offsetPercentage * 2.96} translateX={translateX} />
        <TimeLine start={start} hours={hours} translateX={translateX} />
        <TimeTableInnerWrapper>
          {genres.map((genre, index) => (
            <div key={`genre - ${genre.id}`}>
              {epg[genre.id] && epg[genre.id]?.length > 0 ? (
                <>
                  <GenreTitle>{genre.title}</GenreTitle>
                  <ChannelList
                    onClose={onClose}
                    isVisible={isVisible}
                    channels={epg[genre.id]}
                    genre={genre}
                    hours={hours}
                    onFocus={(
                      indexList: number,
                      indexHr: number,
                      program: any
                    ) => onFocus(indexList, indexHr, program, index, genre)}
                    onArrowPress={onArrowPress}
                    translateY={translateY}
                    translateX={translateX}
                    reportHeight={(height: any) =>
                      reportHeight(height, genre.id)
                    }
                  />
                </>
              ) : null}
            </div>
          ))}
        </TimeTableInnerWrapper>
      </TimeTableWrapper>
    </FocusContext.Provider>
  );
};

export default TimeTable;
