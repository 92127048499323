import React, { useState } from 'react';
import styled from 'styled-components';
import Focusable from 'components/Navigation/Focusable';
import { executeAction } from 'utils/actions';
import theme from 'styles/theme';
import CurrentTime from './CurrentTime';
import scaleSize from 'utils/scale-size';

const { SourceIcon, SettingsIcon, EpgIcon } = theme.icons;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: white;
  padding-right: ${scaleSize(60)}px;
`;

const MenuIcon = styled(Focusable)<{
  isFocused: boolean;
}>`
  position: relative;
  display: inline-block;
  padding: 0 ${scaleSize(24)}px;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white02};

  &:hover,
  &:focus,
  &.focused {
    color: ${({ theme, isFocused }) =>
      isFocused ? theme.colors.white : theme.colors.white04};
    background: linear-gradient(
      to top,
      ${({ theme }) => theme.gradientFrom} 0%,
      ${({ theme }) => theme.gradientTo}
    );

    &:before {
      content: '';
      position: absolute;
     bottom: -1px;
      left: 0;
      right: 0;
      height: ${scaleSize(4)}px;
      background: ${({ theme }) => theme.colors.primary};
    }
`;

type Buttons = Array<{
  Component: React.FC<{ isFocused: boolean }>;
  name: string;
  action: string;
}>;

const buttons: Buttons = [
  {
    Component: EpgIcon,
    name: 'open-epg',
    action: 'open-epg'
  },
  {
    Component: SourceIcon,
    name: 'open-sources',
    action: 'open-sources'
  },
  {
    Component: SettingsIcon,
    name: 'open-settings',
    action: 'open-settings'
  }
];

const MenuIcons: React.FC<{ hasFocusedChild?: boolean }> = ({
  hasFocusedChild
}) => {
  const [focusedIcon, setFocusedIcon] = useState('');

  return (
    <IconsWrapper>
      {buttons.map(({ Component, name, action }) => (
        <MenuIcon
          isFocused={name === focusedIcon && !!hasFocusedChild}
          focusKey={`menu-item-icon-${name}`}
          key={`menu-icon-${name}`}
          onEnterPress={() => hasFocusedChild && executeAction(action, {})}
          onFocus={() => setFocusedIcon(name)}
          onBlur={() => setFocusedIcon('')}
        >
          <Component isFocused={name === focusedIcon} />
        </MenuIcon>
      ))}
      <CurrentTime />
    </IconsWrapper>
  );
};

export default MenuIcons;
