interface Sizes {
  [key: string]: string;
}

export default function resizeImage(src: string | null, sizes: Sizes) {
  if (!src) {
    return '';
  }
  const size = Object.keys(sizes)
    .map((s) => {
      if (isNaN(Number(sizes[s]))) {
        return `${s}/${sizes[s].replace(/\D/g, '')}`;
      }
      return `${s}/${sizes[s]}`;
    })
    .join('/');
  return src.replace('/img/', `/img/${size}/quality/95/`);
}
