export const ACTION_TYPES = {
  SHOW_LOADER: 'showLoader',
  HIDE_LOADER: 'hideLoader',
  SET_LOADER_PROGRESS: 'setLoaderProgress'
} as const;

type ActionTypes = typeof ACTION_TYPES;
type Actions = {
  [Property in keyof ActionTypes]: {
    type: ActionTypes[Property];
    payload?: {
      progress: number;
    };
  };
};
type DispatchType = (args: Actions[keyof Actions]) => void;

export const showLoader = () => (dispatch: DispatchType) => {
  dispatch({
    type: ACTION_TYPES.SHOW_LOADER
  });
};

export const hideLoader = () => (dispatch: DispatchType) => {
  dispatch({
    type: ACTION_TYPES.HIDE_LOADER
  });
};

export const setLoaderProgress =
  (progress: number) => (dispatch: DispatchType) => {
    dispatch({
      type: ACTION_TYPES.SET_LOADER_PROGRESS,
      payload: {
        progress
      }
    });
  };

export const simulateLoaderProgress = () => (dispatch: DispatchType) => {
  dispatch({
    type: ACTION_TYPES.SET_LOADER_PROGRESS,
    payload: {
      progress: Math.round(Math.random() * 67)
    }
  });
};
