import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/hooks';
import { hideLoader, setLoaderProgress } from 'store/features/loader/actions';
import {
  fetchChannels,
  fetchFavoriteChannels
} from 'store/features/channels/action';

const useFetchData = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setLoaderProgress(100));
    dispatch(hideLoader());
    dispatch(fetchChannels());
    dispatch(fetchFavoriteChannels());
  }, [dispatch]);
};

export default useFetchData;
