import React from 'react';
import styled from 'styled-components';
import scaleSize from 'utils/scale-size';
import Button from 'elements/Button';
import {
  devActions,
  DevActionWithModal,
  DevActionWithFunction
} from '../DevTools/DevTools';
import { showModal } from 'store/features/modals/actions';
import MODAL_TYPES from 'constants/modal-types';
import { useAppDispatch } from '../../hooks/hooks';

const ModalWrapper = styled.div`
  width: ${scaleSize(720)}px;
  border-radius: ${scaleSize(16)}px;
  background: ${({ theme }) => theme.colors.grey3};
  max-height: 80vh;
  overflow: hidden;
`;

const ButtonsWrapper = styled.div`
  ${({ theme }) => theme.margin('l', 'top')};
  padding-bottom: ${({ theme }) => theme.marginSizes.s}px;
`;

const ButtonWrapper = styled(Button)`
  display: block;
  ${({ theme }) => theme.margin('l', 'right')};
  ${({ theme }) => theme.margin('l', 'left')};
  ${({ theme }) => theme.margin('s', 'bottom')};
  text-align: center;
`;

const DevToolsModal: React.FC<{ data: any; closeModal: any }> = ({
  closeModal
}) => {
  const dispatch = useAppDispatch();
  const handleClick = (action: DevActionWithModal | DevActionWithFunction) => {
    if (action.type === 'modal') {
      dispatch(showModal(MODAL_TYPES[action.modal], {}, ''));
    } else if (action.type === 'function') {
      action.fn();
    }
  };

  return (
    <ModalWrapper>
      <ButtonsWrapper>
        {Object.keys(devActions).map(
          (action: any) =>
            !devActions[action].isHidden && (
              <ButtonWrapper
                key={`dev-action-${devActions[action].title}`}
                title={`${devActions[action].title}`}
                handleClick={() => handleClick(devActions[action])}
                focusKey={`set-dev-action-${action}`}
              />
            )
        )}
      </ButtonsWrapper>
    </ModalWrapper>
  );
};

export default DevToolsModal;
