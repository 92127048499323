import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addToFavorites,
  fetchApps,
  fetchMeApps,
  getApp,
  removeFromFavorites
} from 'store/features/apps/actions';

interface AppState {
  id: number;
  name: string;
  type: string;
  content_list_app_name?: string;
  content_list_app_type?: string;
  icon?: string;
  background_image?: string;
  image_dominant_color?: string;
  description?: string;
  logo?: string;
  classification?: { description?: string };
  isLoading?: boolean;
}

interface Apps {
  allApps: {
    data: { collection: AppState[] };
    isLoading?: boolean;
    isSuccess?: boolean;
    errorMessage?: string;
  };
  favorites: {
    data: { collection: any };
    isLoading?: boolean;
    isSuccess?: boolean;
    errorMessage?: string;
  };
  selectedApp: AppState;
}

const initialState: Apps = {
  allApps: {
    data: {
      collection: []
    },
    isLoading: false,
    isSuccess: false,
    errorMessage: ''
  },
  favorites: {
    data: {
      collection: []
    },
    isLoading: false,
    isSuccess: false,
    errorMessage: ''
  },
  selectedApp: {
    id: 0,
    type: '',
    name: '',
    icon: '',
    isLoading: false
  }
};

export const appsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    removeAppById: (
      state: Apps,
      { payload }: PayloadAction<{ id: string | number }>
    ) => {
      const collection = [...state.favorites.data.collection];
      const appIndex = collection.findIndex((app) => {
        return app.id === payload.id;
      });

      console.log('==> appIndex', appIndex, payload.id);

      if (appIndex !== -1) {
        collection.splice(appIndex, 1);
      }

      state.favorites = {
        ...state.favorites,
        data: { ...state.favorites.data, collection }
      };
    },
    updateListOrder: (
      state: Apps,
      {
        payload
      }: PayloadAction<{ listId: string; index1: number; index2: number }>
    ) => {
      const collection = [...state.favorites.data.collection];

      [collection[payload.index1], collection[payload.index2]] = [
        collection[payload.index2],
        collection[payload.index1]
      ];

      state.favorites = {
        ...state.favorites,
        data: { ...state.favorites.data, collection }
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApps.pending, (state, { meta }) => {
        state.allApps = state.allApps || {
          data: { collection: [] }
        };
        state.allApps.isLoading = true;
      })
      .addCase(
        fetchApps.fulfilled,
        (state, { payload, meta }: ReturnType<typeof fetchApps.fulfilled>) => {
          state.allApps.isLoading = false;
          state.allApps.isSuccess = true;
          state.allApps.data = payload as unknown as Apps['allApps']['data'];
        }
      )
      .addCase(
        fetchApps.rejected,
        (state, { payload, meta }: ReturnType<typeof fetchApps.rejected>) => {
          state.allApps.isLoading = false;
          state.allApps.isSuccess = false;
          state.allApps.errorMessage = payload as string;
        }
      );
    builder
      .addCase(removeFromFavorites.pending, (state, { meta }) => {
        state.selectedApp.isLoading = true;
      })
      .addCase(
        removeFromFavorites.fulfilled,
        (
          state,
          { payload, meta }: ReturnType<typeof removeFromFavorites.fulfilled>
        ) => {
          state.selectedApp.isLoading = false;
        }
      )
      .addCase(
        removeFromFavorites.rejected,
        (
          state,
          { payload, meta }: ReturnType<typeof removeFromFavorites.rejected>
        ) => {
          state.selectedApp.isLoading = false;
        }
      );
    builder
      .addCase(addToFavorites.pending, (state, { meta }) => {
        state.selectedApp.isLoading = true;
      })
      .addCase(
        addToFavorites.fulfilled,
        (
          state,
          { payload, meta }: ReturnType<typeof addToFavorites.fulfilled>
        ) => {
          state.selectedApp.isLoading = false;
        }
      )
      .addCase(
        addToFavorites.rejected,
        (
          state,
          { payload, meta }: ReturnType<typeof addToFavorites.rejected>
        ) => {
          state.selectedApp.isLoading = false;
        }
      );
    builder
      .addCase(fetchMeApps.pending, (state, { meta }) => {
        state.favorites = state.favorites || {
          data: { collection: [] }
        };
        state.favorites.isLoading = true;
      })
      .addCase(
        fetchMeApps.fulfilled,
        (state, { payload, meta }: ReturnType<typeof fetchApps.fulfilled>) => {
          state.favorites.isLoading = false;
          state.favorites.isSuccess = true;
          state.favorites.data =
            payload as unknown as Apps['favorites']['data'];

          state.favorites.data.collection.sort(
            (a: any, b: any) => a.position - b.position
          );
        }
      )
      .addCase(
        fetchMeApps.rejected,
        (state, { payload, meta }: ReturnType<typeof fetchApps.rejected>) => {
          state.favorites.isLoading = false;
          state.favorites.isSuccess = false;
          state.favorites.errorMessage = payload as string;
        }
      );

    builder
      .addCase(getApp.pending, (state, { meta }) => {
        state.selectedApp.isLoading = true;
        state.favorites.isLoading = true;
      })
      .addCase(
        getApp.fulfilled,
        (state, { payload, meta }: ReturnType<typeof getApp.fulfilled>) => {
          state.favorites.isLoading = false;
          state.selectedApp = payload;
        }
      )
      .addCase(
        getApp.rejected,
        (state, { payload, meta }: ReturnType<typeof getApp.rejected>) => {
          state.favorites.isLoading = false;
          state.favorites.errorMessage = payload as string;
        }
      );
  }
});

export default appsSlice.reducer;
