import React, { FC } from 'react';
import styled from 'styled-components';
import Focusable from 'components/Navigation/Focusable';
import theme from 'styles/theme';
import scaleSize from 'utils/scale-size';
import { marginSizes } from 'styles/base';

export const Item = styled(Focusable)<{ isCurrent: boolean }>`
  ${theme.typography.secondary_txt_medium};
  display: flex;
  align-items: center;
  height: ${scaleSize(64)}px;
  padding-left: ${marginSizes.l}px;
  z-index: 12;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ isCurrent, theme }) =>
    isCurrent ? theme.colors.white : theme.colors.gray05};
  border-right: ${({ isCurrent }) =>
    isCurrent ? '4px solid' + theme.colors.white : null};

  &:hover,
  &:focus,
  &.focused {
    color: ${({ theme }) => theme.colors.white};
  }
`;

interface GenreProps {
  focusKey: string;
  id: number;
  name: string;
  onArrowPress: any;
  onFocus: any;
  isCurrent: boolean;
}
const GenreItem: FC<GenreProps> = ({
  focusKey,
  onFocus,
  onArrowPress,
  id,
  isCurrent,
  name
}) => {
  return (
    <Item
      isCurrent={isCurrent}
      onArrowPress={(direction) => onArrowPress(direction)}
      onFocus={onFocus}
      focusKey={focusKey}
    >
      <span>{name}</span>
    </Item>
  );
};
export default GenreItem;
