import { createSlice, CreateSliceOptions } from '@reduxjs/toolkit';
import { tvSignIn, tvSignUp } from 'store/features/auth/actions';
import Local from 'utils/storage/local';

const initialState = {
  isLoading: false,
  isAuthenticated: Local.authenticated(),
  errorMessage: ''
};

const sliceOptions: CreateSliceOptions = {
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(tvSignIn.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(tvSignIn.rejected, (state) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        // TODO: Use copy from a dictionary for localization in the future
        state.errorMessage = 'Sign in failed';
        Local.deleteAuthenticatedUser();
      })
      .addCase(
        tvSignIn.fulfilled,
        (state, { payload }: ReturnType<typeof tvSignIn.fulfilled>) => {
          state.isLoading = false;
          state.isAuthenticated = true;
          Local.setToken(payload.authToken);
          Local.setUserId(payload.id);
          Local.setUserEmail(payload.email);
        }
      )
      .addCase(tvSignUp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(tvSignUp.rejected, (state) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        // TODO: Use copy from a dictionary for localization in the future
        state.errorMessage = 'Sign up failed';
        Local.deleteAuthenticatedUser();
      })
      .addCase(tvSignUp.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        Local.setToken(payload.authToken);
        Local.setUserId(payload.id);
        Local.setUserEmail(payload.email);
      });
  }
};

const authSlice = createSlice(sliceOptions);
export default authSlice.reducer;
