import React, { FC, useEffect } from 'react';
import { setFocus } from '@noriginmedia/norigin-spatial-navigation';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import usePrevious from 'hooks/usePrevious';
import {
  hideLoader,
  setLoaderProgress,
  simulateLoaderProgress
} from 'store/features/loader/actions';
import { fetchDetails } from 'store/features/details/actions';
import { CAST_TYPES, CAST_VALUES } from 'constants/cast-types';
import CONTENT_TYPES from 'constants/content-types';
import {
  ContentTitle,
  DetailsEntry,
  DetailsInnerWrapper,
  DetailsRow,
  DetailsWrapper,
  Synopsis,
  ButtonsRow
} from './Details.styles';
import Button from 'elements/Button';
import { executeAction } from 'utils/actions';

interface DetailsProps {
  type: CONTENT_TYPES;
  contentId: any;
}

type CastType = keyof typeof CAST_TYPES;

interface CastItem {
  type: CastType;
  person: { name: string };
}

const DetailsYear = ({ year }: { year?: number }) => {
  return <>{year && <DetailsEntry>{year}</DetailsEntry>}</>;
};

const Classification = ({
  classification
}: {
  classification?: { name: string; description: string };
}) => {
  return (
    <>
      {classification && (
        <DetailsEntry>
          {classification.name}: {classification.description}
        </DetailsEntry>
      )}
    </>
  );
};

const Cast = ({ type, cast = [] }: { type: CastType; cast?: CastItem[] }) => {
  const filteredCast = cast.filter((cast) => cast.type === type);
  return (
    <>
      {filteredCast?.length && (
        <DetailsEntry>
          {CAST_VALUES[type]}:{' '}
          {filteredCast.map((cast) => cast.person.name).join(', ')}
        </DetailsEntry>
      )}
    </>
  );
};

interface DeepLinksProps {
  deeplinkings: [
    {
      app_id: number;
      app_name: string;
      url: string;
      app: App;
    }
  ];
}

const DeepLinks: FC<DeepLinksProps> = ({ deeplinkings }) => {
  const appData = useAppSelector((state) => {
    const appsList = state.apps.favorites?.data?.collection || [];

    const app =
      appsList.find((app: any) => app.id === deeplinkings[0].app_id) || {};

    return { ...app, url: deeplinkings[0].url };
  });

  useEffect(() => {
    setFocus('open-deeplink');
  }, []);

  return (
    <ButtonsRow>
      <Button
        title={`Open in ${deeplinkings[0].app_name}`}
        focusKey={'open-deeplink'}
        handleClick={() => executeAction('launch-app', appData)}
      />
    </ButtonsRow>
  );
};

const Details: FC<DetailsProps> = ({ type, contentId }) => {
  const { isLoading } = useAppSelector((state) => ({
    isLoading: !!state.details?.[type]?.[contentId]?.isLoading
  }));

  const { details } = useAppSelector((state) => ({
    details: (type && state.details?.[type]?.[contentId]) || null
  }));

  const wasLoading = usePrevious(isLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (wasLoading && !isLoading) {
      dispatch(setLoaderProgress(100));
      dispatch(hideLoader());
    }
  }, [details]);

  useEffect(() => {
    dispatch(simulateLoaderProgress());
    type && dispatch(fetchDetails({ contentId, type }));
  }, [type, contentId]);

  return (
    <>
      {details?.data && (
        <DetailsWrapper bg={details.data?.images?.screenshot_landscape || ''}>
          <DetailsInnerWrapper>
            <ContentTitle>{details.data?.title}</ContentTitle>
            <DetailsRow>
              <DetailsYear year={details?.data?.year} />
              <Classification classification={details?.data?.classification} />
              <Cast cast={details?.data?.casts} type={CAST_TYPES.director} />
              <Cast cast={details?.data?.casts} type={CAST_TYPES.actor} />
            </DetailsRow>
            <Synopsis>{details.data?.synopsis}</Synopsis>
            <DeepLinks deeplinkings={details.data?.deeplinkings} />
          </DetailsInnerWrapper>
        </DetailsWrapper>
      )}
    </>
  );
};

export default Details;
