// TODO: Figure out where to get the proper list names (dictionary or BE?)
const CUSTOM_LIST_TITLES: DefaultKeyStringMap = {
  netflix_list: 'Netflix',
  prime_video_list: 'Prime Video',
  disney_list: 'Disney',
  rakuten_tv_list: 'Rakuten',
  youtube_list: 'Youtube'
};

export default CUSTOM_LIST_TITLES;
