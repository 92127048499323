import {
  FocusContext,
  getCurrentFocusKey,
  setFocus,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import ListItem from 'components/List/Item';
import ARTWORK_TYPES from 'constants/artwork-types';
import CollectionTypes from 'constants/collection-types';
import CONTENT_TYPES from 'constants/content-types';
import CUSTOM_LIST_TITLES from 'constants/custom-list-names';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import useAppRowUpdate from 'hooks/useAppRowUpdate';
import usePathname from 'hooks/usePathname';
import useTabVisibility from 'hooks/useTabVisibility';
import {
  FC,
  ReactElement,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { fetchList } from 'store/features/lists/actions';
import { executeAction } from 'utils/actions';
import calculateListTranslate from 'utils/calculate-list-translate';
import ListHeader from './Header/ListHeader';
import { ListItemProps } from './Item/types';
import { ListItemsWrapper, ListWrapper } from './List.styles';
import { getListPlaceholder } from './ListPlaceholder';
import { useListVisibility } from './useListVisibility';
import FOCUS_KEYS from 'constants/focus-keys';

interface ListProps {
  id: string;
  data: {
    id: string;
    name: string;
    type: string;
    branded_image?: string;
  };
  listData?: any;
  prefix: string;
  showArtworkTitle?: boolean;
  onFocus: (listIndex: number, itemIndex: number, focusKey?: string) => void;
  listIndex: number;
  type: ARTWORK_TYPES;
  onLoad?: (listIndex: number, itemIndex?: number, focusKey?: string) => void;
  query?: string;
  listFocusKey?: string;
  listTitle: string;
  isFocusable?: boolean;
  isBelowFocused: boolean;
  isStatic?: boolean;
  listOffset?: number;
  onArrowPress?: any;
  onBlur?: any;
  setListsOffsetAfterIndex?: any;
  itemOverlayComponent?: (item: any) => ReactElement;
  renderItemOverlay?: (item: any) => boolean;
}

const MAX_VISIBLE_ITEMS = 6;
const TRACKER_TIMEOUT_DELAY = 2200;

let isFocusResolved = false;

const GenericList: FC<ListProps> = memo(
  ({
    data,
    onFocus,
    onArrowPress,
    prefix,
    listIndex,
    listOffset,
    type,
    onLoad,
    query,
    listData,
    isFocusable = true,
    listFocusKey,
    isBelowFocused,
    isStatic,
    onBlur,
    showArtworkTitle,
    itemOverlayComponent,
    renderItemOverlay = () => true
  }) => {
    const dispatch = useAppDispatch();
    const listId = `${prefix}${data.type}_${data.id}`;
    const [focusedIndex, setFocusedIndex] = useState(0);
    const [visibleItems, setVisibleItems] = useState<ListItemProps[]>([]);
    const [trackElementsTimer, setTrackElementsTimer] = useState<number | null>(
      null
    );
    const { list } = useAppSelector((state) => {
      const list = listData || state.lists[listId];
      const title = state.lists?.[listId]?.data?.title;
      const isLoading = state.lists?.[listId]?.isLoading;
      return {
        list,
        title,
        isLoading
      };
    });
    const focusParams = {
      focusKey: listFocusKey || `focusable-list-${listIndex}`,
      trackChildren: true,
      focusable: !!list?.data?.collection?.length && isFocusable,
      onBlur: onBlur
    };

    const { ref, focusKey, hasFocusedChild } = useFocusable(focusParams);

    const isListVisible = useListVisibility(ref);
    const { isTabVisible } = useTabVisibility();
    const allLists = useAppSelector((state) => state.lists);

    const { pathname } = usePathname();

    useEffect(() => {
      const firstList = Object.values(allLists)?.[0];
      const firstItemFocusKey = `focusable-${listId}-${
        firstList?.data?.collection?.[0]?.id || 0
      }`;

      const isMenuItemFocused = getCurrentFocusKey()?.startsWith('menu-item');
      const isPage = pathname.startsWith('/page');
      const isCurrentFocusedItem = getCurrentFocusKey() === firstItemFocusKey;
      if (
        !isCurrentFocusedItem &&
        isMenuItemFocused &&
        isPage &&
        !isFocusResolved
      ) {
        setFocus(firstItemFocusKey);
        setFocusedIndex(0);
        isFocusResolved = true;
      }
    }, [getCurrentFocusKey()]);

    useEffect(() => {
      const isHomePage = pathname === '/';
      const firstList = Object.values(allLists)?.[0];
      let firstItemFocusKey = `focusable-${listId}-${
        firstList?.data?.collection[0]?.id || 0
      }`;

      if (isHomePage) {
        firstItemFocusKey = `focusable-${listId}-${
          list?.data?.collection[0]?.id || 0
        }`;
        
      }

      if (!isTabVisible) {
        setFocus(firstItemFocusKey);
        setFocusedIndex(0);
        isHomePage && setFocus(FOCUS_KEYS.watchTv);
      }
    }, [isTabVisible]);

    useEffect(() => {
      const isNetflixList = data.type !== CollectionTypes.netflix_list;
      if (isNetflixList || !isListVisible || !isTabVisible) {
        return;
      }
      const newVisibleItems = list?.data?.collection.slice(
        focusedIndex,
        Math.min(
          focusedIndex + MAX_VISIBLE_ITEMS,
          list?.data?.collection.length
        )
      );
      setVisibleItems(newVisibleItems);
      newVisibleItems.length > 0 && trackElements(newVisibleItems);
    }, [data, isListVisible, isTabVisible]);

    useEffect(() => {
      if (trackElementsTimer !== null) {
        clearTimeout(trackElementsTimer);
      }
      const newTimer = setTimeout(() => {
        const allVisibleItems =
          list?.data?.collection.slice(
            focusedIndex,
            Math.min(
              focusedIndex + MAX_VISIBLE_ITEMS,
              list?.data?.collection.length
            )
          ) || [];
        const newVisibleItems = allVisibleItems.filter(
          (item: ListItemProps) => !visibleItems.includes(item)
        );
        setVisibleItems(allVisibleItems);

        if (
          newVisibleItems.length > 0 &&
          data.type === CollectionTypes.netflix_list
        ) {
          trackElements(newVisibleItems);
        }
      }, TRACKER_TIMEOUT_DELAY);

      setTrackElementsTimer(newTimer as unknown as number);

      return () => {
        newTimer !== null && clearTimeout(newTimer);
      };
    }, [focusedIndex, visibleItems, list]);

    const trackElements = (items: ListItemProps[]) => {
      const deepLinks = items.map((i: any) => i.deeplinkings[0]?.url);
      executeAction('send-netflix-impressions', {
        impressionDeepLinks: deepLinks
      });
    };

    const translateX = calculateListTranslate(
      list?.data?.collection || [],
      focusedIndex
    );

    const fetchListData = (e?: string) => {
      const fetchParams = { data, listId, query, ...(e && { e }) };
      dispatch(fetchList(fetchParams)).then(() => {
        onLoad && onLoad(listIndex);
      });
    };

    useAppRowUpdate(data.type, fetchListData);

    useEffect(() => {
      if (!list?.data?.collection?.length || query) {
        fetchListData();
      } else if (list?.data) {
        onLoad && onLoad(listIndex);
      }
    }, [query, listId]);

    useEffect(() => {
      if (focusedIndex !== 0 && list && list.pagination) {
        const {
          pagination: { nextPage = null, perPage = 14, currentPage = 1 }
        } = list;
        const focusKey = getCurrentFocusKey();

        if (
          focusedIndex !== 0 &&
          focusedIndex > perPage * currentPage - perPage / 2 &&
          nextPage
        ) {
          dispatch(fetchList({ data, listId, page: nextPage, query })).then(
            () => {
              onLoad && onLoad(listIndex, focusedIndex, focusKey);
            }
          );
        }
      }
    }, [focusedIndex]);

    const setFocusedIndexCallback = useCallback(
      (listIndex: number, index: number, focusKey: string) => {
        setFocusedIndex(index);
        onFocus(listIndex, index, focusKey);
      },
      [focusedIndex]
    );

    const listTitle = `${data.name || CUSTOM_LIST_TITLES[data.type]}`;
    const placeholder = getListPlaceholder(
      { data: list?.data?.collection || [], isLoading: list?.isLoading },
      type,
      listTitle
    );

    if (placeholder) {
      return placeholder;
    }

    const count = list.lastQuery ? ` (${list.pagination.totalCount})` : '';
    const firstItem = list.data.collection[0];
    const hasMetadata =
      firstItem.type !== CONTENT_TYPES.app &&
      firstItem.title &&
      hasFocusedChild;

    return (
      <ListWrapper
        ref={ref}
        key={`focusable-${listId}-normal`}
        hasMetadata={hasMetadata}
        listIndex={listIndex}
        isFocused={hasFocusedChild}
        isBelowFocused={isBelowFocused}
        isStatic={isStatic}
        listOffset={listOffset}
      >
        <FocusContext.Provider value={focusKey}>
          <ListHeader
            focusedIndex={focusedIndex}
            title={listTitle}
            count={count}
            isFocused={hasFocusedChild}
            brandedImage={data.branded_image}
            list={list.data.collection}
          />
          <ListItemsWrapper
            style={{ transform: `translate3d(-${translateX}px, 0, 0)` }}
          >
            {list.data.collection.map(
              (
                item: {
                  id: number;
                  type: CONTENT_TYPES;
                  customFocusKey?: string;
                  action?: string;
                  title?: string;
                },
                itemIndex: number
              ) => {
                const focusKey =
                  item.customFocusKey ||
                  `focusable-${listId}-${item?.id || itemIndex}`;

                return (
                  <ListItem
                    key={`list-${listIndex}-${itemIndex}`}
                    focusKey={focusKey}
                    data={item}
                    showArtworkTitle={showArtworkTitle}
                    onArrowPress={onArrowPress}
                    itemIndex={itemIndex}
                    onFocus={() => {
                      setFocusedIndexCallback(listIndex, itemIndex, focusKey);
                    }}
                  >
                    {renderItemOverlay?.(item)
                      ? itemOverlayComponent?.(item)
                      : null}
                  </ListItem>
                );
              }
            )}
          </ListItemsWrapper>
        </FocusContext.Provider>
      </ListWrapper>
    );
  }
);

export default GenericList;
