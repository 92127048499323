import {
  FocusContext,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import { FC } from 'react';
import styled from 'styled-components';
import { ChannelProgramWrapper } from './Container.styles';
import GenresMenu from './GenresMenu/GenresMenu';
import TimeTable from './TimeTable/TimeTable';
interface ChannelProgramProps {
  isVisible: boolean;
  onFocus: () => void;
  onClose: () => void;
}

const ChannelProgramDimmer = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;

const Container: FC<ChannelProgramProps> = ({
  isVisible,
  onFocus,
  onClose
}) => {
  const { focusKey } = useFocusable({ isFocusBoundary: true });

  return (
    <FocusContext.Provider value={focusKey}>
      <ChannelProgramWrapper
        isVisible={isVisible}
        onEnterPress={onFocus}
        onFocus={onFocus}
      >
        <GenresMenu isVisible={isVisible} />
        <TimeTable onClose={onClose} isVisible={isVisible} />
      </ChannelProgramWrapper>
      <ChannelProgramDimmer />
    </FocusContext.Provider>
  );
};
export default Container;
