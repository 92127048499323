import { createAction, createSlice } from '@reduxjs/toolkit';

interface BottomBarState {
  isVisible: boolean;
  hint: any;
}

const initialState: BottomBarState = {
  isVisible: true,
  hint: ''
};

export const bottomBarSlice = createSlice({
  name: 'bottomBar',
  initialState,
  reducers: {
    showBottomBar: (state) => {
      state.isVisible = true;
    },
    hideBottomBar: (state) => {
      state.isVisible = false;
    },
    setHint: (state, { payload }) => {
      state.hint = payload.hint;
    }
  }
});
export const showBottomBar = createAction('bottomBar/showBottomBar');
export const hideBottomBar = createAction('bottomBar/hideBottomBar');
export const setHint = createAction<{ hint: string }>('bottomBar/setHint');

export default bottomBarSlice.reducer;
