import styled from 'styled-components';
import scaleSize from 'utils/scale-size';
import Image from 'components/Image/Image';
import ARTWORK_SIZES from 'constants/artwork-sizes';
import ARTWORK_TYPES from 'constants/artwork-types';

interface ArtworkImageProps {
  type: keyof typeof ARTWORK_TYPES;
  src: string;
}

export const DetailsWrapper = styled.div<{ bg: string }>`
  ${({ theme }) => theme.fullScreen};
  background-image: url(${({ bg }) => bg});
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;

  &:before {
    content: '';
    ${({ theme }) => theme.fullScreen};
    z-index: 0;
    background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.7) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 67.75%),
      rgba(0, 0, 0, 0.2);
  }
`;

export const ListWrapper = styled.div`
  margin-left: -${({ theme }) => theme.marginSizes.xxl}px;
  ${({ theme }) => theme.margin('l', 'top')};
`;

export const DetailsInnerWrapper = styled.div`
  ${({ theme }) => theme.fullScreen};
  ${({ theme }) => theme.padding('xxl', 'top')};
  ${({ theme }) => theme.margins(['xxl', 0, 0, 'xxl'])};
  display: flex;
  flex-direction: column;
  gap: ${scaleSize(80)}px;
`;

export const Synopsis = styled.div`
  ${({ theme }) => theme.typography.description};
  max-width: ${scaleSize(642)}px;
  font-size: ${scaleSize(28)}px;
  line-height: ${scaleSize(40)}px;
`;

export const DetailsRow = styled.div`
  ${({ theme }) => theme.margin('xs', 'top')};
`;

export const ButtonsRow = styled.div`
  ${({ theme }) => theme.margin('l', 'top')};
`;

export const DetailsEntry = styled.div`
  color: ${({ theme }) => theme.colors.white};
`;

export const ContentTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.title};
`;

export const ArtworkImage = styled(Image)<ArtworkImageProps>`
  width: ${({ type }) => (ARTWORK_SIZES[type] || {}).width}px;
  height: ${({ type }) => (ARTWORK_SIZES[type] || {}).height}px;
  background-size: cover;
  border-radius: ${scaleSize(4)}px;
  overflow: hidden;
`;

export const LogoImageWrapper = styled.div`
  max-height: ${scaleSize(100)}px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const LogoImage = styled.img`
  max-height: ${scaleSize(100)}px;
  width: auto;
`;

export const DetailsDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${scaleSize(24)}px;
`;
export const NameGenreDescriptionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${scaleSize(16)}px;
  opacity: 0.6;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${scaleSize(24)}px;
`;

export const NameGenreDescriptionText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.secondary_txt_medium};
  font-weight: 400;
`;
