import {
  FocusContext,
  setFocus,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import ListItem from 'components/List/Item';
import ARTWORK_TYPES from 'constants/artwork-types';
import COLLECTION_TYPES from 'constants/collection-types';
import CONTENT_TYPES from 'constants/content-types';
import EDITABLE_LIST_TYPES from 'constants/editable-list-types';
import FOCUS_KEYS from 'constants/focus-keys';
import { Direction } from 'constants/rc-direction';
import { getOnTVList } from 'devices/TPV/fetchDeviceList';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import useEditMode from 'hooks/useEditMode';
import useTabVisibility from 'hooks/useTabVisibility';
import { DebouncedFunc, debounce } from 'lodash';
import { ConnectedSourcesContext } from 'providers/ConnectedInputSources';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { toggleBlur } from 'store/features/app/actions';
import { fetchMeApps } from 'store/features/apps/actions';
import { setHint } from 'store/features/bottomBar/bottomBarSlice';
import { setMenuOpacity } from 'store/features/menu/menuSlice';
import Current from 'utils/current';
import CUSTOM_LIST_TITLES from '../../constants/custom-list-names';
import calculateListTranslate from '../../utils/calculate-list-translate';
import { ListItemType } from './Item/types';
import {
  ListItemsWrapper,
  ListTitle,
  ListTitleInnerWrapper,
  ListWrapper
} from './List.styles';
import { getListPlaceholder } from './ListPlaceholder';
import { OnNow } from './OnNow';
import { useTranslation } from 'react-i18next';
import { fetchPage } from 'store/features/pages/actions';
import { useLocation } from 'react-router-dom';

interface ListProps {
  id: string;
  data: {
    id: string;
    name: string;
    type: string;
    branded_image?: string;
  };
  listData?: any;
  prefix: string;
  showArtworkTitle?: boolean;
  onFocus: (listIndex: number, itemIndex: number, focusKey: string) => void;
  listIndex: number;
  type: ARTWORK_TYPES;
  onLoad?: (listIndex: number, itemIndex?: number, focusKey?: string) => void;
  query?: string;
  listTitle?: string;
  isFocusable?: boolean;
  isBelowFocused: boolean;
  setListsOffsetAfterIndex?: any;
  listOffset?: number;
  onBlur?: () => void;
}

const HINTS = {
  options: 'app.favourites.hint.options',
  finish: 'app.favourites.hint.finish',
  delete: 'app.favourites.hint.remove'
};
const HINT_DISPLAY_TIMEOUT = 3000;

function getOnNowAvailability() {
  return Current.market() === 'gb';
}

const MeAppsList: FC<ListProps> = ({
  data,
  prefix = '_',
  onFocus: onListFocus,
  listIndex,
  type,
  onLoad,
  showArtworkTitle,
  isFocusable = true,
  onBlur,
  isBelowFocused,
  setListsOffsetAfterIndex
}) => {
  const { t } = useTranslation();
  const listId = `${prefix}${data.type}_${data.id}`;
  const debouncedDispatchRef = useRef<false | DebouncedFunc<() => void>>();
  const [onNowVisible, setOnNowVisible] = useState(false);
  const [enhancedFavouriteApps, setEnhancedFavouriteApps] = useState<
    ListItemType[]
  >(() => []);
  const connectedInputSources = useContext<InputSource[]>(
    ConnectedSourcesContext
  );
  const { state: locationState } = useLocation();

  const [focusedIndex, setFocusedIndex] = useState(() => {
    const hasNewAppData = locationState?.editMode && locationState?.appId;
    if (hasNewAppData) {
      return enhancedFavouriteApps.length - 1;
    }
    return 0;
  });

  const dispatch = useAppDispatch();
  const appFavourites = useAppSelector((state) => {
    return state.apps.favorites;
  });

  const onNowEnabled = getOnNowAvailability();

  const { ref, focusKey, hasFocusedChild } = useFocusable({
    focusKey: `focusable-list-${listIndex}`,
    trackChildren: true,
    focusable: isFocusable && !!enhancedFavouriteApps?.length,
    onBlur: onBlur,
    preferredChildFocusKey: `focusable-${listId}-${focusedIndex}`
  });

  const nonSystemAppsStartIndex = enhancedFavouriteApps?.findIndex(
    (app) => !app.isSystemApp
  );

  const isListEditable =
    hasFocusedChild &&
    EDITABLE_LIST_TYPES.includes(data.type) &&
    focusedIndex >= nonSystemAppsStartIndex;

  // TODO: abstract specific page and reload current page instead
  const currentPageId = useAppSelector((state) => {
    const homePage = state.menu.data.collection.find(
      (item) => item.position === 1
    );
    return homePage?.id;
  });

  function updatePageContent() {
    if (!currentPageId) {
      return;
    }

    dispatch(fetchPage({ pageId: currentPageId }));
  }

  const { editMode, removeFocused, toggleEditMode } = useEditMode({
    focusedIndex,
    list: enhancedFavouriteApps,
    listId,
    setFocusedIndex,
    isEditable: EDITABLE_LIST_TYPES.includes(data.type),
    canEdit: isListEditable,
    reorderMinIndex: nonSystemAppsStartIndex,
    onExitCallback: updatePageContent
  });

  useEffect(() => {
    if (!editMode) {
      dispatch(fetchMeApps()).then(() => {
        onLoad && onLoad(listIndex);
      });
    }
  }, []);

  const { isTabVisible } = useTabVisibility();
  useEffect(() => {
    if (!isTabVisible) {
      toggleEditMode(false);
    }
  }, [isTabVisible]);

  useEffect(() => {
    dispatch(setMenuOpacity({ opacity: editMode ? 0 : 1 }));
    let hint = '';
    if (editMode && removeFocused) {
      hint = '';
    } else if (editMode) {
      hint = HINTS.finish;
    } else {
      hint = HINTS.options;
    }
    debouncedDispatchRef.current && debouncedDispatchRef.current.cancel();
    isListEditable && dispatch(setHint({ hint: hint }));
  }, [editMode, removeFocused]);

  useEffect(() => {
    debouncedDispatchRef.current =
      isListEditable &&
      debounce(() => {
        dispatch(setHint({ hint: HINTS.options }));
      }, HINT_DISPLAY_TIMEOUT);

    if (isListEditable) {
      !editMode &&
        debouncedDispatchRef.current &&
        debouncedDispatchRef.current();
    } else {
      debouncedDispatchRef.current && debouncedDispatchRef.current.cancel();
      dispatch(setHint({ hint: '' }));
    }
    return () => {
      debouncedDispatchRef.current && debouncedDispatchRef.current.cancel();
    };
  }, [isListEditable]);

  useEffect(() => {
    const updatedEnhancedFavouriteApps = [...appFavourites.data.collection];

    const enhancedInputSources = [...connectedInputSources]?.map(
      (inputSource, index) => {
        return {
          id: inputSource.type,
          type: CONTENT_TYPES.app,
          name: inputSource.name,
          action: 'open-source',
          position: index + 1,
          isSystemApp: true,
          icon: '/assets/imgs/input-source.png',
          customFocusKey: 'input-source' + index + 1
        };
      }
    );

    enhancedInputSources.reverse().forEach((inputSource) => {
      updatedEnhancedFavouriteApps.unshift(inputSource);
    });

    const watchTvApp = {
      id: null,
      name: t('app.favourites.watchTv'),
      icon: '/assets/imgs/watch-tv.png',
      type: CONTENT_TYPES.app,
      isSystemApp: true,
      action: 'watch-tv',
      customFocusKey: FOCUS_KEYS.watchTv,
      position: 0
    };
    updatedEnhancedFavouriteApps.unshift(watchTvApp);

    setEnhancedFavouriteApps(updatedEnhancedFavouriteApps);
  }, [appFavourites, connectedInputSources]);

  function onItemFocus(listIndex: number, index: number, focusKey: string) {
    setFocusedIndex(index);
    onListFocus(listIndex, index, focusKey);
  }

  function toggleOnNowRow(show: boolean) {
    const onNow = getOnTVList();

    if (onNow.data.collection.length <= 0) {
      setListsOffsetAfterIndex({ index: 0, offset: 0 });
      setOnNowVisible(false);
      return;
    }

    setOnNowVisible(show);
    dispatch(toggleBlur(show));
  }

  const listTitle = data.name || CUSTOM_LIST_TITLES[data.type];
  const listState = {
    isLoading: appFavourites.isLoading || !enhancedFavouriteApps?.length,
    data: enhancedFavouriteApps || []
  };

  const Placeholder = getListPlaceholder(listState, type, listTitle);

  const translateX = calculateListTranslate(
    enhancedFavouriteApps || [],
    focusedIndex
  );

  if (Placeholder) {
    return Placeholder;
  }

  return (
    <ListWrapper
      ref={ref}
      key={`focusable-${listId}-${editMode ? 'edit' : 'normal'}`}
      editMode={editMode}
      isFocused={hasFocusedChild || onNowVisible}
      listIndex={listIndex}
      isBelowFocused={isBelowFocused}
    >
      {onNowEnabled && onNowVisible && (
        <OnNow
          onArrowPress={(direction: Direction) => {
            if (direction === Direction.UP) {
              setFocus('watch-tv');
              return false;
            } else if (direction === Direction.DOWN) {
              setListsOffsetAfterIndex({ index: 0, offset: 0 });
              toggleOnNowRow(false);
            }
            return true;
          }}
          onItemFocus={() => {
            setListsOffsetAfterIndex({ index: 0, offset: 300 });
            toggleOnNowRow(true);
          }}
        />
      )}
      <FocusContext.Provider value={focusKey}>
        <ListTitleInnerWrapper isFocused={hasFocusedChild}>
          <ListTitle>{listTitle}</ListTitle>
        </ListTitleInnerWrapper>
        <ListItemsWrapper
          id="me-apps-list"
          style={{ transform: `translate3d(-${translateX}px, 0, 0)` }}
        >
          {enhancedFavouriteApps.map((item, itemIndex: number) => {
            const focusKey =
              item.customFocusKey ||
              `focusable-${listId}-${item?.id || itemIndex}`;

            return (
              <ListItem
                key={focusKey}
                focusKey={focusKey}
                data={item}
                openAppDirectly={data.type === COLLECTION_TYPES.me_apps_list}
                canMoveLeft={editMode && focusedIndex > nonSystemAppsStartIndex}
                canMoveRight={
                  editMode && focusedIndex < enhancedFavouriteApps.length - 1
                }
                isEdited={itemIndex === focusedIndex && editMode}
                removeFocused={itemIndex === focusedIndex && removeFocused}
                editMode={editMode}
                showArtworkTitle={showArtworkTitle}
                itemIndex={itemIndex}
                onArrowPress={(direction: Direction) => {
                  if (
                    direction === Direction.DOWN &&
                    item?.action === 'watch-tv' &&
                    onNowVisible
                  ) {
                    setFocus('on_now_list');
                    return false;
                  }
                  return true;
                }}
                onFocus={() => {
                  if (item?.action === 'watch-tv' && onNowEnabled) {
                    setListsOffsetAfterIndex({ index: 0, offset: 300 });
                    toggleOnNowRow(true);
                  }

                  onItemFocus(listIndex, itemIndex, focusKey);
                }}
                onBlur={() => {
                  if (item?.action === 'watch-tv' && onNowVisible) {
                    setListsOffsetAfterIndex({ index: 0, offset: 0 });
                    toggleOnNowRow(false);
                  }
                }}
              />
            );
          })}
        </ListItemsWrapper>
      </FocusContext.Provider>
    </ListWrapper>
  );
};

export default MeAppsList;
