import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';
import { RootState } from 'store/configure-store';
import CONTENT_TYPES from 'constants/content-types';

interface FetchDetailsPayload {
  type: CONTENT_TYPES;
  contentId: number;
}

export const fetchDetails = createAsyncThunk<
  any,
  FetchDetailsPayload,
  { state: RootState }
>('details/fetchDetails', async ({ contentId, type }, { rejectWithValue }) => {
  try {
    return await api({
      endpoint: `/contents/${contentId}`,
      queryParams: {}
    });
  } catch (err) {
    let error: Error = err as Error;
    return rejectWithValue(error.message);
  }
});
