import { createAction, createSlice } from '@reduxjs/toolkit';
import { updateMenu } from 'store/features/menu/actions';

interface MenuItem {
  id: string;
  type: string;
  position: number;
  name: string;
  url: string;
  content_type?: string;
}

interface MenuState {
  data: { collection: MenuItem[] };
  menuIsVisible: boolean;
  hintVisible: boolean;
  menuOpacity: number;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage?: string;
}

const initialState: MenuState = {
  data: {
    collection: []
  },
  menuIsVisible: true,
  hintVisible: true,
  menuOpacity: 1,
  isLoading: false,
  isSuccess: false,
  errorMessage: ''
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    showMenu: (state) => {
      state.menuIsVisible = true;
    },
    hideMenu: (state, { payload }) => {
      state.menuIsVisible = false;
      state.hintVisible = payload.hintVisible;
    },
    setMenuOpacity: (state, { payload }) => {
      state.menuOpacity = payload.opacity;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateMenu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMenu.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload;
      })
      .addCase(updateMenu.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload as string;
      });
  }
});
export const showMenu = createAction('menu/showMenu');
export const hideMenu = createAction<{ hintVisible: boolean }>('menu/hideMenu');
export const setMenuOpacity = createAction<{ opacity: number }>(
  'menu/setMenuOpacity'
);

export default menuSlice.reducer;
