import { ArtworkWrapperProps } from 'components/List/Item/types';
import Focusable from 'components/Navigation/Focusable';
import ARTWORK_SIZES from 'constants/artwork-sizes';
import ARTWORK_TYPES from 'constants/artwork-types';
import styled from 'styled-components';
import ChevronIcon from 'styles/themes/base/icons/ChevronIcon';
import scaleSize from 'utils/scale-size';

export const ListItemWrapper = styled(Focusable)<ArtworkWrapperProps>`
  width: ${({ type }) => (ARTWORK_SIZES[type] || {}).width}px;
  height: ${({ type }) => (ARTWORK_SIZES[type] || {}).height}px;
  margin-right: ${({ type }) => (ARTWORK_SIZES[type] || {}).marginRight}px;
  margin-bottom: ${({ type }) => (ARTWORK_SIZES[type] || {}).marginBottom}px;
  display: inline-block;
  position: relative;
  border-radius: ${scaleSize(6)}px;

  &.focused:not(.edit-mode) {
    box-shadow:
      0 0 0 ${scaleSize(2)}px black,
      0 0 0 ${scaleSize(6)}px ${({ theme }) => theme.colors.primary};
    transform: scale(1.2);
    z-index: 1;
  }

  &.is-edited {
    box-shadow:
      0 0 0 ${scaleSize(2)}px black,
      0 0 0 ${scaleSize(6)}px ${({ theme }) => theme.colors.primary},
      0 0 40px ${scaleSize(5)}px black;
    transform: scale(${({ editMode }) => (editMode ? 1.4 : 1)});
    z-index: 1;
  }
`;

export const ListItemTitle = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  //TODO: use text styles from theme file or Text components from /elements
  font-size: ${scaleSize(28)}px;
  color: ${({ theme }) => theme.colors.white};
  text-shadow: black 1px 1px;
`;

export const AppTitleContainer = styled.div<{
  type: keyof typeof ARTWORK_TYPES;
}>`
  position: absolute;
  top: 110%;
  width: ${({ type }) => (ARTWORK_SIZES[type] || {}).width}px;
  overflow: hidden;
  z-index: 2;
`;

export const AppTitle = styled.div`
  font-size: ${scaleSize(24)}px;
  color: ${({ theme }) => theme.colors.white};
  text-shadow: black 1px 1px;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  width: 100%;
`;

export const Metadata = styled.div <{
  type: keyof typeof ARTWORK_TYPES;
}>`
  position: absolute;
  left: 5%;
  top: 115%;
  width: calc(${({ type }) => (ARTWORK_SIZES[type] || {}).width * 2 }px - 30%);
  text-align: left;
  font-size: ${scaleSize(26)}px;
  color: ${({ theme }) => theme.colors.white};
  text-shadow: black 1px 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  transform-origin: top left;

  span {
    display: block;
    color: ${({ theme }) => theme.colors.white06};
    font-size: ${scaleSize(18)}px;
  }
`;

export const EditChevron = styled(ChevronIcon)`
  position: absolute;
  top: 50%;
  z-index: 2;
`;

export const EditChevronLeft = styled(EditChevron)`
  left: -${scaleSize(30)}px;
  transform: translateY(-50%) rotate(-90deg);
`;

export const EditChevronRight = styled(EditChevron)`
  right: -${scaleSize(30)}px;
  transform: translateY(-50%) rotate(90deg);
`;

export const RemoveButton = styled.div<{
  isFocused?: boolean;
}>`
  position: absolute;
  bottom: -${scaleSize(84)}px;
  left: 50%;
  z-index: 2;
  width: ${scaleSize(64)}px;
  height: ${scaleSize(64)}px;
  transform: translateX(-50%) scale(0.8);
  background: ${({ isFocused, theme }) =>
    isFocused ? theme.colors.primary : '#161616'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px ${({ theme }) => theme.colors.white03};
`;
