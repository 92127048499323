import inputSources from './mocks/input-sources.json';

if (typeof window.getConnectedSourceList === 'undefined') {
  window.getConnectedSourceList = function () {
    return [];
    // Uncomment to mock the connected source list
    // return JSON.stringify(inputSources);
  };
}

export function getConnectedSourceList(): InputSource[] {
  const sources = window.getConnectedSourceList();

  if (!!sources && typeof sources === 'string') {
    return JSON.parse(sources)?.getConnectedSourceList || [];
  }

  return [];
}
