import React from 'react';
import theme from 'styles/theme';

type IconProps = {
  isFocused: boolean;
};

const EpgIcon: React.FC<IconProps> = ({ isFocused }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M1.33337 16H20M30.6667 16H24"
          stroke={isFocused ? theme.colors.white : theme.colors.gray05}
          strokeWidth="3"
        />
        <path
          d="M1.33337 6.66663H8.00004M30.6667 6.66663H12"
          stroke={isFocused ? theme.colors.white : theme.colors.gray05}
          strokeWidth="3"
        />
        <path
          d="M1.33337 25.3334H8.00004M30.6667 25.3334H12"
          stroke={isFocused ? theme.colors.white : theme.colors.gray05}
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default EpgIcon;
