import Marquee from 'components/Marquee';
import groupTitleTranslations from 'locales/groupTitleTranslations.json';
import calculateListTitleTranslate from 'utils/calculate-list-title-translate';
import Current from 'utils/current';
import {
  BrandedImage,
  BrandedImageWrapper,
  ListTitle,
  ListTitleInnerWrapper
} from '../List.styles';
import {
  ListGroupTitle,
  ListGroupTitleGroups,
  ListGroupTitlePlaceholder
} from './ListHeader.style';

const LIST_GROUP_TITLE_MAX_LENGTH = 28;

interface ListHeaderProps {
  list: Array<any>;
  title?: string;
  count?: string;
  isFocused: boolean;
  focusedIndex: number;
  brandedImage?: string;
}

function getGroupRanges(list: any) {
  if (list.length === 0) {
    return [];
  }

  const result = [];
  let currentGroup = list[0].group;
  let start = 0;

  for (let i = 1; i < list.length; i++) {
    if (list[i].group !== currentGroup) {
      result.push({ title: currentGroup, start, end: i - 1 });
      currentGroup = list[i].group;
      start = i;
    }
  }

  result.push({ title: currentGroup, start, end: list.length - 1 });

  return result;
}

interface GroupTitleTranslations {
  [title: string]: {
    [locale: string]: string;
  };
}

const getTranslatedTitle = (locale: string, title: string): string => {
  const titleTranslations = (
    groupTitleTranslations.groupTitles as GroupTitleTranslations
  )[title];
  if (!!titleTranslations && !!titleTranslations[locale]) {
    return titleTranslations[locale];
  }
  return title;
};

export default function ListHeader({
  title,
  count,
  list,
  focusedIndex,
  isFocused,
  brandedImage
}: ListHeaderProps) {
  const groupTitles = getGroupRanges(list);
  let titleGroups = null;

  const currentLocale = Current.locale();
  const hasMultipleGroupTitles = groupTitles.length > 1;

  if (hasMultipleGroupTitles) {
    titleGroups = (
      <ListGroupTitleGroups isFocused={isFocused}>
        <ListGroupTitlePlaceholder>Placeholder</ListGroupTitlePlaceholder>
        {groupTitles.map((group, index) => {
          const title = getTranslatedTitle(currentLocale, group.title);
          const groupEndReached = group.end < focusedIndex;

          if (groupEndReached) {
            return null;
          }

          const isCurrentGroup =
            group.end >= focusedIndex && group.start <= focusedIndex;
          return (
            <ListGroupTitle
              key={`group-title-${group.title}-${index}`}
              isCurrentGroup={isCurrentGroup}
              translateX={calculateListTitleTranslate(
                list,
                group.start,
                group.end,
                focusedIndex
              )}
            >
              <Marquee
                enabled={
                  title.length > LIST_GROUP_TITLE_MAX_LENGTH && isCurrentGroup
                }
                text={title}
              />
            </ListGroupTitle>
          );
        })}
      </ListGroupTitleGroups>
    );
  }

  return (
    <ListTitleInnerWrapper
      isFocused={isFocused}
      withGroups={groupTitles.length > 1}
    >
      <ListTitle>
        {title}
        {count}
      </ListTitle>
      {titleGroups}
      {brandedImage && (
        <BrandedImageWrapper>
          <BrandedImage src={brandedImage} alt="branded" />
        </BrandedImageWrapper>
      )}
    </ListTitleInnerWrapper>
  );
}
