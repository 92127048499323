import ARTWORK_TYPES from 'constants/artwork-types';
import scaleSize from 'utils/scale-size';

const RIGHT_MARGIN = scaleSize(24);

// TODO Reuse margins from base.ts
const ARTWORK_SIZES = {
  [ARTWORK_TYPES.SERVICE]: {
    width: scaleSize(124),
    height: scaleSize(124),
    marginRight: RIGHT_MARGIN,
    marginBottom: scaleSize(10)
  },
  [ARTWORK_TYPES.LANDSCAPE]: {
    width: scaleSize(272),
    height: scaleSize(153),
    marginRight: RIGHT_MARGIN,
    marginBottom: scaleSize(10)
  },
  [ARTWORK_TYPES.GENRE]: {
    width: scaleSize(272),
    height: scaleSize(153),
    marginRight: RIGHT_MARGIN,
    marginBottom: scaleSize(10)
  },
  [ARTWORK_TYPES.LANDSCAPE_4_3]: {
    width: scaleSize(204),
    height: scaleSize(153),
    marginRight: RIGHT_MARGIN,
    marginBottom: scaleSize(10)
  },
  [ARTWORK_TYPES.LANDSCAPE_XL]: {
    width: scaleSize(272),
    height: scaleSize(153),
    marginRight: RIGHT_MARGIN,
    marginBottom: scaleSize(10)
  },
  [ARTWORK_TYPES.LANDSCAPE_TPV]: {
    width: scaleSize(272),
    height: scaleSize(153),
    marginRight: RIGHT_MARGIN,
    marginBottom: scaleSize(10)
  },
  [ARTWORK_TYPES.SQUARE_TPV]: {
    width: scaleSize(153),
    height: scaleSize(153),
    marginRight: RIGHT_MARGIN,
    marginBottom: scaleSize(10)
  },
  [ARTWORK_TYPES.PORTRAIT]: {
    width: scaleSize(110),
    height: scaleSize(153),
    marginRight: RIGHT_MARGIN,
    marginBottom: scaleSize(10)
  },
  [ARTWORK_TYPES.NA]: {
    width: scaleSize(140),
    height: scaleSize(140),
    marginRight: RIGHT_MARGIN,
    marginBottom: scaleSize(10)
  }
} as const;

export default ARTWORK_SIZES;
