export interface FullDeviceInfo {
  Channel: {
    appStore: string;
    vendor: string;
    brand: string;
  };
  Product: {
    platform: string;
    year: string;
    deviceId: string;
    WhaleAdID: string;
    firmwareComponentID: string;
    firmwareVersion: string;
  };
  Capability: {
    os: string;
    browserEngine: string;
    hasStorage: boolean;
    support3d: boolean;
    supportUHD: boolean;
    supportHDR: boolean;
    supportWebSocket: boolean;
    supportPlayready: boolean;
    supportWidevineModular: boolean;
    supportWidevineClassic: boolean;
    supportAdobeHDS: boolean;
    supportAppleHLS: boolean;
    supportMSSmoothStreaming: boolean;
    supportMPEG_DASH: boolean;
    drmMethod: string;
  };
}

function generateDeviceId(length: number): string {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export const mockDeviceInfo: FullDeviceInfo = {
  Channel: {
    appStore: 'Zeasn',
    vendor: 'TCL',
    brand: 'Philips'
  },
  Product: {
    platform: 'TCL 5655',
    year: '2023',
    deviceId: generateDeviceId(16), //0008c60000123456 //0008c60000123459ko
    WhaleAdID: 'adc1e1cb-2c81-4639-bb21-166891d50dd1',
    firmwareComponentID: 'TPM196E00001',
    firmwareVersion: 'TPM171E_R.107.000.043.000'
  },
  Capability: {
    os: 'Android',
    browserEngine: 'Blink',
    hasStorage: true,
    support3d: true,
    supportUHD: true,
    supportHDR: true,
    supportWebSocket: true,
    supportPlayready: true,
    supportWidevineModular: true,
    supportWidevineClassic: true,
    supportAdobeHDS: true,
    supportAppleHLS: true,
    supportMSSmoothStreaming: true,
    supportMPEG_DASH: true,
    drmMethod: 'OIPF'
  }
};

export function mockInitializeDeviceInfo(
  callback: (info: FullDeviceInfo) => void
): void {
  setTimeout(() => {
    console.warn('Mock onDeviceInfoReady called', mockDeviceInfo);
    callback(mockDeviceInfo);
  }, 1000);
}

export function getMockDeviceUID(): string {
  return mockDeviceInfo.Product.deviceId;
}
