import { createSlice } from '@reduxjs/toolkit';
import { ACTION_TYPES } from 'store/features/loader/actions';

const initialState = {
  showLoader: true,
  progress: 0
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => action.type === ACTION_TYPES.SHOW_LOADER,
        (state) => {
          state.showLoader = true;
          state.progress = 0;
        }
      )
      .addMatcher(
        (action) => action.type === ACTION_TYPES.HIDE_LOADER,
        (state) => {
          state.showLoader = false;
        }
      )
      .addMatcher(
        (action) => action.type === ACTION_TYPES.SET_LOADER_PROGRESS,
        (state, action) => {
          state.progress = action.payload.progress;
        }
      );
  }
});

export default loaderSlice.reducer;
