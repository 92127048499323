import React from 'react';
import styled from 'styled-components';
import { useAppSelector } from 'hooks/hooks';
import scaleSize from 'utils/scale-size';
import reactStringReplace from 'react-string-replace';
import usePrevious from '../../hooks/usePrevious';
import { useTranslation } from 'react-i18next';

const Hint = styled.div<{ isVisible: boolean }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%)
    translateY(${({ isVisible }) => (isVisible ? 0 : 200)}%);
  bottom: ${scaleSize(24)}px;
  font-size: ${scaleSize(32)}px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) =>
    theme.transition({
      value: 'transform',
      timing: theme.timings.l,
      easing: theme.easings.ease
    })};
`;

const RoundedHint = styled.div`
  display: inline-block;
  width: ${scaleSize(60)}px;
  height: ${scaleSize(60)}px;
  margin: 0 ${scaleSize(10)}px;
  text-align: center;
  border-radius: 50%;
  aspect-ratio: 1;
  padding: ${scaleSize(4)}px;
  line-height: ${scaleSize(60)}px;
  border: ${scaleSize(2)}px solid rgba(255, 255, 255, 0.4);
  background: rgba(128, 128, 128, 0.4);
  vertical-align: middle;
`;

const BottomBarWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: ${scaleSize(220)}px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: ${({ isVisible }) => +isVisible};
    ${({ theme }) =>
      theme.transition({
        value: 'opacity',
        timing: theme.timings.l,
        easing: theme.easings.ease
      })};
    transition-delay: ${({ isVisible }) => (isVisible ? 0 : 100)}ms;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  }
`;

const BottomBar: React.FC = () => {
  const isVisible = useAppSelector((state) => state.bottomBar.isVisible);
  const hint = useAppSelector((state) => state.bottomBar.hint);
  const prevHint = usePrevious(hint);
  const { t } = useTranslation();

  const translatedHintValue = t(hint);
  const translatedPreviousHintValue = t(prevHint);

  return (
    <BottomBarWrapper isVisible={isVisible && translatedHintValue}>
      <Hint isVisible={!!translatedHintValue}>
        {reactStringReplace(
          translatedHintValue || translatedPreviousHintValue,
          /(\{[A-z]+\})/,
          (match, i) => (
            <RoundedHint key={`hint-${i}`}>
              {match.replace(/\{|\}/g, '')}
            </RoundedHint>
          )
        )}
      </Hint>
    </BottomBarWrapper>
  );
};

export default BottomBar;
