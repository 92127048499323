import { marginSizes, sizes } from 'styles/base';
import base from '../base/base';
import { css } from 'styled-components';

const colors = {
  ...base.colors
};

const typography = {
  ...base.typography
};

const fonts = [
  {
    fontFamily: 'Silka',
    src: 'Silka-Regular.woff',
    type: 'woff',
    fontWeight: 400,
    fontStyle: 'normal'
  }
];

const globalStyles = css`
  ${fonts.map(
    (font) => css`
      @font-face {
        font-family: ${font.fontFamily};
        src: url('./assets/titan/fonts/${font.src}') format('${font.type}');
        font-weight: ${font.fontWeight};
        font-style: ${font.fontStyle};
      }
    `
  )}
  body {
    font-family: ${fonts.map((font) => font.fontFamily).join(',')}, sans-serif;
  }
`;

const theme = {
  title: 'Titan',
  fonts,
  globalStyles,
  sizes,
  colors,
  marginSizes,
  typography
};

export default theme;
