import {
  pause as pauseFocusables,
  resume as resumeFocusables,
  setFocus
} from '@noriginmedia/norigin-spatial-navigation';
import {
  EDIT_LIST_ORDER_TIMEOUT,
  EDIT_MODE_EXIT_TIMEOUT
} from 'constants/timeout-constants';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  removeFromFavorites,
  updateAppsOrder,
  updateListOrder
} from 'store/features/apps/actions';
import { useAppDispatch } from './hooks';
import useBackButton from './useBackButton';
import usePrevious from './usePrevious';

let enterKeyReleased = true;

interface EditModeProps {
  canEdit: boolean;
  isEditable: boolean;
  focusedIndex: number;
  reorderMinIndex: number;
  list: any;
  listId: string;
  setFocusedIndex: Dispatch<SetStateAction<number>>;
  onExitCallback: () => void;
}

let editModeTimout: ReturnType<typeof setTimeout> | null = null;

const useEditMode = ({
  canEdit,
  isEditable,
  focusedIndex,
  reorderMinIndex,
  list,
  listId,
  setFocusedIndex,
  onExitCallback
}: EditModeProps): {
  editMode: boolean;
  wasEditMode: boolean;
  removeFocused: boolean;
  toggleEditMode: (state: boolean) => void;
} => {
  const { state: locationState } = useLocation();
  const [editMode, setEditMode] = useState<boolean>(
    !!(locationState?.editMode && isEditable)
  );
  const [removeFocused, setRemoveFocused] = useState(false);
  const wasEditMode = usePrevious(editMode);
  const dispatch = useAppDispatch();
  const editModeIdleTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();

  useBackButton(() => {
    setEditMode(false);
    setRemoveFocused(false);
  }, true);

  useEffect(() => {
    if (locationState?.editMode && isEditable) {
      setFocus(`focusable-${listId}-${locationState.appId}`);
      setEditMode(true);
    }
  }, [locationState]);

  useEffect(() => {
    if (editMode) {
      pauseFocusables();
    } else if (wasEditMode) {
      if (focusedIndex > 0) {
        dispatch(updateAppsOrder({ collection: list })).then(() => {
          onExitCallback &&
            typeof onExitCallback === 'function' &&
            onExitCallback();
        });
      }
      resumeFocusables();
      navigate(`/`, {
        replace: true,
        state: {}
      });
    }
  }, [editMode, focusedIndex, wasEditMode]);

  // TODO: Use direction instead of keys
  function moveAppOrSetRemovable(key: string) {
    // Account for the fact that system apps are synced outside of redux state.
    const normalisedFocusedIndex = focusedIndex - reorderMinIndex;
    if (
      key === 'ArrowRight' &&
      focusedIndex < list.length - 1 &&
      !removeFocused
    ) {
      setFocusedIndex((prevIndex) => prevIndex + 1);

      // Account for the updated focus index
      const normalisedNextIndex = normalisedFocusedIndex + 1;
      dispatch(
        updateListOrder({
          listId,
          index1: normalisedFocusedIndex,
          index2: normalisedNextIndex
        })
      );
    } else if (
      key === 'ArrowLeft' &&
      focusedIndex > reorderMinIndex &&
      !removeFocused
    ) {
      setFocusedIndex((prevIndex) => prevIndex - 1);

      // Account for the updated focus index
      const normalisedNextIndex = normalisedFocusedIndex - 1;
      dispatch(
        updateListOrder({
          listId,
          index1: normalisedFocusedIndex,
          index2: normalisedNextIndex
        })
      );
    } else if (key === 'ArrowDown') {
      setRemoveFocused(true);
    } else if (key === 'ArrowUp') {
      setRemoveFocused(false);
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (editModeIdleTimeoutRef.current) {
      clearTimeout(editModeIdleTimeoutRef.current);
      editModeIdleTimeoutRef.current = null;
    }

    if (editMode) {
      moveAppOrSetRemovable(event.key);
    }

    if (event.key === 'Enter') {
      if (!editModeTimout && !editMode) {
        enterKeyReleased = false;
        editModeTimout = setTimeout(() => {
          editModeTimout = null;
          setEditMode(true);
        }, EDIT_LIST_ORDER_TIMEOUT);
      }
    }
  }

  function handleKeyUp(event: KeyboardEvent) {
    // Exit edit mode if idle for 10 seconds
    editModeIdleTimeoutRef.current = setTimeout(() => {
      setEditMode(false);
      editModeIdleTimeoutRef.current = null;
    }, EDIT_MODE_EXIT_TIMEOUT);

    if (event.key === 'Enter' && editMode && enterKeyReleased) {
      if (removeFocused) {
        const id = list?.[focusedIndex]?.id;
        const prevAppId = list[focusedIndex - 1].id;
        dispatch(removeFromFavorites({ id }));
        setRemoveFocused(false);
        setFocus(`focusable-${listId}-${prevAppId}`);
      }
      requestAnimationFrame(() => {
        setEditMode(false);
      });
    }

    if (event.key === 'Enter' && editModeTimout) {
      clearTimeout(editModeTimout);
      editModeTimout = null;
    }

    enterKeyReleased = true;
  }

  useEffect(() => {
    if (!canEdit) {
      return;
    }

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [canEdit, focusedIndex, editMode, removeFocused]);

  return {
    editMode,
    wasEditMode: wasEditMode || false,
    removeFocused,
    toggleEditMode: setEditMode
  };
};

export default useEditMode;
