import React from 'react';
import scaleSize from 'utils/scale-size';

type IconProps = {
  isFocused?: boolean;
  className?: string;
  color?: string;
};

const ChevronIcon: React.FC<IconProps> = ({ isFocused, className, color }) => {
  return (
    <svg
      width={scaleSize(32)}
      height={scaleSize(32)}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g>
        <path
          d="M6.66669 18.6667L16 9.33337L25.3334 18.6667"
          stroke={color || 'rgba(255, 255, 255, 0.4)'}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ChevronIcon;
