import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store/configure-store';
import api from 'api/api';
import {
  fetchDeviceFVPMetadata,
  getTPVApps
} from 'devices/TPV/fetchDeviceList';
import Current from 'utils/current';
import getFVPSortedApps from 'utils/get-fvp-sorted-apps-collection';
import Local from '../../../utils/storage/local';

export interface UpdateListOrderPayload {
  listId: string;
  index1: number;
  index2: number;
}
interface AppsUpdateResponse {
  id: number;
  name: string;
  icon: string;
  type: string;
  position: number;
}

export const updateAppsOrder = createAsyncThunk<
  AppsUpdateResponse,
  { collection: any; update?: boolean },
  { state: RootState }
>(
  'apps/updateAppsOrder',
  async ({ collection, update }, { rejectWithValue }) => {
    try {
      const updatedPositions = updatePositions(
        collection,
        update || false
      ).filter((item: any) => item.app_id !== null);
      return await api({
        endpoint: `/me/apps/update_positions`,
        withAuthHeader: true,
        method: 'PUT',
        queryParams: {},
        data: { apps: updatedPositions }
      });
    } catch (error: any) {
      console.log('==> e', error);
      return rejectWithValue(error?.response?.data?.errors[0] || error.message);
    }
  }
);

export const addToFavorites = createAsyncThunk<
  AppsUpdateResponse,
  { id: number },
  { state: RootState }
>('apps/addToFavorites', async ({ id }, { rejectWithValue, dispatch }) => {
  try {
    return await api({
      endpoint: `/me/apps`,
      withAuthHeader: true,
      method: 'POST',
      queryParams: {},
      data: { app_id: id }
    });
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.errors[0] || error.message);
  }
});

export const removeFromFavorites = createAsyncThunk<
  AppsUpdateResponse,
  { id: number },
  { state: RootState }
>('apps/removeFromFavorites', async ({ id }, { rejectWithValue, dispatch }) => {
  try {
    dispatch(removeAppById({ id }));
    return await api({
      endpoint: `/me/apps`,
      withAuthHeader: true,
      method: 'DELETE',
      queryParams: {},
      data: { app_id: id }
    });
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.errors[0] || error.message);
  }
});

export const getApp = createAsyncThunk(
  'apps/getApp',
  async (appId, { rejectWithValue }) => {
    try {
      return await api({
        endpoint: `/apps/${appId}`
      });
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.errors[0] || error.message);
    }
  }
);

export const fetchApps = createAsyncThunk<any, void, { state: RootState }>(
  'apps/fetchApps',
  async (any: void, { rejectWithValue }) => {
    try {
      return await api({
        endpoint: `/apps`,
        queryParams: {
          per_page: 999
        }
      });
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.errors[0] || error.message);
    }
  }
);

const updatePositions = (collection: any, update: boolean) => {
  const randomNumber = Math.round(Math.random() * 4000);

  const apiPositions = collection
    .map((item: any, index: number) => ({
      app_id: item.id,
      position: index + randomNumber + 1
    }))
    .filter((item: any) => `${item.app_id}`.indexOf('_fvp_') !== 0);

  const localPositions = collection
    .map((item: any, index: number) => ({
      ...item,
      app_id: item.id,
      position: index + randomNumber + 1
    }))
    .filter((item: any) => `${item.app_id}`.indexOf('_fvp_') === 0);

  console.log('==> apiPositions', apiPositions);
  console.log('==> localPositions', localPositions);

  Local.setFvpPositions(localPositions);

  return apiPositions;
};

export const fetchMeApps = createAsyncThunk<any, void, { state: RootState }>(
  'apps/fetchMeApps',
  async (any: void, { rejectWithValue, dispatch }) => {
    try {
      const res = await api({
        endpoint: `/me/apps`,
        withAuthHeader: true,
        queryParams: {
          per_page: 999
        }
      });

      let collection = res.collection;

      if (Current.market() === 'gb') {
        const tpvApps = getTPVApps();
        if (!Local.getPositionsReported()) {
          collection = getFVPSortedApps(collection, tpvApps, false);
          dispatch(updateAppsOrder({ collection, update: true }));
          Local.setPositionsReported();
        } else {
          const localApps = Local.getFvpPositions();
          collection = await fetchDeviceFVPMetadata(collection);
          collection = [...collection, ...localApps].sort(
            (a: any, b: any) => a.position - b.position
          );
        }
      }

      return {
        collection,
        pagination: res.pagination
      };
    } catch (error: any) {
      console.log('==> e', error);
      return rejectWithValue(error?.response?.data?.errors[0] || error.message);
    }
  }
);

export const updateListOrder = createAction<UpdateListOrderPayload>(
  'apps/updateListOrder'
);

export const removeAppById = createAction<{ id: string | number }>(
  'apps/removeAppById'
);
