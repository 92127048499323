import styled from 'styled-components';

interface GridInnerWrapperProps {
  translateY: number;
}

export const GridWrapper = styled.div`
  ${({ theme }) => theme.fullScreen};
  ${({ theme }) => theme.margin('xxl', 'left')};
  margin-top: calc(
    ${({ theme }) => theme.marginSizes.xxl + theme.marginSizes.xs}px
  );
  font-size: 0;
`;

export const GridInnerWrapper = styled.div<GridInnerWrapperProps>`
  transform: translateY(-${({ translateY }) => translateY}px);
  ${({ theme }) =>
    theme.transition({
      value: 'transform',
      timing: theme.timings.s,
      easing: theme.easings.ease
    })};
`;
