import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const updateMenu = createAsyncThunk(
  'app/updateMenu',
  async (page, { rejectWithValue }) => {
    try {
      return await api({
        endpoint: '/menu_items'
      });
    } catch (err: any) {
      const error: Error = err as Error;
      return rejectWithValue(error.message);
    }
  }
);
