import React from 'react';
import scaleSize from 'utils/scale-size';

type IconProps = {
  color?: string;
};

const SpecialStarIcon: React.FC<IconProps> = ({ color = '#FFD600' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      fill="none"
      width={scaleSize(18)}
      height={scaleSize(18)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49906 15.7305L4.60375 17.8706C4.07758 18.1596 3.4268 17.9482 3.15018 17.3984C3.04003 17.1794 3.00202 16.9287 3.04203 16.6848L3.78597 12.1521C3.84586 11.7872 3.7301 11.4149 3.47642 11.1565L0.325043 7.94643C-0.100632 7.51282 -0.109342 6.8007 0.305588 6.35587C0.470816 6.17873 0.687313 6.06346 0.921565 6.02789L5.27666 5.36657C5.62724 5.31333 5.9303 5.08324 6.08708 4.75126L8.03474 0.627246C8.29782 0.0701908 8.94322 -0.158522 9.47628 0.116401C9.68855 0.225877 9.86036 0.405424 9.96512 0.627246L11.9128 4.75126C12.0696 5.08324 12.3726 5.31333 12.7232 5.36657L17.0783 6.02789C17.6666 6.11721 18.0742 6.68798 17.9887 7.30272C17.9546 7.54752 17.8443 7.77376 17.6748 7.94643L14.5234 11.1565C14.2698 11.4149 14.154 11.7872 14.2139 12.1521L14.9578 16.6848C15.0583 17.2971 14.6648 17.8786 14.0789 17.9836C13.8456 18.0254 13.6056 17.9857 13.3961 17.8706L9.5008 15.7305C9.18723 15.5582 8.81263 15.5582 8.49906 15.7305Z"
        fill={color}
      />
    </svg>
  );
};

export default SpecialStarIcon;
