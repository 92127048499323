import React from 'react';
import {
  Image as ImageWrapper,
  ImageDiv as ImageDivWrapper
} from 'elements/Image';

interface ImageProps {
  src: string;
  className?: string;
}

const Image: React.FC<ImageProps> = ({ src, className }) => {
  if (src && src.indexOf('data:image') === 0) {
    return <ImageWrapper src={src} className={className} />;
  }

  return <ImageDivWrapper src={src} className={className} />;
};

export default Image;
