import React, { useEffect } from 'react';
import styled from 'styled-components';
import Router from 'components/Router/Router';
import Loader from 'components/Loader/Loader';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { RootState } from 'store/configure-store';
import { tvSignIn, tvSignUp } from 'store/features/auth/actions';
import Toast from 'components/Toast/Toast';
import DevTools from 'components/DevTools/DevTools';
import Local from 'utils/storage/local';
import ConnectedInputSourcesProvider from 'providers/ConnectedInputSources';
import { useTranslation } from 'react-i18next';

const AppWrapper = styled.div<{ ambiLightColor: string }>`
  width: ${({ theme }) => theme.sizes.fullWidth};
  height: ${({ theme }) => theme.sizes.fullHeight};
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: ${({ theme }) => theme.colors.black};
  position: relative;
  --ambilight-color: ${({ ambiLightColor }) => ambiLightColor};
`;

interface AppProps {
  deviceInfo: {
    deviceManufacturer: string;
    deviceUID: string;
  };
}

const App: React.FC<AppProps> = ({ deviceInfo }) => {
  const { deviceUID } = deviceInfo;
  const dispatch = useAppDispatch();
  const showLoader = useAppSelector((state) => state.loader.showLoader);
  const ambiLightColor = useAppSelector((state) => state.app.color);
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const isLoading = useAppSelector((state: RootState) => state.auth.isLoading);
  const {i18n} = useTranslation();

  function storeLanguagePreference() {
    const urlParams = new URLSearchParams(window.location.search);
    let language = urlParams.get('language');

    if (language) {
      i18n.changeLanguage(language);
      Local.setLanguage(language);
    }
  }

  useEffect(() => {
    storeLanguagePreference();

    const handlerLanguageChange = (e: any) => {
      console.log(`==> ApplicationSetLanguage`, e.Language);
      Local.setLanguage(e.Language);

      const urlObj = new URL(window.location.href);

      urlObj.searchParams.set('language', e.Language);
      window.history.replaceState({}, '', urlObj.href);
      i18n.changeLanguage(e.Language);
      window.location.reload();
    };

    document.addEventListener('ApplicationSetLanguage', handlerLanguageChange);

    return () => {
      document.removeEventListener(
        'ApplicationSetLanguage',
        handlerLanguageChange
      );
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      dispatch(tvSignUp(deviceUID))
        .unwrap()
        .catch((error) => {
          //TODO: add normal error handling
          if (error?.code === 'taken' || error?.code === 'missing_param') {
            dispatch(tvSignIn(deviceUID));
          }
        });
    }
  }, []);

  return (
    <>
      <AppWrapper ambiLightColor={ambiLightColor}>
        <ConnectedInputSourcesProvider>
          <Loader showLoader={showLoader || !isAuthenticated} />
          <Toast />
          <DevTools />
          {isAuthenticated && <Router />}
        </ConnectedInputSourcesProvider>
      </AppWrapper>
    </>
  );
};

export default App;
