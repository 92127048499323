// @ts-nocheck
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './langs/en.json';
import es from './langs/es.json';
import fr from './langs/fr.json';
import de from './langs/de.json';
import it from './langs/it.json';
import pl from './langs/pl.json';
import sq from './langs/sq.json';
import hy from './langs/hy.json';
import az from './langs/az.json';
import be from './langs/be.json';
import bg from './langs/bg.json';
import bs from './langs/bs.json';
import cs from './langs/cs.json';
import da from './langs/da.json';
import et from './langs/et.json';
import fi from './langs/fi.json';
import ka from './langs/ka.json';
import el from './langs/el.json';
import hu from './langs/hu.json';
import hr from './langs/hr.json';
import is from './langs/is.json';
import kk from './langs/kk.json';
import lt from './langs/lt.json';
import lv from './langs/lv.json';
import mk from './langs/mk.json';
import nl from './langs/nl.json';
import no from './langs/no.json';
import pt from './langs/pt.json';
import ro from './langs/ro.json';
import sr from './langs/sr.json';
import ru from './langs/ru.json';
import sv from './langs/sv.json';
import sl from './langs/sl.json';
import sk from './langs/sk.json';
import tr from './langs/tr.json';
import uk from './langs/uk.json';
import ar from './langs/ar.json';
import he from './langs/he.json';
import ca from './langs/ca.json';
import zhHans from './langs/zh-hans.json';
import th from './langs/th.json';
import ga from './langs/ga.json';
import fy from './langs/fy.json';
import esAr from './langs/es-ar.json';
import ptBr from './langs/pt-br.json';

import Current from '../utils/current';
export const resources = {
  en,
  es,
  fr,
  it,
  de,
  pl,
  sq,
  hy,
  az,
  be,
  bg,
  cs,
  et,
  fi,
  ka,
  el,
  hr,
  is,
  kk,
  lt,
  lv,
  mk,
  fy,
  no,
  pt,
  ro,
  sr,
  ru,
  sv,
  sl,
  sk,
  tr,
  uk,
  ar,
  he,
  ca,
  "zh-Hans": zhHans,
  th,
  da,
  ga,
  nl,
  bs,
  hu,
  "es-ar": esAr,
  "pt-br": ptBr
} as const;

i18n.use(initReactI18next).init({
  lng: Current.locale(),
  resources
});

export default i18n;
