import ARTWORK_SIZES from 'constants/artwork-sizes';
import styled from 'styled-components';
import Image from 'components/Image/Image';
import scaleSize from 'utils/scale-size';
import ARTWORK_TYPES from 'constants/artwork-types';

interface Props {
  src: string;
  type: keyof typeof ARTWORK_TYPES;
}

const Artwork = styled(Image)<Props>`
  width: ${({ type }) => (ARTWORK_SIZES[type] || {}).width}px;
  height: 100%;
  background-size: cover;
  border-radius: ${scaleSize(6)}px;
  overflow: hidden;
`;

export default function ArtworkComponent({ src, type }: Props) {
  return <Artwork src={src} type={type} />;
}
