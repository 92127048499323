import React from 'react';
import theme from 'styles/theme';
import scaleSize from 'utils/scale-size';

type IconProps = {
  isFocused: boolean;
};

const CustomIcon: React.FC<IconProps> = ({ isFocused }) => {
  const size = scaleSize(32);
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 32 32`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10_136)">
        <mask
          id="mask0_10_136"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="1"
          width="30"
          height="30"
        >
          <path d="M31 1H1V31H31V1Z" fill="white" />
        </mask>
        <g mask="url(#mask0_10_136)">
          <mask
            id="mask1_10_136"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="30"
            height="30"
          >
            <path d="M31 1H1V31H31V1Z" fill="white" />
          </mask>
          <g mask="url(#mask1_10_136)">
            <path
              d="M16.9094 1.975L11.9125 7.6V15.5031L16.9094 21.1281H26.0031L31 15.5031V7.5625L26.0031 1.9375L16.9094 1.975ZM28.4031 14.2281L24.925 18.0531H17.9875L14.5094 14.2281V8.80938L17.9875 4.98438H24.925L28.4031 8.80938V14.2281Z"
              fill={isFocused ? theme.colors.white : theme.colors.white06}
            />
            <path
              d="M13.2719 16.2452L1 27.1225L3.60435 30.0607L15.8762 19.1834L13.2719 16.2452Z"
              fill={isFocused ? theme.colors.white : theme.colors.white06}
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10_136">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomIcon;
