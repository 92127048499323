export const ACTION_TYPES = {
  SHOW_MODAL: 'showModal',
  REMOVE_MODAL: 'removeModal'
} as const;

type ActionTypes = typeof ACTION_TYPES;
type Actions = {
  [Property in keyof ActionTypes]: {
    type: ActionTypes[Property];
    payload: {
      modalType: string;
      modalData: any;
      returnFocusKey?: string;
    };
  };
};
type DispatchType = (args: Actions[keyof Actions]) => void;

export const showModal =
  (modalType: string, modalData: any, returnFocusKey?: string) =>
  (dispatch: DispatchType) => {
    dispatch({
      type: ACTION_TYPES.SHOW_MODAL,
      payload: {
        modalType,
        modalData,
        returnFocusKey
      }
    });
  };

export const removeModal = () => (dispatch: DispatchType) => {
  dispatch({
    type: ACTION_TYPES.REMOVE_MODAL,
    payload: {
      modalType: '',
      modalData: null
    }
  });
};
