import React, { FC, RefObject, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  FocusContext,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import GenreItem from './GenreItem';
import { RootState } from 'store/configure-store';
import { setGenreId } from 'store/features/channels/channelsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { Genre } from 'store/types';
import { FULL_HEIGHT } from 'styles/base';
import scaleSize from 'utils/scale-size';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  position: absolute;
  width: 20%;
  height: 100%;
  z-index: 11;
  background-color: ${({ theme }) => theme.colors.black};
  border-right: 1px solid ${({ theme }) => theme.colors.gray02};
  ${({ theme }) =>
    theme.transition({
      value: 'transform',
      timing: theme.timings.s,
      easing: theme.easings.ease
    })};
`;

export const GenresWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: ${scaleSize(64)}px;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: ${({ theme }) => theme.colors.black};
`;

const findGenreIndexById = (genres: Genre[], id?: number): number => {
  const index = genres.findIndex((genre) => genre.id === id);
  return index !== -1 ? index : 0;
};

const GenresMenu: FC<{
  isVisible?: boolean;
}> = ({ isVisible }) => {
  const dispatch = useAppDispatch();
  const genres = useAppSelector((state: RootState) => state.channels.genres);

  const genreId = useAppSelector(
    (state: RootState) => state?.channels?.currentGenreId
  );
  const focusParams = { saveLastFocusedChild: true };
  const { focusKey, ref } = useFocusable(focusParams);
  const [currentItem, setCurrentItem] = useState(
    findGenreIndexById(genres, genreId)
  );
  const [translateY, setTranslateY] = useState(0);

  useEffect(() => {
    const currentItem = findGenreIndexById(genres, genreId);
    setCurrentItem(currentItem);
  }, [genreId]);

  const onFocus = (genre: any, index: number) => {
    setCurrentItem(index);
    dispatch(setGenreId({ genreId: genre.id }));
  };
  const onArrowPress = (direction: any, index: number) => {
    if (direction === 'up') {
      return index > 0;
    } else if (direction === 'down') {
      return index < genres.length - 1;
    }
    return direction !== 'left';
  };

  useEffect(() => {
    const itemHeight = 52; // TODO use itemHeight
    const height = FULL_HEIGHT * 0.9; // TODO change 0.9
    const itemsUnFitted = genres.length - height / itemHeight;
    const maxTranslation = itemsUnFitted * itemHeight;
    const newTranslateY =
      currentItem * itemHeight > maxTranslation
        ? translateY
        : -currentItem * itemHeight;

    setTranslateY(newTranslateY);
  }, [currentItem]);

  return (
    <FocusContext.Provider value={focusKey}>
      <Wrapper>
        <GenresWrapper ref={ref as RefObject<HTMLDivElement>}>
          {genres.map((genre: Genre, index) => (
            <GenreItem
              id={genre.id}
              name={genre.name}
              key={`genre-menu-item${genre.id}`}
              focusKey={`genre-menu-item${genre.id}`}
              isCurrent={index === currentItem}
              onArrowPress={(direction: any) => onArrowPress(direction, index)}
              onFocus={() => {
                onFocus(genre, index);
              }}
            />
          ))}
        </GenresWrapper>
      </Wrapper>
    </FocusContext.Provider>
  );
};

export default GenresMenu;
