import React, { FC, memo } from 'react';
import {
  ListPlaceholder,
  ListPlaceholderItems,
  ListPlaceholderTitle,
  ListTitle
} from './List.styles';
import LIST_SIZES from 'constants/list-sizes';
import ARTWORK_TYPES from 'constants/artwork-types';
import ARTWORK_SIZES from 'constants/artwork-sizes';
import { ListItemType } from './Item/types';

interface ListPlaceholderProps {
  title?: string;
  width: number;
  height: number;
  marginRight: number;
  marginBottom: number;
  titleHeight: number;
}

const ListPlaceholderComponent: FC<ListPlaceholderProps> = memo(
  ({ width, height, marginBottom, marginRight, titleHeight, title }) => {
    return (
      <ListPlaceholder height={height + marginBottom + titleHeight}>
        <ListPlaceholderTitle>
          <ListTitle>{title}</ListTitle>
        </ListPlaceholderTitle>
        <ListPlaceholderItems
          height={height}
          width={width}
          marginRight={marginRight}
        />
      </ListPlaceholder>
    );
  }
);

export const getListPlaceholder = (
  listState: {
    data: ListItemType[];
    isLoading: boolean;
  }, 
  type: ARTWORK_TYPES,
  title?: string,
) => {
  const { width, marginRight } = ARTWORK_SIZES[type];
  const { height, marginBottom, titleHeight } = LIST_SIZES[type];
  const listHasContents = !!listState?.data.length;
  const listLoadingWithoutData =
    listState?.isLoading && !listState.data?.length;

  if (!listState?.data || listLoadingWithoutData) {
    return (
      <ListPlaceholderComponent
        title={title}
        width={width}
        height={height}
        marginRight={marginRight}
        marginBottom={marginBottom}
        titleHeight={titleHeight}
      />
    );
  } else if (!listHasContents) {
    return <></>;
  }

  return null;
};

export default ListPlaceholderComponent;
