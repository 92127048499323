import React from 'react';
import styled from 'styled-components';
import {
  format,
  addMinutes,
  startOfHour,
  setMinutes,
  differenceInMinutes
} from 'date-fns';
import scaleSize from 'utils/scale-size';
import Text from 'elements/Text';
import { roundToNearestHalfHour } from 'utils/helpers';
import {
  CHANNEL_EPG_IMAGE_WIDTH,
  EPG_TIME_SLOT_WIDTH
} from '../../../constants';

export const TimeLineContainer = styled.div<{ translateX: number }>`
  will-change: transform;
  display: flex;
  position: relative;
  left: ${CHANNEL_EPG_IMAGE_WIDTH}px; // width of the channel image
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.black};
  z-index: 7;
  transform: ${({ translateX }) => `translate3d(${translateX}px, 0, 0)`};
  ${({ theme }) => theme.margin('s', 'bottom')};
  ${({ theme }) => theme.margin('l', 'top')};
`;

export const TimeSlot = styled(Text)`
  color: ${({ theme }) => theme.colors.gray05};
`;
export const TimeSlotContainer = styled.div`
  width: ${EPG_TIME_SLOT_WIDTH}px;
  flex-shrink: 0;
`;

export const NowContainer = styled.div<{
  offset?: number;
}>`
  position: absolute;
  left: ${({ offset }) => scaleSize(offset || 0)}px;
  background-color: ${({ theme }) => theme.colors.red01};
  border-radius: ${scaleSize(4)}px;
  top: -${scaleSize(10)}px;
  padding: ${scaleSize(8)}px ${scaleSize(8)}px;
`;

export const NowText = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
`;

export const NowTimeText = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
`;

const getNextHours = ({
  start,
  hours
}: {
  start: string;
  hours: number;
}): string[] => {
  let now = new Date(start);
  const minutes = now.getMinutes();

  now = minutes < 30 ? startOfHour(now) : setMinutes(startOfHour(now), 30);

  return Array.from({ length: hours * 2 }, (_, i) => {
    const time = addMinutes(now, i * 30);
    return format(time, 'h.mma').toLowerCase();
  });
};

const TimeLine: React.FC<{
  start: string;
  hours: number;
  translateX: number;
}> = ({ start, hours, translateX }) => {
  const timeSlots = getNextHours({ start, hours });

  const offsetPercentage = Math.round(
    (differenceInMinutes(new Date(), roundToNearestHalfHour(new Date())) / 30) *
      100
  );
  return (
    <TimeLineContainer translateX={translateX}>
      <NowContainer offset={offsetPercentage * 2.96}>
        <NowText>NOW</NowText>
        <NowTimeText> {format(new Date(), 'h.mma').toLowerCase()}</NowTimeText>
      </NowContainer>
      {timeSlots.map((slot, index) => (
        <TimeSlotContainer key={slot}>
          <TimeSlot>{index > 0 && slot}</TimeSlot>
        </TimeSlotContainer>
      ))}
    </TimeLineContainer>
  );
};

export default TimeLine;
