import React, { FC, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { fetchPage } from 'store/features/pages/actions';
import { useParams } from 'react-router-dom';
import ListsContainer from 'components/List/ListsContainer';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';

const PageWrapper = styled.div`
  ${({ theme }) => theme.fullScreen};
`;
const Page: FC<{ propsPageId?: string }> = ({ propsPageId }) => {
  const { pageId = propsPageId } = useParams<{ pageId?: string }>();
  const PREFIX = `Page_${pageId}`;
  const statePage = useAppSelector((state) =>
    pageId ? state?.pages?.[pageId] : undefined
  );

  const page = useMemo(() => {
    if (statePage) {
      return statePage;
    }
    return {
      data: {
        collection: []
      }
    };
  }, [statePage]);

  const dispatch = useAppDispatch();

  const { data: pageContents } = page;

  useEffect(() => {
    pageId && dispatch(fetchPage({ pageId }));
  }, [pageId]);

  return (
    <PageWrapper data-testid="page">
      <ListsContainer data={pageContents} prefix={PREFIX} />
    </PageWrapper>
  );
};

export default Page;
