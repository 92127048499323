import { RefObject, useEffect, useState } from 'react';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';

export const useListVisibility = (ref: RefObject<any>) => {
  const [isListVisible, setIsListVisible] = useState(false);
  const [setListRef, listEntry] = useIntersectionObserver({
    threshold: [0, 0.25, 0.5, 0.75, 1],
    root: null,
    rootMargin: '0px'
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setListRef(ref.current);
    }, 100);
    return () => clearTimeout(timer);
  }, [ref, setListRef]);

  useEffect(() => {
    if (listEntry) {
      setIsListVisible(
        listEntry.isIntersecting && listEntry.intersectionRatio > 0.2
      );
    }
  }, [listEntry]);

  return isListVisible;
};
