import { getConnectedSourceList } from 'devices/TPV/getConnectedSourceList';
import { useEffect, useState } from 'react';

export type ConnectedSourceEvent = Event & {
  sourceName: string;
  sourceType: string;
};

function sortByType(a: InputSource, b: InputSource) {
  if (a.type < b.type) {
    return -1;
  }
  if (a.type > b.type) {
    return 1;
  }
  return 0;
}

export function useConnectedInputSources() {
  const [connectedSources, setConnectedSources] = useState<InputSource[]>(() =>
    fetchSources()
  );

  function onConnectedSourceAdded(e: Event) {
    console.log('==> [Event]: connectedSourceAdded', e);

    const isConnectedSourceEvent =
      e.hasOwnProperty('sourceName') && e.hasOwnProperty('sourceType');
    const typedEvent = e as ConnectedSourceEvent;

    if (!isConnectedSourceEvent) {
      return;
    }

    const sourceToAdd = {
      name: typedEvent.sourceName,
      type: typedEvent.sourceType
    };

    const newSources = [...connectedSources, sourceToAdd];

    const uniqueSources = newSources.filter(
      (source, index, self) =>
        index === self.findIndex((s) => s.type === source.type)
    );

    setConnectedSources(uniqueSources.sort(sortByType));
  }

  function onConnectedSourceRemoved(e: Event) {
    console.log('==> [Event]: connectedSourceRemoved', e);
    const isConnectedSourceEvent =
      e.hasOwnProperty('sourceName') && e.hasOwnProperty('sourceType');
    const typedEvent = e as ConnectedSourceEvent;
    if (!isConnectedSourceEvent) {
      return;
    }

    const sourceToRemove = {
      name: typedEvent.sourceName,
      type: typedEvent.sourceType
    };
    const newSources = [...connectedSources].filter((source) => {
      return source.type !== sourceToRemove.type;
    });

    setConnectedSources(newSources.sort(sortByType));
  }

  function fetchSources() {
    const sources = getConnectedSourceList();
    const initialSources = sources?.map((source: InputSource) => ({
      name: source.name,
      type: source.type
    }));

    return initialSources.sort(sortByType) || [];
  }

  function refreshSources() {
    setConnectedSources(fetchSources());
  }

  useEffect(() => {
    document.addEventListener('connectedSourceAdded', onConnectedSourceAdded);

    document.addEventListener(
      'connectedSourceRemoved',
      onConnectedSourceRemoved
    );

    return () => {
      document.removeEventListener(
        'connectedSourceAdded',
        onConnectedSourceAdded
      );
      document.removeEventListener(
        'connectedSourceRemoved',
        onConnectedSourceRemoved
      );
    };
  }, [connectedSources]);

  return { connectedSources, refreshSources };
}
