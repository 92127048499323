import { createSlice } from '@reduxjs/toolkit';
import { fetchBanners } from 'store/features/banners/actions';

interface Banner {
  data: { collection: any[] };
  isLoading?: boolean;
  isSuccess?: boolean;
  errorMessage?: string;
}

interface BannersState {
  [key: string]: Banner;
}

const initialState: BannersState = {};

export const bannersSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending, (state, { meta }) => {
        const { prefix } = meta.arg;
        state[prefix] = state[prefix] || {
          data: { collection: [] }
        };
        state[prefix].isLoading = true;
      })
      .addCase(
        fetchBanners.fulfilled,
        (
          state,
          { payload, meta }: ReturnType<typeof fetchBanners.fulfilled>
        ) => {
          const { prefix } = meta.arg;
          state[prefix].isLoading = false;
          state[prefix].isSuccess = true;
          state[prefix].data = payload as Banner['data'];
        }
      )
      .addCase(
        fetchBanners.rejected,
        (
          state,
          { payload, meta }: ReturnType<typeof fetchBanners.rejected>
        ) => {
          const { prefix } = meta.arg;
          state[prefix].isLoading = false;
          state[prefix].isSuccess = false;
          state[prefix].errorMessage = payload as string;
        }
      );
  }
});

export default bannersSlice.reducer;
