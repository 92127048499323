export const ACTION_TYPES = {
  SHOW_TOAST: 'showToast',
  REMOVE_TOAST: 'removeToast'
} as const;

type ActionTypes = typeof ACTION_TYPES;
type Actions = {
  [Property in keyof ActionTypes]: {
    type: ActionTypes[Property];
    payload?: {
      toastText?: string;
    };
  };
};
type DispatchType = (args: Actions[keyof Actions]) => void;

export const showToast = (toastText: string) => (dispatch: DispatchType) => {
  dispatch({
    type: ACTION_TYPES.SHOW_TOAST,
    payload: {
      toastText
    }
  });
};

export const removeToast = () => (dispatch: DispatchType) => {
  dispatch({
    type: ACTION_TYPES.REMOVE_TOAST
  });
};
