import React from 'react';
import styled from 'styled-components';
import scaleSize from 'utils/scale-size';
import Button from 'elements/Button';
import { executeAction } from 'utils/actions';
import resizeImage from 'utils/resize-image';
import theme from 'styles/theme';

const { SpecialStarIcon } = theme.icons;

const ModalWrapper = styled.div`
  width: ${scaleSize(720)}px;
  border-radius: ${scaleSize(16)}px;
  background: ${({ theme }) => theme.colors.grey3};
  max-height: 80vh;
  overflow: hidden;
`;

const ButtonsWrapper = styled.div`
  ${({ theme }) => theme.margin('l', 'top')};
  padding-bottom: ${({ theme }) => theme.marginSizes.s}px;
`;

const ContentWrapper = styled.div<{ bg: string }>`
  width: ${scaleSize(720)}px;
  height: ${scaleSize(432)}px;
  background-size: cover;
  background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0) 40%
    ),
    url(${({ bg }) => bg});
  position: relative;
`;

const ContentTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${scaleSize(32)}px;
  font-weight: 300;
  margin-bottom: ${scaleSize(10)}px;
`;

const ContentDetails = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.marginSizes.s}px;
`;

const BoxedItem = styled.div`
  height: ${scaleSize(36)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${scaleSize(24)}px;
  line-height: ${scaleSize(24)}px;
  font-weight: 500;
  border-radius: ${scaleSize(4)}px;
  border: solid 1px ${({ theme }) => theme.colors.gray07};
  padding: 0 ${scaleSize(12)}px;
`;

const ContentRatingWrapper = styled(BoxedItem)`
  max-width: ${scaleSize(66)}px;
  color: ${({ theme }) => theme.colors.gold};
  border-color: ${({ theme }) => theme.colors.gold};
`;

const ContentQuality = styled(BoxedItem)`
  max-width: ${scaleSize(46)}px;
  color: ${({ theme }) => theme.colors.gray07};
  border-color: ${({ theme }) => theme.colors.gray07};
  background: ${({ theme }) => theme.colors.gray02};
`;

const ContentDetailsText = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray07};
  font-size: ${scaleSize(28)}px;
  font-weight: 300;
  line-height: ${scaleSize(40)}px;
`;

const ContentDescription = styled.div`
  position: absolute;
  bottom: ${scaleSize(34)}px;
  left: ${({ theme }) => theme.marginSizes.l}px;
`;

const ButtonWrapper = styled(Button)`
  display: block;
  ${({ theme }) => theme.margin('l', 'right')};
  ${({ theme }) => theme.margin('l', 'left')};
  ${({ theme }) => theme.margin('s', 'bottom')};
  text-align: center;
`;

function ContentRating({ rating = 0 }: { rating?: number }) {
  return (
    <ContentRatingWrapper>
      <SpecialStarIcon />
      &nbsp;
      {rating}
    </ContentRatingWrapper>
  );
}

const DeeplinkModal: React.FC<{ data: any; closeModal: any }> = ({
  data,
  closeModal
}) => {
  const src = resizeImage(data?.images?.screenshot_landscape || null, {
    width: `${scaleSize(720)}`,
    height: `${scaleSize(432)}`
  });

  const handleClick = (deeplink: any) => {
    closeModal();
    executeAction('launch-app', deeplink);
  };

  return (
    <ModalWrapper>
      <ContentWrapper bg={src}>
        <ContentDescription>
          <ContentTitle>{data?.title}</ContentTitle>
          <ContentDetails>
            <ContentRating rating={8.7} />
            <ContentQuality>4K</ContentQuality>
            <ContentDetailsText>{data?.year || 1970}</ContentDetailsText>
            <ContentDetailsText>1h 51m</ContentDetailsText>
          </ContentDetails>
        </ContentDescription>
      </ContentWrapper>
      <ButtonsWrapper>
        {data.deeplinkings.map((deeplink: any) => (
          <ButtonWrapper
            key={`launch-app-${deeplink.app.name}`}
            title={`Watch on ${deeplink.app.name}`}
            handleClick={() => handleClick(deeplink)}
            focusKey={`launch-app-${deeplink.app.id}`}
          />
        ))}
      </ButtonsWrapper>
    </ModalWrapper>
  );
};

export default DeeplinkModal;
