import { windowGlobalApi } from 'utils/window-global-api';
import SPECIAL_APPS from 'constants/special-apps';
import { DEVICE_FVP_APP_NAMES } from 'devices/TPV/fetchDeviceList';
import CONTENT_TYPES from 'constants/content-types';

windowGlobalApi('loadLocalUI');
windowGlobalApi('loadApp');
windowGlobalApi('GotoNetflix');
windowGlobalApi('GotoAIV');
windowGlobalApi('GotoOpenInternet');
windowGlobalApi('GotoFVPApp');
windowGlobalApi('GotoFVPProgram');
windowGlobalApi('TuneToListedChannel');
windowGlobalApi('NetflixVisibleRecommenadationList');

type DefaultKeyFunctionMap = {
  [key: string]: (payload: any) => void;
};

export enum ActionSource {
  FAVOURITE_APPS = 'favorite_apps',
  STORE = 'store',
  BANNER = 'banner',
  SEARCH = 'search',
}

interface ActionPayload {
  id?: string;
  app: {
    isFVPApp: boolean;
    url: string;
    name: string;
    type?: string;
    code: string;
    app_type: string;
    resolution: string;
  };
}

interface TuneTVChannelPayload extends ActionPayload {
  channelNumber: string;
}

interface LaunchAppPayload extends ActionPayload {
  url?: string;
}

interface LaunchProgramPayload extends ActionPayload {
  source: ActionSource;
  url?: string;
}

type LaunchMediaPayload = LaunchAppPayload & LaunchProgramPayload;

interface SendNetflixImpressionsPayload extends ActionPayload {
  impressionDeepLinks?: string[];
}
const DEFAULT_YOUTUBE_URL = 'https://www.youtube.com/tv?launch=menu';
const DEFAULT_DISNEY_URL = 'disneyplus';

const appOverrides: DefaultKeyFunctionMap = {
  [SPECIAL_APPS.bbc_iplayer]: (payload: LaunchMediaPayload) =>
    window.GotoFVPApp(payload.app.url, payload.app.name),
  [SPECIAL_APPS.itv_hub]: (payload: LaunchMediaPayload) =>
    window.GotoFVPApp(payload.app.url, payload.app.name),
  [SPECIAL_APPS.all_4]: (payload: LaunchMediaPayload) =>
    window.GotoFVPApp(payload.app.url, payload.app.name),
  [SPECIAL_APPS.demand_5]: (payload: LaunchMediaPayload) =>
    window.GotoFVPApp(payload.app.url, payload.app.name),
  [SPECIAL_APPS.freeview_explore]: (payload: LaunchMediaPayload) =>
    window.GotoFVPApp(payload.app.url, payload.app.name),
  [SPECIAL_APPS.bbc_sounds]: (payload: LaunchMediaPayload) =>
    window.GotoFVPApp(payload.app.url, payload.app.name),
  [SPECIAL_APPS.britbox]: (payload: LaunchMediaPayload) =>
    window.GotoFVPApp(payload.app.url, payload.app.name),
  [SPECIAL_APPS.netflix]: (payload: LaunchMediaPayload) =>
    window.GotoNetflix(payload.url || payload?.app?.url, payload.source),
  [SPECIAL_APPS.youtube]: (payload: LaunchMediaPayload) => {
    const url = payload.url || payload?.app?.url || DEFAULT_YOUTUBE_URL;

    window.loadApp(
      url,
      'YouTube',
      'NetTVSpecial',
      payload.app.resolution || '720p'
    );
  },
  [SPECIAL_APPS.dazn]: (payload: LaunchMediaPayload) =>
    window.loadApp(
      (payload.url || payload?.app?.url).replace('{TargetName}', 'philips'),
      payload.app.name,
      payload.app.app_type,
      payload.app.resolution
    ),
  [SPECIAL_APPS.prime_video]: (payload: LaunchMediaPayload) =>
    window.GotoAIV(payload.url || payload?.app?.url, 'PrimeVideo'),
  // window.loadApp(`detail?gti=${payload.url || payload?.app?.url}`, 'primevideo', 'NetTVSpecial', ''),
  [SPECIAL_APPS.internet_browser]: (payload: LaunchMediaPayload) =>
    window.GotoOpenInternet(
      payload.app.url,
      payload.app.name,
      payload.app.app_type
    ),
  [SPECIAL_APPS.disney]: (payload: LaunchMediaPayload) =>
    window.loadApp(
      payload.url || payload?.app?.url || DEFAULT_DISNEY_URL,
      'disney',
      'Disney',
      payload.app.resolution || '1080p'
    ),
  [SPECIAL_APPS.demo_me]: () => window.loadLocalUI('demome'),
  [SPECIAL_APPS.media]: () => window.loadLocalUI('media'),
  [SPECIAL_APPS.google_assistant]: () => window.loadLocalUI('ga'),
  [SPECIAL_APPS.alexa]: () => window.loadLocalUI('alexa'),
  [SPECIAL_APPS.free_channels]: (payload: LaunchMediaPayload) =>
    window.open(payload.url || payload?.app?.url)
};

export const actions: DefaultKeyFunctionMap = {
  'watch-tv': () => window.loadLocalUI('tv'),
  'open-epg': () => window.loadLocalUI('epg'),
  'open-sources': () => window.loadLocalUI('sources'),
  'open-source': (payload: ActionPayload) => window.loadLocalUI(payload.id),
  'open-settings': () => window.loadLocalUI('setting'),
  'tune-tv-channel': (payload: TuneTVChannelPayload) =>
    window.TuneToListedChannel(payload.channelNumber),
  'send-netflix-impressions': (payload: SendNetflixImpressionsPayload) => {
    window.NetflixVisibleRecommenadationList(
      payload.impressionDeepLinks?.filter((link) => !!link.trim()).join(',')
    );
  },
  'launch-app': (payload: LaunchAppPayload) => {
    const appLaunchOverride = appOverrides[payload.app.code];

    if (payload.app.isFVPApp) {
      window.GotoFVPApp(payload.app.url, payload.app.name);
    } else if (!!appLaunchOverride) {
      appLaunchOverride(payload);
    } else {
      window.loadApp(
        payload.url || payload.app.url,
        payload.app.name,
        payload.app.app_type,
        payload.app.resolution
      );
    }
  },
  'launch-program': (payload: LaunchProgramPayload) => {
    const appLaunchOverride = appOverrides[payload.app.code];

    if (DEVICE_FVP_APP_NAMES.hasOwnProperty(payload.app.code)) {
      window.GotoFVPProgram(payload.url);
    } else if (!!appLaunchOverride) {
      appLaunchOverride(payload);
    } else {
      window.GotoFVPApp(payload.app.url, payload.app.name);
    }
  }
};

export const executeAction = (action: string = '', payload: any) => {
  console.log(action, payload);
  if (actions[action]) {
    actions[action](payload);
  } else {
    console.error('==> Unknown action:', action);
  }
};
