import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from './api';
import { convertKeysToCamelCase } from 'utils/helpers';
import {
  addChannelToListFavorites,
  deleteChannelFromListFavorites
} from 'store/features/lists/actions';
import { Channel as IChannel } from 'store/types';

export const fetchChannels = createAsyncThunk(
  'channels/fetchChannels',
  async () => {
    const response = await api.getChannels();
    return convertKeysToCamelCase({
      collection: response.collection,
      pagination: response.pagination
    });
  }
);

export const fetchFavoriteChannels = createAsyncThunk(
  'channels/fetchFavoriteChannels',
  async () => {
    const response = await api.getFavoriteChannels();
    return convertKeysToCamelCase({
      collection: response.collection,
      pagination: response.pagination
    });
  }
);

export const addChannelToWatched = createAsyncThunk(
  'channels/addChannelToWatched',
  async (
    { channelId, duration }: { channelId: number; duration: number },
    thunkAPI
  ) => {
    const response = await api.addChannelToWatched(channelId, duration);
    return convertKeysToCamelCase({
      collection: response.collection,
      pagination: response.pagination
    });
  }
);

export const fetchChannelsGenres = createAsyncThunk(
  'channels/fetchChannelsGenres',
  async () => {
    const response = await api.getChannelsGenres();
    return convertKeysToCamelCase({
      collection: response.collection,
      pagination: response.pagination
    });
  }
);

export const fetchChannelPrograms = createAsyncThunk(
  'channels/fetchChannelPrograms',
  async (channelId: string) => {
    const response = await api.getChannelPrograms(channelId);
    return convertKeysToCamelCase({
      collection: response.collection,
      pagination: response.pagination
    });
  }
);

export const fetchChannelsByGenre = createAsyncThunk(
  'channels/fetchChannelsByGenre',
  async (genreId: number) => {
    const response = await api.getChannelsByGenre(genreId);
    return convertKeysToCamelCase(response.data);
  }
);

export const fetchEPGbyGenre = createAsyncThunk(
  'channels/fetchChannelProgramsByGenre',
  async (genreId: number) => {
    const date = new Date();
    const formattedDate =
      date.getUTCFullYear().toString() +
      (date.getUTCMonth() + 1).toString().padStart(2, '0') +
      date.getUTCDate().toString().padStart(2, '0');
    const hoursUTC = date.getUTCHours();

    const response = await api.getEPGByGenre(genreId, formattedDate, hoursUTC);
    return convertKeysToCamelCase({
      collection: response.collection,
      pagination: response.pagination
    });
  }
);

export const fetchProgramsByChannel = createAsyncThunk(
  'channels/getProgramsByChannel',
  async (channelId: number) => {
    const date = new Date();
    const formattedDate =
      date.getUTCFullYear().toString() +
      (date.getUTCMonth() + 1).toString().padStart(2, '0') +
      date.getUTCDate().toString().padStart(2, '0');
    const hoursUTC = date.getUTCHours();

    const response = await api.getProgramsByChannel(
      channelId,
      formattedDate,
      hoursUTC
    );
    return convertKeysToCamelCase(response);
  }
);

export const addToFavChannelsAndUpdateList = createAsyncThunk(
  'channels/addToFavChannelsAndUpdateList',
  async (channel: IChannel, { dispatch }) => {
    const resultAction = await dispatch(addToFavChannels(channel.id));
    if (addToFavChannels.fulfilled.match(resultAction)) {
      await dispatch(addChannelToListFavorites({ channel }));
    }
    return channel;
  }
);

export const deleteFromFavChannelsAndUpdateList = createAsyncThunk(
  'channels/deleteFromFavChannelsAndUpdateList',
  async (channel: IChannel, { dispatch }) => {
    const resultAction = await dispatch(deleteFromFavChannels(channel.id));
    if (deleteFromFavChannels.fulfilled.match(resultAction)) {
      await dispatch(deleteChannelFromListFavorites({ channel }));
    }
    return channel;
  }
);

export const addToFavChannels = createAsyncThunk(
  'channels/addToFavChannels',
  async (channelId: number) => {
    const response = await api.addChannelToFavorite(channelId);
    return convertKeysToCamelCase(response.data);
  }
);

export const deleteFromFavChannels = createAsyncThunk(
  'channels/deleteFromFavChannels',
  async (channelId: number) => {
    const response = await api.deleteChannelFromFavorite(channelId);
    return convertKeysToCamelCase(response.data);
  }
);
