import styled, { css } from 'styled-components';
import Focusable from 'components/Navigation/Focusable';
import scaleSize from 'utils/scale-size';
import Title from 'elements/Title';
import DescText from 'elements/DescText';

const flexDisplay = css`
  display: flex;
  align-items: stretch;
`;

export const OuterWrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: row;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.7) 5.26%,
      rgba(0, 0, 0, 0) 47.42%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
`;

export const ChannelMenuWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ isActive }) =>
    isActive ? `${scaleSize(380)}px` : `${scaleSize(112)}px`};
  ${({ theme }) =>
    theme.transition({
      value: 'width',
      timing: theme.timings.s,
      easing: theme.easings.ease
    })};
  border-right: ${({ theme }) => `1px solid ${theme.colors.gray02}`};
  padding: ${scaleSize(80)}px 0px ${scaleSize(80)}px ${scaleSize(40)}px;
`;

export const ChevronIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: ${scaleSize(12)}px;
`;

export const ChannelItemWrapper = styled(Focusable)`
  padding: ${scaleSize(12)}px;
  z-index: 3;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  top: ${scaleSize(759)}px;
  bottom: 0;
  width: ${scaleSize(1808)}px;
  height: 20%;
  gap: ${scaleSize(24)}px;
`;

export const ChannelActionsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  gap: ${scaleSize(12)}px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${scaleSize(16)}px;
  align-self: stretch;
  height: ${scaleSize(157)}px;
  margin-top: ${scaleSize(32)}px;
`;

export const TitleText = styled(Title)`
  line-height: ${scaleSize(42)}px;
  margin: 0;
`;

export const DurationText = styled(DescText)``;

export const GenreNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${scaleSize(8)}px;
`;
export const ChannelGenreWrapper = styled.div`
  display: flex;
  padding: ${scaleSize(2)}px ${scaleSize(12)}px;
  gap: ${scaleSize(10)}px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(4px);
  opacity: 0.6;
`;

export const ChannelGenreText = styled(DescText)`
  ${({ theme }) => theme.typography.fb};
  font-size: ${scaleSize(24)}px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: ${scaleSize(32)}px;
`;
export const ChannelNumberText = styled(DescText)`
  ${({ theme }) => theme.typography.fb};
  font-size: ${scaleSize(24)}px;
  color: rgba(255, 255, 255, 0.6);
  opacity: 0.6;
`;
export const Image = styled.img.attrs(({ src }) => ({ src }))`
  width: ${scaleSize(
    220
  )}px; //TODO change after BE sends data with landscape photos
  height: ${scaleSize(
    124
  )}px; //TODO change after BE sends data with landscape photos
  border-radius: ${scaleSize(4)}px;
  overflow: hidden;
  background-color: black;
`;

export const ImgWrapper = styled.div<{ isFocused: boolean }>`
  ${flexDisplay};
  width: ${scaleSize(
    220
  )}px; //TODO change after BE sends data with landscape photos
  height: ${scaleSize(124)}px;
  position: relative;
  background-color: black;
  ${({ isFocused, theme }) =>
    isFocused &&
    `
    box-shadow: 0 0 0 ${scaleSize(4)}px ${theme.colors.primary};
    border: solid ${scaleSize(2)}px black;
    border-radius: ${scaleSize(4)}px;
  `}
`;

export const ImgOuterWrapper = styled.div`
  ${({ theme }) => theme.margin('xxl', 'left')};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  gap: ${scaleSize(12)}px;
`;
