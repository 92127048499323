const vw = Math.max(
  document.documentElement.clientWidth || 0,
  window.innerWidth || 0
);

const SIZES: DefaultKeyNumberMap = {
  FHD: 1920,
  HD: 1280
};

function findClosestSize(vw: number) {
  let closestSize = null;
  let closestDifference = Infinity;

  for (const size in SIZES) {
    const difference = Math.abs(vw - SIZES[size]);
    if (difference < closestDifference) {
      closestDifference = difference;
      closestSize = size;
    }
  }

  return closestSize;
}

export const appScale = findClosestSize(vw); // || process.env.REACT_APP_SCALE || 'FHD';

console.log('==> appScale', appScale);

const scaleSize = (size: number): number => {
  switch (appScale) {
    case 'FHD':
      return size;
    case 'HD':
      return Math.floor(size * 0.666666666666667);
    default:
      return size;
  }
};

export default scaleSize;
