import React, {
  RefObject,
  useEffect,
  useState,
  memo,
  useLayoutEffect
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  FocusContext,
  getCurrentFocusKey,
  setFocus,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import { updateMenu } from 'store/features/menu/actions';
import { resetFocusData } from 'store/features/focuses/focusesSlice';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import {
  MenuInnerWrapper,
  MenuItem,
  MenuWrapper,
  MenuHidden
} from './Menu.styles';
import MenuIcons from './MenuIcons';
import theme from 'styles/theme';
import FOCUS_KEYS from 'constants/focus-keys';

const { SearchIcon, ChevronIcon } = theme.icons;

export interface MenuItemType {
  content_type?: string;
  position: number;
  name: string;
  type: string;
  id: string;
  url: string;
}

export interface MenuState {
  data: {
    collection: MenuItemType[];
  };
}

const PATH_OVERRIDE: { [key: string]: string } = {
  '/page/1': '/'
};

function getFocusKeyFromIndex(index?: number) {
  return `menu-item-${index ?? 1}`;
}

const Menu: React.FC = memo(() => {
  const [focusedItem, setFocusedItem] = useState({} as MenuItemType);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isMenuVisible = useAppSelector((state) => state.menu.menuIsVisible);
  const menu = useAppSelector(
    (state: { menu: MenuState }) => state.menu.data.collection
  );
  const hintVisible = useAppSelector((state) => state.menu.hintVisible);
  const menuOpacity = useAppSelector((state) => state.menu.menuOpacity);
  const dispatch = useAppDispatch();
  const { history } = useAppSelector(
    (state) => state.navigation.routes?.[pathname] || { history: [] }
  );

  const { focusKey, ref, hasFocusedChild } = useFocusable({
    trackChildren: true,
    focusKey: FOCUS_KEYS.menu,
    preferredChildFocusKey: getFocusKeyFromIndex(getActiveItem()?.position),
    focusable: isMenuVisible
  });

  const isMenuFocused = getCurrentFocusKey()?.startsWith('menu-item');
  useLayoutEffect(() => {
    const focusedItemIsActiveItem =
      getCurrentFocusKey() === getFocusKeyFromIndex(getActiveItem()?.position);
    const lastFocusWasInMenu =
      history[history.length - 2]?.startsWith('menu-item');

    if (isMenuFocused && !focusedItemIsActiveItem && !lastFocusWasInMenu) {
      setFocus(getFocusKeyFromIndex(getActiveItem()?.position));
    }
  }, [getCurrentFocusKey()]);

  useEffect(() => {
    dispatch(updateMenu());
  }, [menu.length]);

  function getActiveItem() {
    return menu.find((item) => {
      const pagePath = item.position === 1 ? '/' : `/page/${item.id}`;
      const path = item.type === 'page' ? pagePath : item.url;
      return (PATH_OVERRIDE[path] || path) === pathname;
    });
  }

  if (!isMenuVisible) {
    return hintVisible ? (
      <MenuHidden>
        <ChevronIcon isFocused={false} />
      </MenuHidden>
    ) : null;
  }

  const { host, protocol } = window.location;
  const channelsUrl = `${protocol}//${host}/channels`;

  const currentFocusedKey = getCurrentFocusKey();

  return (
    <FocusContext.Provider value={focusKey}>
      <MenuWrapper
        isMenuVisible={isMenuVisible}
        ref={ref as RefObject<HTMLDivElement>}
        menuOpacity={menuOpacity}
        isBlurred={!hasFocusedChild}
      >
        <MenuInnerWrapper>
          {menu.map((menuItem, itemIndex) => {
            const pagePath =
              menuItem.position === 1 ? '/' : `/page/${menuItem.id}`;
            const path = menuItem.type === 'page' ? pagePath : menuItem.url;
            const isCurrent = (PATH_OVERRIDE[path] || path) === pathname;
            const focusKey = getFocusKeyFromIndex(itemIndex);
            return (
              <MenuItem
                isBlurred={!hasFocusedChild}
                isFocused={currentFocusedKey === focusKey && isMenuFocused}
                key={focusKey}
                focusKey={focusKey}
                isCurrent={isCurrent}
                onFocus={() => setFocusedItem(menuItem)}
                onArrowPress={(direction) => {
                  if (direction === 'left') {
                    return itemIndex > 0;
                  }
                  return direction !== 'up';
                }}
                onEnterRelease={() => {
                  if (!hasFocusedChild) {
                    return;
                  }
                  if (menuItem.url === '/channels') {
                    window.open(channelsUrl);
                    return;
                  }
                  isCurrent && dispatch(resetFocusData({ page: pathname }));
                  navigate(PATH_OVERRIDE[path] || path, {
                    state: { pageId: menuItem.id }
                  });
                }}
              >
                {/*TODO: need to find better solution*/}
                {menuItem.url === '/search' ? (
                  <SearchIcon
                    isFocused={focusedItem.name === menuItem.name}
                  />
                ) : (
                  menuItem.name
                )}
              </MenuItem>
            );
          })}
        </MenuInnerWrapper>
        <MenuIcons hasFocusedChild={hasFocusedChild} />
      </MenuWrapper>
    </FocusContext.Provider>
  );
});

export default Menu;
