import { format, startOfHour, addMinutes } from 'date-fns';

export function convertKeysToCamelCase(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(convertKeysToCamelCase);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce(
      (acc: { [key: string]: any }, key: string) => {
        const camelCaseKey = key.replace(/_([a-z])/g, (match, p1) =>
          p1.toUpperCase()
        );

        if (acc.hasOwnProperty(camelCaseKey)) {
          // TODO: report this to tracker to implement a more robust solution
          console.warn(
            `Warning: '${camelCaseKey}' already exists in the object`
          );
        }
        acc[camelCaseKey] = convertKeysToCamelCase(obj[key]);
        return acc;
      },
      {}
    );
  } else {
    return obj;
  }
}

export function formatDate(dateString: Date | string): string {
  const date = new Date(dateString);
  return format(date, 'h.mma').toLowerCase();
}

export function roundToNearestHalfHour(date: Date): Date {
  const minutes = date.getMinutes();
  const isHalfPast = minutes >= 30;
  let roundedDate = startOfHour(date);

  if (isHalfPast) {
    roundedDate = addMinutes(roundedDate, 30);
  }
  return roundedDate;
}
