import { createSlice, CreateSliceOptions } from '@reduxjs/toolkit';
import { fetchGenres } from './action';

interface GenreData {
  id: number;
  image: { id: number; image?: string };
  name: string;
}

interface PageData {
  count?: number;
  currentPage?: number;
  nextPage?: number;
  perPage?: number;
  prevPage?: number;
  totalCount?: number;
  totalPages?: number;
}

interface GenreState {
  data: { collection: GenreData[] };
  pagination: PageData;
  isLoading: boolean;
  error: { message: string } | null;
}

const initialState: GenreState = {
  data: { collection: [] },
  pagination: {},
  isLoading: false,
  error: null
};

const genresOptions: CreateSliceOptions<GenreState, {}, 'genres'> = {
  name: 'genres',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGenres.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchGenres.fulfilled,
        (state, { payload }: ReturnType<typeof fetchGenres.fulfilled>) => {
          state.isLoading = false;
          state.data.collection = payload.collection.map((item: any) => ({
            ...item,
            type: 'genre'
          }));
          state.pagination = payload.pagination;
          state.error = null;
        }
      )
      .addCase(
        fetchGenres.rejected,
        (state, { error }: ReturnType<typeof fetchGenres.rejected>) => {
          state.isLoading = false;
          state.error = { message: error.message ?? '' };
        }
      );
  }
};

const genresSlice = createSlice(genresOptions);
export default genresSlice.reducer;
