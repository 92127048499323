import React from 'react';
import styled from 'styled-components';
import scaleSize from 'utils/scale-size';
import Button from 'elements/Button';
import packageJson from '../../../package.json';
import Title from 'elements/Title';

const ModalWrapper = styled.div`
  width: ${scaleSize(720)}px;
  border-radius: ${scaleSize(16)}px;
  background: ${({ theme }) => theme.colors.grey3};
  max-height: 80vh;
  overflow: hidden;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.margin('l', 'bottom')};
`;

const ButtonWrapper = styled(Button)`
  display: flex;
`;

const VersionModal: React.FC<{ closeModal: any }> = ({ closeModal }) => {
  return (
    <ModalWrapper>
      <ButtonsWrapper>
        <Title>App Version: {packageJson.version}</Title>
        <ButtonWrapper
          title={`Close`}
          handleClick={closeModal}
          focusKey={`close-packageJson.version`}
        />
      </ButtonsWrapper>
    </ModalWrapper>
  );
};

export default VersionModal;
