import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  ChannelGenreText,
  ChannelMenuWrapper,
  ChannelGenreWrapper,
  ChannelItemWrapper,
  ChannelNumberText,
  DescriptionWrapper,
  DurationText,
  GenreNumberWrapper,
  Image,
  ImgWrapper,
  OuterWrapper,
  TitleText,
  ChannelActionsList,
  ImgOuterWrapper
} from './ChannelItem.styles';
import CONTENT_TYPE_TO_ARTWORK_TYPE from 'constants/content-type-to-artwork-type';
import resizeImage from 'utils/resize-image';
import artworkImage from 'utils/artwork-image';
import ARTWORK_SIZES from 'constants/artwork-sizes';
import CONTENT_TYPES from 'constants/content-types';
import { formatDate } from 'utils/helpers';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import {
  FocusContext,
  setFocus,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import MenuItemComponent from './MenuItem';
import {
  addToFavChannelsAndUpdateList,
  deleteFromFavChannelsAndUpdateList,
  fetchProgramsByChannel
} from 'store/features/channels/action';
import theme from 'styles/theme';
import { Channel as IChannel } from 'store/types/channel';
import { showToast } from 'store/features/toasts/actions';
import { useTranslation } from 'react-i18next';

const {
  EpgIconSideMenu: EpgIcon,
  ChevronIcon,
  HeartAddIcon,
  BackIcon,
  HeartCrossedIcon,
  ChevronIconDown
} = theme.icons;

interface ChannelItemProps {
  isVisible: boolean;
  onFocusChannelItem: () => void;
  onFocusChannelMenu: () => void;
  onBlur: () => void;
  onEnterPress?: () => void;
  currentChannelIndex: number;
  openProgram: () => void;
}

enum Actions {
  GO_TO_FAVORITES = 'go-to-favorites',
  ADD_TO_FAVORITES = 'add-to-favorites',
  DELETE_FROM_FAVORITES = 'delete-from-favorites',
  OPEN_INFO = 'open-info',
  OPEN_EPG = 'open-epg'
}

type MenuItemType = {
  Component: React.FC<{ isFocused: boolean }>;
  name: string;
  action: Actions;
  text: string;
};

const menuItems: Array<MenuItemType> = [
  {
    Component: HeartAddIcon,
    text: 'channels.addToMyChannels',
    name: Actions.ADD_TO_FAVORITES,
    action: Actions.ADD_TO_FAVORITES
  },
  {
    Component: HeartCrossedIcon,
    text: 'channels.deleteFromMyChannels',
    name: Actions.DELETE_FROM_FAVORITES,
    action: Actions.DELETE_FROM_FAVORITES
  },
  {
    Component: EpgIcon,
    text: 'channels.allChannels',
    name: Actions.OPEN_EPG,
    action: Actions.OPEN_EPG
  }
];
const ChannelItem: FC<ChannelItemProps> = ({
  isVisible = true,
  currentChannelIndex,
  onFocusChannelItem,
  onFocusChannelMenu,
  onBlur,
  openProgram
}) => {
  const { t } = useTranslation();
  const focusParams = { saveLastFocusedChild: true, isFocusBoundary: true };
  const { focusKey } = useFocusable(focusParams);
  const dispatch = useAppDispatch();

  const channels = useAppSelector(
    (state) => state.channels?.channels as IChannel[]
  );
  const favoriteChannels = useAppSelector(
    (state) => state.channels?.favoriteChannels
  );
  const [isWrapperFocused, setIsWrapperFocused] = useState(false);
  const [currentChannel, setCurrentChannel] = useState<IChannel | null>(
    channels[currentChannelIndex || 0]
  );

  const artworkType =
    CONTENT_TYPE_TO_ARTWORK_TYPE[currentChannel?.type || CONTENT_TYPES.channel];

  const src =
    currentChannel &&
    resizeImage(artworkImage(currentChannel) || null, {
      width: String(ARTWORK_SIZES[artworkType]?.width)
    });
  const [isMenuExtended, setIsMenuExtended] = useState(false);
  const [currentTimelineItem, setCurrentTimelineItem] = useState<any>(null);
  const currentTime = new Date().getTime();

  //TODO really bad piece of code, need to be rewritten
  useEffect(() => {
    setCurrentChannel(channels[currentChannelIndex]);
    channels[currentChannelIndex]?.id &&
      dispatch(fetchProgramsByChannel(channels[currentChannelIndex].id)).then(
        (res) => {
          const currentTimelineItem = res?.payload?.timeline?.find(
            (item: any) => {
              const startTime = new Date(item?.startTime).getTime();
              const endTime = new Date(item?.endTime).getTime();
              return startTime <= currentTime && currentTime <= endTime;
            }
          );
          currentTimelineItem && setCurrentTimelineItem(currentTimelineItem);
        }
      );
  }, [currentChannelIndex]);

  useEffect(() => {
    isVisible && setFocus('focusable-channel-item-key');
  }, [isVisible]);

  const onFocusHandler = useCallback(() => {
    onFocusChannelMenu();
    setIsMenuExtended(true);
  }, [isMenuExtended]);

  const onArrowPress = (direction: string, props: object) => {
    return direction !== 'down' && direction !== 'up';
  };

  const isFavoriteChannel =
    favoriteChannels &&
    favoriteChannels.find((i) => i?.id === currentChannel?.id);

  const onEnterPressHandler = (action: Actions) => {
    switch (action) {
      case Actions.ADD_TO_FAVORITES:
        currentChannel &&
          dispatch(addToFavChannelsAndUpdateList(currentChannel)).then(() =>
            dispatch(showToast('Channel was added to Favourites'))
          );
        break;
      case Actions.DELETE_FROM_FAVORITES:
        currentChannel &&
          dispatch(deleteFromFavChannelsAndUpdateList(currentChannel)).then(
            () => dispatch(showToast('Channel was deleted from Favourites'))
          );
        break;
      case Actions.OPEN_EPG:
      case Actions.GO_TO_FAVORITES:
        openProgram();
        break;
      default:
        console.warn(`No handler for action ${action}`);
    }
  };

  return (
    <OuterWrapper isVisible={isVisible}>
      <FocusContext.Provider value={focusKey}>
        <ChannelMenuWrapper isActive={isMenuExtended}>
          <MenuItemComponent
            isMenuExtended={isMenuExtended}
            focusKey={'focusable-channel-back-key'}
            text={t('channels.back')}
            Component={BackIcon}
            onFocus={onFocusHandler}
            onEnterPress={() => window.close()}
          />
          <ChannelActionsList>
            {menuItems.map(({ Component, name, action, text }) => {
              if (isFavoriteChannel && Component === HeartAddIcon) return null;
              if (!isFavoriteChannel && Component === HeartCrossedIcon)
                return null;
              return (
                <MenuItemComponent
                  focusKey={`focusable-channel-menu-item-${name}`}
                  isMenuExtended={isMenuExtended}
                  key={`side-menu-icon-${name}`}
                  text={t(text)}
                  onFocus={onFocusHandler}
                  Component={Component}
                  onEnterPress={() => onEnterPressHandler(action)}
                />
              );
            })}
          </ChannelActionsList>
        </ChannelMenuWrapper>
        <ChannelItemWrapper
          onFocus={() => {
            setIsMenuExtended(false);
            setIsWrapperFocused(true);
            onFocusChannelItem();
          }}
          onArrowPress={onArrowPress}
          onBlur={() => {
            onBlur();
            setIsWrapperFocused(false);
          }}
          focusKey={'focusable-channel-item-key'}
          extraProps={{
            'data-current-channel': currentChannel?.originalTitle
          }}
        >
          <ImgOuterWrapper>
            {isWrapperFocused && <ChevronIcon />}
            <ImgWrapper isFocused={isWrapperFocused}>
              {src && <Image src={src} />}
            </ImgWrapper>
            {isWrapperFocused && <ChevronIconDown />}
          </ImgOuterWrapper>
          <DescriptionWrapper>
            <TitleText>{currentChannel?.title || ''}</TitleText>
            {currentTimelineItem && (
              <DurationText>
                {`${formatDate(currentTimelineItem?.startTime)} - 
                ${formatDate(currentTimelineItem?.endTime)}`}
              </DurationText>
            )}
            <GenreNumberWrapper>
              {currentChannel?.genres && (
                <ChannelGenreWrapper>
                  <ChannelGenreText>
                    {currentChannel?.genres[0]?.name}
                  </ChannelGenreText>
                </ChannelGenreWrapper>
              )}
              <ChannelNumberText>{`CH${
                currentChannelIndex + 1
              }`}</ChannelNumberText>
            </GenreNumberWrapper>
          </DescriptionWrapper>
        </ChannelItemWrapper>
      </FocusContext.Provider>
    </OuterWrapper>
  );
};
export default ChannelItem;
