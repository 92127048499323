import React, { FC, useState } from 'react';
import styled from 'styled-components';

import DescText from 'elements/DescText';
import Focusable from 'components/Navigation/Focusable';
import scaleSize from 'utils/scale-size';

export const MenuItem = styled.div<{ focused: boolean }>`
  gap: ${scaleSize(24)}px;
  display: flex;
  align-items: center;
  color: ${({ theme, focused }) =>
    focused ? theme.colors.white : theme.colors.grey};
  width: 100%;
`;

export const MenuItemText = styled(DescText)<{ focused: boolean }>`
  ${({ theme }) => theme.typography.fb};
  font-size: ${scaleSize(24)}px;
  color: ${({ theme, focused }) =>
    focused ? theme.colors.white : theme.colors.white06};
`;

export const MenuActionItem = styled(Focusable)`
  padding: 0px ${scaleSize(12)}px;
  display: flex;
  height: ${scaleSize(84)}px;
  &.focused {
    background: linear-gradient(
      270deg,
      rgba(51, 127, 191, 0.25) 0%,
      rgba(51, 127, 191, 0) 98.68%
    );
    border-right: ${scaleSize(4)}px solid ${({ theme }) => theme.colors.primary};
  }
`;

const MenuItemComponent: FC<{
  onFocus: () => void;
  onBlur?: () => void;
  onEnterPress?: () => void;
  text?: string;
  focusKey?: string;
  isMenuExtended?: boolean;
  Component?: any;
  icon?: any;
}> = ({
  isMenuExtended,
  onEnterPress,
  onFocus,
  onBlur,
  focusKey,
  text,
  Component
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const onEnterPressHandler = () => {
    onEnterPress && onEnterPress();
  };
  const onFocusHandler = () => {
    onFocus();
    setIsFocused(true);
  };
  const onBlurHandler = () => {
    onBlur && onBlur();
    setIsFocused(false);
  };

  return (
    <MenuActionItem
      onFocus={onFocusHandler}
      focusKey={focusKey}
      onEnterRelease={onEnterPressHandler}
      onBlur={onBlurHandler}
    >
      <MenuItem focused={isFocused}>
        <Component isFocused={isFocused} />
        {isMenuExtended && (
          <MenuItemText focused={isFocused}>{text}</MenuItemText>
        )}
      </MenuItem>
    </MenuActionItem>
  );
};

export default MenuItemComponent;
