import React from 'react';
import theme from 'styles/theme';
import scaleSize from 'utils/scale-size';

type IconProps = {
  isFocused: boolean;
};

const CustomIcon: React.FC<IconProps> = ({ isFocused }) => {
  const size = scaleSize(32);
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 32 32`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1281_4434"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <path
          d="M32 0H0V32H32V0Z"
          fill={isFocused ? theme.colors.white : theme.colors.white06}
        />
      </mask>
      <g mask="url(#mask0_1281_4434)">
        <path
          d="M21.9765 6.98199H7.9375V11.013H21.9765V6.98199Z"
          fill={isFocused ? theme.colors.white : theme.colors.white06}
        />
        <path
          d="M0 21.192C0.00403279 22.137 0.353787 23.0479 0.983276 23.7527C1.61277 24.4575 2.47845 24.9076 3.41699 25.018H4.97501V20.995H0V21.192Z"
          fill={isFocused ? theme.colors.white : theme.colors.white06}
        />
        <path
          d="M13.9845 13.988H7.9375V18.015H13.9845V13.988Z"
          fill={isFocused ? theme.colors.white : theme.colors.white06}
        />
        <path
          d="M4.975 6.98199H0V11.013H4.975V6.98199Z"
          fill={isFocused ? theme.colors.white : theme.colors.white06}
        />
        <path
          d="M4.975 13.988H0V18.015H4.975V13.988Z"
          fill={isFocused ? theme.colors.white : theme.colors.white06}
        />
        <path
          d="M32.0001 6.98199H24.9941V11.013H32.0001V6.98199Z"
          fill={isFocused ? theme.colors.white : theme.colors.white06}
        />
        <path
          d="M32.0003 13.988H16.9863V18.015H32.0003V13.988Z"
          fill={isFocused ? theme.colors.white : theme.colors.white06}
        />
        <path
          d="M7.9375 20.995V25.018H28.5835C29.522 24.9074 30.3875 24.4573 31.017 23.7525C31.6464 23.0477 31.9962 22.1369 32.0005 21.192V20.992L7.9375 20.995Z"
          fill={isFocused ? theme.colors.white : theme.colors.white06}
        />
      </g>
    </svg>
  );
};

export default CustomIcon;
