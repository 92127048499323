import styled, { keyframes } from 'styled-components';
import scaleSize from 'utils/scale-size';
import { BANNERS_HEIGHT, marginSizes } from 'styles/base';
import Image from 'components/Image/Image';
import Text from 'elements/Text';
import Focusable from 'components/Navigation/Focusable';
import Button from 'elements/Button';
const BANNER_SWITCH_TIMER = 10000;

export const FakeButton = styled(Button)`
  ${({ theme }) => theme.margin('l', 'top')};
`;

export const BtnText = styled(Text)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${scaleSize(32)}px;
  line-height: ${scaleSize(48)}px;
`;

export const LogoImageWrapper = styled.div`
  max-height: ${scaleSize(67)}px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const LogoImage = styled.img`
  max-height: ${scaleSize(67)}px;
  width: auto;
`;

export const FakeFocusable = styled(Focusable)`
  width: 100%;
  height: ${BANNERS_HEIGHT / 2}px;
  position: absolute;
  top: ${scaleSize(150)}px;
  left: 0;
  z-index: 4;
`;

export const HidableData = styled.div`
  ${({ theme }) => theme.margin('s', 'top')};
  ${({ theme }) =>
    theme.transition([
      {
        value: 'transform',
        timing: theme.timings.l,
        easing: theme.easings.ease
      },
      {
        value: 'opacity',
        timing: theme.timings.l,
        easing: theme.easings.ease
      }
    ])};
  opacity: 0;
  transform: translateY(-${scaleSize(30)}px);
`;

export const OpacityOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 12.5%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 74.62%);
  z-index: 2;
  ${({ theme }) =>
    theme.transition({
      value: 'opacity',
      timing: theme.timings.l,
      easing: theme.easings.ease
    })};
  opacity: 1;
`;

export const Indicators = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: absolute;
  bottom: -${marginSizes.xl}px;
  right: ${marginSizes.xxl}px;
  ${({ theme }) =>
    theme.transition({
      value: 'transform',
      timing: theme.timings.l,
      easing: theme.easings.ease
    })};
`;

export const BannersWrapper = styled.div<{ isBlurred: boolean }>`
  position: relative;
  width: 100%;
  height: ${BANNERS_HEIGHT}px;
  margin-bottom: ${scaleSize(150)}px;
  // filter: ${({ isBlurred }) => (isBlurred ? 'blur(40px)' : 'none')};
  ${({ theme }) =>
    theme.transition({
      value: 'filter',
      timing: theme.timings.s,
      easing: theme.easings.ease
    })};
  &.focused {
    ${HidableData} {
      opacity: 1;
      transform: translateY(0);
    }
    ${OpacityOverlay} {
      opacity: 0;
    }
    ${Indicators} {
      transform: translateY(100px);
    }
  }
`;

export const BannerItemsWrapper = styled.div<{
  currentBannerIndex: number;
  bannersLength: number;
}>`
  display: flex;
  flex-wrap: nowrap;
  ${({ theme }) =>
    theme.transition({
      value: 'transform',
      timing: theme.timings.s,
      easing: theme.easings.ease
    })};
  content: '${({ currentBannerIndex }) => currentBannerIndex}';
  transform: translateX(
    -${({ currentBannerIndex }) => scaleSize(1920 * currentBannerIndex)}px
  );
  width: ${({ bannersLength }) => scaleSize(1920 * bannersLength)}px;
`;

export const SlidingBanner = styled.div`
  position: relative;
  width: ${scaleSize(1920)}px;
  height: ${scaleSize(1080)}px;
  flex-shrink: 0;
  z-index: -1;
`;

export const Banner = styled(Image)`
  width: ${({ theme }) => theme.sizes.fullWidth};
  height: ${scaleSize(1080)}px;
  background-size: contain;
  background-color: transparent;
`;

export const GradientOverlayVertical = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    var(--ambilight-color) 100%
  );
`;

export const GradientOverlayHorizontal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    var(--ambilight-color) 74.62%
  );
`;

export const BannerInnerWrapper = styled.div`
  ${({ theme }) => theme.margins([0, 'xxl', 0, 'xxl'])};
  position: absolute;
  top: ${scaleSize(190)}px;
  left: 0;
  width: 60%;
  height: 100%;
  z-index: 1;
`;

export const BannerDataWrapper = styled.div``;

export const BannerTitle = styled(Text).attrs({ as: 'div' })`
  font-size: ${scaleSize(80)}px;
  line-height: ${scaleSize(80)}px;
  font-weight: 500;
  margin-top: 12px;
`;

export const BannerText = styled(Text).attrs({ as: 'div' })`
  max-width: ${scaleSize(750)}px;
  font-weight: 300;
  line-height: ${scaleSize(40)}px;
  opacity: 0.6;
`;

export const Indicator = styled.div<{ active: boolean }>`
  ${({ theme }) => theme.margin('xs', 'left')};
  border-radius: 100px;
  width: ${scaleSize(16)}px;
  height: ${scaleSize(16)}px;
  background-color: ${({ active, theme }) =>
    active ? `${theme.colors.gray1}` : `${theme.colors.gray1}`};
`;

export const ProgressBarWrapper = styled.div`
  ${({ theme }) => theme.margin('xs', 'left')};
  border-radius: 100px;
  width: ${scaleSize(64)}px;
  height: ${scaleSize(16)}px;
  background-color: ${({ theme }) => theme.colors.gray1};
  overflow: hidden;
`;

export const progressAnimation = keyframes`
  0% {
    transform: translateX(-75%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  border-radius: ${scaleSize(8)}px;
  height: ${scaleSize(16)}px;
  background-color: ${({ theme }) => theme.colors.white};
  animation: ${progressAnimation} ${BANNER_SWITCH_TIMER}ms ease-in;
`;
