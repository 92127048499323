import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';
import { convertKeysToCamelCase } from 'utils/helpers';
import {
  fetchDeviceFVPMetadata,
  fetchDeviceList
} from 'devices/TPV/fetchDeviceList';
import COLLECTION_TYPES from 'constants/collection-types';
import { RootState } from 'store/configure-store';
import DEEPLINK_APPS from 'constants/apps';
import { Channel as IChannel } from 'store/types';
import SPECIAL_APPS from 'constants/special-apps';
import { APP_ROW_UPDATES_CLEAR } from 'constants/app-rows-updates-lists';

export interface EndpointsData {
  id?: string;
  type: string;
}

const buildEndpointParams = (data: EndpointsData, listId?: string) => {
  let params = { listId, endpoint: '', type: '', withAuthHeader: false };
  const enhanceFlags = { updateMetadata: false };
  if (data.id === 'search_list') {
    params.endpoint = `/contents/search`;
    params.type = data.type;
  } else if (data.id === 'apps_search_list') {
    params.endpoint = `/apps/search`;
    enhanceFlags.updateMetadata = true;
  } else if (data.id === 'youtube_search_list') {
    params.endpoint = `/youtube/search`;
  } else if (data.type === COLLECTION_TYPES.list) {
    params.endpoint = `/lists/${data.id}/items`;
  } else if (data.type === COLLECTION_TYPES.apps_list) {
    params.endpoint = `/apps`;
    enhanceFlags.updateMetadata = true;
  } else if (data.type === COLLECTION_TYPES.app_list) {
    params.endpoint = `/lists/${data.id}/items`;
  } else if (data.type === 'content') {
    params.endpoint = `/contents`;
    params.type = 'channel';
  } else if (data.type === COLLECTION_TYPES.watched_list) {
    params.endpoint = `/me/watcheds`;
    params.withAuthHeader = true;
  } else if (data.type === COLLECTION_TYPES.watchlist_list) {
    params.endpoint = `/me/watchlists`;
    params.withAuthHeader = true;
  } else if (data.type === COLLECTION_TYPES.dazn_list) {
    params.endpoint = `/dazn/contents`;
  } else if (data.type === COLLECTION_TYPES.rakuten_tv_list) {
    params.endpoint = `/rakuten_tv/recommended`;
  } else if (data.type === COLLECTION_TYPES.favorites_list) {
    params.endpoint = `/me/favorites`;
    params.withAuthHeader = true;
    params.type = 'channel';
  } else if (data.type === COLLECTION_TYPES.youtube_list) {
    params.endpoint = `/youtube/contents`;
  } else if (data.type === 'channel') {
    params.endpoint = `/contents`;
    params.type = 'channel_program';
  } else if (data.type === COLLECTION_TYPES.most_watched_channels_by_user) {
    params.endpoint = `/me/most_watched/channels`;
    params.type = 'channel';
    params.withAuthHeader = true;
  } else if (data.type === COLLECTION_TYPES.most_watched_channels_by_market) {
    params.endpoint = `/most_watched/channels`;
    params.type = 'channel';
  } else {
    params.endpoint = `/${data.type}s/${data.id}/contents`;
  }
  return { params, enhanceFlags };
};

export const DEVICE_LISTS: Array<string> = [
  COLLECTION_TYPES.netflix_list,
  COLLECTION_TYPES.prime_video_list,
  COLLECTION_TYPES.disney_list,
  COLLECTION_TYPES.freeview_list,
  COLLECTION_TYPES.bbc_list,
  COLLECTION_TYPES.all4_list,
  COLLECTION_TYPES.my5_list,
  COLLECTION_TYPES.itvhub_list
];

export const DEVICE_APP_NAMES: DefaultKeyStringMap = {
  netflix: 'netflix',
  PrimeVideo: 'PrimeVideo',
  disney: 'disney',
  youtube: 'youtube',
  fvp: 'fvp',
  bbciplayer: 'bbciplayer',
  all4: 'all4',
  my5: 'my5',
  itvhub: 'itvhub',
  bbcsounds: 'bbcsounds',
  britbox: 'britbox'
};

const DEVICE_LISTS_APP_NAME_MAP: DefaultKeyStringMap = {
  [COLLECTION_TYPES.netflix_list]: DEVICE_APP_NAMES.netflix,
  [COLLECTION_TYPES.prime_video_list]: DEVICE_APP_NAMES.PrimeVideo,
  [COLLECTION_TYPES.disney_list]: DEVICE_APP_NAMES.disney,
  [COLLECTION_TYPES.youtube_list]: DEVICE_APP_NAMES.youtube,
  [COLLECTION_TYPES.freeview_list]: DEVICE_APP_NAMES.fvp,
  [COLLECTION_TYPES.bbc_list]: DEVICE_APP_NAMES.bbciplayer,
  [COLLECTION_TYPES.all4_list]: DEVICE_APP_NAMES.all4,
  [COLLECTION_TYPES.my5_list]: DEVICE_APP_NAMES.my5,
  [COLLECTION_TYPES.itvhub_list]: DEVICE_APP_NAMES.itvhub
};

export const METADATA_APPS = [
  SPECIAL_APPS.bbc_sounds,
  SPECIAL_APPS.britbox,
  SPECIAL_APPS.freeview_explore,
  SPECIAL_APPS.bbc_iplayer,
  SPECIAL_APPS.all_4,
  SPECIAL_APPS.demand_5,
  SPECIAL_APPS.itv_hub,
  SPECIAL_APPS.bbc_sounds
];

export const fetchList = createAsyncThunk<
  { collection: any[]; pagination: any; title?: string | null },
  {
    data: {
      type: string;
    };
    listId: string;
    page?: number;
    perPage?: number;
    query?: string;
    e?: string;
  },
  { state: RootState }
>(
  'lists/fetchList',
  async (
    { data, listId, page = 1, perPage = 14, query, e },
    { rejectWithValue }
  ) => {
    const { params, enhanceFlags } = buildEndpointParams(data, listId);
    const { endpoint, type, withAuthHeader } = params;

    if (DEVICE_LISTS.includes(data.type)) {
      if (!!e && e === APP_ROW_UPDATES_CLEAR[data.type]) {
        return Promise.resolve({
          title: '',
          collection: [],
          pagination: {}
        });
      } else if (!e) {
        const appName =
          DEVICE_LISTS_APP_NAME_MAP[
            data.type as keyof typeof DEVICE_LISTS_APP_NAME_MAP
          ];
        const app = DEEPLINK_APPS[appName as keyof typeof DEEPLINK_APPS] || {};
        const { collection, title } = await fetchDeviceList(appName, app);

        return Promise.resolve({
          title,
          collection,
          pagination: {}
        });
      }
    }

    try {
      const res = await api({
        endpoint,
        withAuthHeader,
        queryParams: {
          type,
          page,
          per_page: perPage,
          q: query
        }
      });

      let collection = [];
      let pagination = {};

      if (res.collection) {
        collection = res.collection;
        pagination = res.pagination;
      } else {
        // TODO: Remove this once BE returns YouTube data in correct format
        collection = Object.values(res).filter((item) => !!item);
      }

      if (enhanceFlags.updateMetadata) {
        collection = await fetchDeviceFVPMetadata(collection);
      }
      return {
        collection: collection,
        pagination: convertKeysToCamelCase(pagination)
      };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export interface RemoveListsPayload {
  prefix: string;
}

export const removeListsByPrefix = createAction<RemoveListsPayload>(
  'lists/removeListsByPrefix'
);

export const addChannelToListFavorites = createAction<{ channel: IChannel }>(
  'lists/addChannelToListFavorites'
);

export const deleteChannelFromListFavorites = createAction<{
  channel: IChannel;
}>('lists/deleteChannelFromListFavorites');
