import { useEffect, useState } from 'react';

enum OSKBShowValue {
  Hidden = 0,
  Visible = 1
}

interface OSKBShowEvent extends Event {
  oskbshow: OSKBShowValue;
}

export function useOSKBVisibility() {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  useEffect(() => {
    const handleOSKBShowEvent = (event: OSKBShowEvent) => {
      setIsKeyboardVisible(Number(event.oskbshow) === OSKBShowValue.Visible);
    };

    document.addEventListener(
      'SendOSKBShow',
      handleOSKBShowEvent as EventListener
    );
    return () => {
      document.removeEventListener(
        'SendOSKBShow',
        handleOSKBShowEvent as EventListener
      );
    };
  }, []);

  return { isKeyboardVisible };
}
