import { useEffect, DependencyList } from 'react';

const useBackButton = (
  callback: CallableFunction,
  isActive: boolean,
  deps: DependencyList = []
) => {
  useEffect(() => {
    if (isActive) {
      const handleKeyDown = (e: KeyboardEvent) => {
        const { key } = e;

        if (key === 'Backspace') {
          callback(e);
        }
      };
      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isActive, ...deps]);

  return null;
};
export default useBackButton;
