const MARKETS = [
  'gb',
  'es',
  'li',
  'de',
  'it',
  'lu',
  'sm',
  'va',
  'al',
  'am',
  'at',
  'az',
  'bs',
  'be',
  'bg',
  'by',
  'ch',
  'cz',
  'dk',
  'ee',
  'fi',
  'fr',
  'ge',
  'gr',
  'hr',
  'hu',
  'ie',
  'kz',
  'lt',
  'lv',
  'me',
  'mk',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'rs',
  'ru',
  'se',
  'si',
  'sk',
  'tr',
  'ua'
];

export default MARKETS;
