import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isPaused: false,
  routes: {}
};

export interface FocusData {
  key: string;
}

export interface FocusState {
  isPaused: boolean;
  routes: {
    [route: string]: {
      default?: string;
      history: Array<string>;
    };
  };
}

export interface SaveFocusPayload {
  page: string;
  itemIndex?: number;
  listIndex?: number;
  focusKey?: string;
}

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: initialState as FocusState,
  reducers: {
    updateRouteDefault: (
      state,
      { payload }: PayloadAction<{ key: string; route: string }>
    ) => {
      const { key, route } = payload;
      const routePath = route.split('?')[0];

      state.routes[routePath] = state.routes[routePath] || {};
      state.routes[routePath].default = key;
      return state;
    },
    saveFocus: (
      state,
      { payload }: PayloadAction<{ key: string; route: string }>
    ) => {
      const { key, route } = payload;
      const routePath = route.split('?')[0];

      state.routes[routePath] = state.routes[routePath] || {};

      const history = state.routes[routePath]?.history?.slice() || [];

      if (history[history.length - 1] !== key) {
        history.length > 9 && history.shift();
        history.push(key);
        state.routes[routePath].history = history;
      }

      return state;
    }
  }
});

export const saveFocus = createAction<{ key: string; route: string }>(
  'navigation/saveFocus'
);

export const updateRouteDefault = createAction<{ key: string; route: string }>(
  'navigation/updateRouteDefault'
);

export default navigationSlice.reducer;
