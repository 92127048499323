export default {
  list: 'list',
  banner_list: 'banner_list',
  // TODO: Will we really have both apps_list and app_list?
  apps_list: 'apps_list',
  app_list: 'app_list',
  favorites_list: 'favorites_list',
  me_apps_list: 'me_apps_list',
  watchlist_list: 'watchlist_list',
  watched_list: 'watched_list',
  most_watched_channels_by_user: 'most_watched_channels_by_user_list',
  most_watched_channels_by_market: 'most_watched_channels_by_market_list',
  dazn_list: 'dazn_list',
  rakuten_tv_list: 'rakuten_tv_list',
  netflix_list: 'netflix_list',
  prime_video_list: 'prime_video_list',
  disney_list: 'disney_list',
  youtube_list: 'youtube_list',
  freeview_list: 'freeview_list',
  bbc_list: 'bbc_list',
  all4_list: 'all4_list',
  my5_list: 'my5_list',
  itvhub_list: 'itvhub_list',
  on_now_list: 'on_now_list'
} as const;
