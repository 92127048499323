const MODAL_TYPES: DefaultKeyStringMap = {
  DEEPLINK: 'DEEPLINK',
  IMAGE_FULL_SCREEN: 'IMAGE_FULL_SCREEN',
  THEME: 'THEME',
  ENVS: 'ENVS',
  MARKETS: 'MARKETS',
  VERSION: 'VERSION',
  DEV_ACTIONS: 'DEV_ACTIONS'
};

export default MODAL_TYPES;
