import ARTWORK_TYPES from 'constants/artwork-types';
import ARTWORK_SIZES from 'constants/artwork-sizes';
import scaleSize from 'utils/scale-size';

const TITLE_HEIGHT = scaleSize(62);
const MARGIN_BOTTOM = scaleSize(50);

const LIST_SIZES = {
  [ARTWORK_TYPES.SERVICE]: {
    titleHeight: TITLE_HEIGHT,
    marginBottom: MARGIN_BOTTOM,
    height: ARTWORK_SIZES[ARTWORK_TYPES.SERVICE].height
  },
  [ARTWORK_TYPES.LANDSCAPE]: {
    titleHeight: TITLE_HEIGHT,
    marginBottom: MARGIN_BOTTOM,
    height: ARTWORK_SIZES[ARTWORK_TYPES.LANDSCAPE].height
  },
  [ARTWORK_TYPES.GENRE]: {
    titleHeight: TITLE_HEIGHT,
    marginBottom: MARGIN_BOTTOM,
    height: ARTWORK_SIZES[ARTWORK_TYPES.GENRE].height
  },
  [ARTWORK_TYPES.LANDSCAPE_4_3]: {
    titleHeight: TITLE_HEIGHT,
    marginBottom: MARGIN_BOTTOM,
    height: ARTWORK_SIZES[ARTWORK_TYPES.LANDSCAPE_4_3].height
  },
  [ARTWORK_TYPES.LANDSCAPE_XL]: {
    titleHeight: TITLE_HEIGHT,
    marginBottom: MARGIN_BOTTOM,
    height: ARTWORK_SIZES[ARTWORK_TYPES.LANDSCAPE_XL].height
  },
  [ARTWORK_TYPES.LANDSCAPE_TPV]: {
    titleHeight: TITLE_HEIGHT,
    marginBottom: MARGIN_BOTTOM,
    height: ARTWORK_SIZES[ARTWORK_TYPES.LANDSCAPE_TPV].height
  },
  [ARTWORK_TYPES.SQUARE_TPV]: {
    titleHeight: TITLE_HEIGHT,
    marginBottom: MARGIN_BOTTOM,
    height: ARTWORK_SIZES[ARTWORK_TYPES.SQUARE_TPV].height
  },
  [ARTWORK_TYPES.PORTRAIT]: {
    titleHeight: TITLE_HEIGHT,
    marginBottom: MARGIN_BOTTOM,
    height: ARTWORK_SIZES[ARTWORK_TYPES.PORTRAIT].height
  },
  [ARTWORK_TYPES.NA]: {
    titleHeight: 0,
    marginBottom: 0,
    height: 0
  }
} as const;

export default LIST_SIZES;
