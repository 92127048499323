import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';

import packageJSON from '../../package.json';

const rollbarConfig = {
  enabled: process.env.ENVIRONMENT !== 'development',
  accessToken: process.env.ROLLBAR_ACCESS_TOKEN || '',
  environment: process.env.ENVIRONMENT || 'development',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        code_version: packageJSON.version,
        source_map_enabled: true
      }
    }
  }
};

export function ObservabilityProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </RollbarProvider>
  );
}
