import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import CONTENT_TYPES from 'constants/content-types';
import ContentDetails from 'components/Details/ContentDetails';
import AppDetails from 'components/Details/AppDetails';

const Details: FC = () => {
  const { contentId, type = CONTENT_TYPES.movie } = useParams<{
    type: CONTENT_TYPES;
    contentId: any;
  }>();

  const DetailsComponent =
    type === CONTENT_TYPES.app ? AppDetails : ContentDetails;

  return <DetailsComponent type={type} contentId={contentId} />;
};

export default Details;
