import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  hideLoader,
  setLoaderProgress,
  simulateLoaderProgress
} from 'store/features/loader/actions';
import { fetchGrid } from 'store/features/grids/actions';
import usePrevious from 'hooks/usePrevious';
import ListItem from 'components/List/Item';
import ARTWORK_SIZES from 'constants/artwork-sizes';
import CONTENT_TYPES from 'constants/content-types';
import CONTENT_TYPE_TO_ARTWORK_TYPE from 'constants/content-type-to-artwork-type';
import { FULL_WIDTH, marginSizes } from 'styles/base';
import {
  FocusContext,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import { GridInnerWrapper, GridWrapper } from './Grid.styles';

import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import useNavigation from 'hooks/useNavigation';
import styled from 'styled-components';

interface GridProps {
  type?: string;
  className?: string;
  showArtworkTitle?: boolean;
  preventScroll?: boolean;
  gridData?: any;
}

type RouteParams = {
  genreId?: string;
  listId?: string;
  channelId?: string;
  type?: string;
  [key: string]: string | undefined;
};

const calculateGridTranslate = (focusedIndex: number, type: string) => {
  const artworkType =
    CONTENT_TYPE_TO_ARTWORK_TYPE[
      CONTENT_TYPES[type as keyof typeof CONTENT_TYPES]
    ];

  const { width, height, marginRight, marginBottom } =
    ARTWORK_SIZES[artworkType];
  const perRow = Math.floor(
    (FULL_WIDTH - marginSizes.xxl) / (width + marginRight)
  );
  const currentRow = Math.floor(focusedIndex / perRow);

  return (height + marginBottom) * currentRow;
};

const GenreCardOverlay = styled.div`
  border-radius: 4px;
  opacity: 0.5;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Grid: FC<GridProps> = ({
  type,
  gridData,
  showArtworkTitle,
  className,
  preventScroll
}) => {
  const {
    genreId,
    listId,
    channelId,
    type: paramsType = CONTENT_TYPES.movie
  } = useParams<RouteParams>();
  const finalType = type || paramsType;
  const params = { genreId, listId, channelId };
  const key = `${finalType}-${JSON.stringify(params)}`;
  const grid = useAppSelector(
    (state: any) => gridData || state.grids[key] || { data: { collection: [] } }
  );
  const [focusedIndex, setFocusedIndex] = useState(0);
  const { ref, focusKey } = useFocusable();
  const { focusLast } = useNavigation({ defaultFocusKey: focusKey });

  const wasLoading = usePrevious(grid.isLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (wasLoading && !grid.isLoading) {
      dispatch(setLoaderProgress(100));
      dispatch(hideLoader());
      focusLast();
    }
  }, [grid]);

  useEffect(() => {
    dispatch(simulateLoaderProgress());
    dispatch(fetchGrid({ type: finalType, params }));
  }, [finalType]);

  useEffect(() => {
    if (focusedIndex !== 0) {
      const params = { genreId, listId, channelId, page: 0 };
      const {
        pagination: { nextPage = null, perPage = 50, currentPage = 1 }
      } = grid;

      if (focusedIndex > perPage * currentPage - perPage / 2 && nextPage) {
        params.page = nextPage;
        dispatch(fetchGrid({ type: finalType, params }));
      }
    }
  }, [focusedIndex]);

  if (!grid.data) {
    return null;
  }

  return (
    <GridWrapper ref={ref} className={className}>
      <FocusContext.Provider value={focusKey}>
        <GridInnerWrapper
          translateY={
            !preventScroll ? calculateGridTranslate(focusedIndex, finalType) : 0
          }
        >
          {grid?.data?.collection?.map((item: any, itemIndex: number) => {
            return (
                <ListItem
                  data={item}
                  showArtworkTitle={showArtworkTitle}
                  key={`grid-${finalType}-${item.id}`}
                  focusKey={`focusable-${finalType}-${item.id}`}
                  itemIndex={itemIndex}
                  onFocus={() => setFocusedIndex(itemIndex)}
                  hideMetadata
                  ArtworkOverlayComponent={() => <GenreCardOverlay />}
                ></ListItem>
            );
          })}
        </GridInnerWrapper>
      </FocusContext.Provider>
    </GridWrapper>
  );
};

export default Grid;
