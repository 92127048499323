import { useEffect } from 'react';
import {
  fetchEPGbyGenre,
  fetchChannelsGenres
} from 'store/features/channels/action';
import { useAppDispatch } from 'hooks/hooks';

//TODO: temporary solution to preload epg in the background
const usePreloadEPG = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    let isCancelled = false;
    const fetchGenresAndEPG = async () => {
      try {
        // @ts-ignore
        const genresResult = await dispatch(fetchChannelsGenres());
        if (
          genresResult.payload &&
          genresResult.payload.collection &&
          !isCancelled
        ) {
          for (let genre of genresResult.payload.collection) {
            await dispatch(fetchEPGbyGenre(genre.id));
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchGenresAndEPG();
    return () => {
      isCancelled = true;
    };
  }, [dispatch]);
};

export default usePreloadEPG;
