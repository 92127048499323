import React from 'react';
import theme from 'styles/theme';

type IconProps = {
  isFocused: boolean;
};

const SourcesIcon: React.FC<IconProps> = ({ isFocused }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M24 24C24.7931 24 25.5979 24 26.3337 24C27.9906 24 29.3333 22.6569 29.3333 21V8.33337C29.3333 6.67652 27.9902 5.33337 26.3333 5.33337H5.66663C4.00977 5.33337 2.66663 6.67652 2.66663 8.33337V21C2.66663 22.6569 4.00937 24 5.66622 24C6.40203 24 7.20686 24 7.99996 24"
          stroke={isFocused ? theme.colors.white : theme.colors.gray05}
          strokeWidth="3"
        />
        <path
          d="M10 30.6667C9.17595 30.6667 8.70557 29.7259 9.2 29.0667L15.2 21.0667C15.6 20.5333 16.4 20.5333 16.8 21.0667L22.8 29.0667C23.2944 29.7259 22.824 30.6667 22 30.6667H10Z"
          fill={isFocused ? theme.colors.white : theme.colors.gray05}
        />
      </g>
    </svg>
  );
};

export default SourcesIcon;
