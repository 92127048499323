import styled from 'styled-components';

export const Image = styled.img<{ src?: string }>`
  height: 100%;
  width: 100%;
`;

export const ImageDiv = styled.div<{ src?: string }>`
  height: 100%;
  width: 100%;
  background: ${({ theme, src }) =>
    `${theme.colors.gray07} url('${src}') no-repeat`};
  background-size: cover;
`;
