import { css } from 'styled-components';
import scaleSize from 'utils/scale-size';
import { marginSizes, sizes } from 'styles/base';
import BackIcon from './icons/BackIcon';
import ChevronIcon from './icons/ChevronIcon';
import ChevronIconDown from './icons/ChevronIconDown';
import CloseIcon from './icons/CloseIcon';
import EpgIcon from './icons/EpgIcon';
import EpgIconSideMenu from './icons/EpgIconSideMenu';
import HeartAddIcon from './icons/HeartAddIcon';
import HeartCrossedIcon from './icons/HeartCrossedIcon';
import HeartIcon from './icons/HeartIcon';
import InfoIcon from './icons/InfoIcon';
import SearchIcon from './icons/SearchIcon';
import SettingsIcon from './icons/SettingsIcon';
import SourceIcon from './icons/SourcesIcon';
import WatchTVIcon from './icons/WatchTVIcon';
import SpecialStarIcon from './icons/SpecialStar';

const icons = {
  BackIcon,
  ChevronIcon,
  ChevronIconDown,
  CloseIcon,
  EpgIcon,
  EpgIconSideMenu,
  HeartAddIcon,
  HeartCrossedIcon,
  HeartIcon,
  InfoIcon,
  SearchIcon,
  SettingsIcon,
  SourceIcon,
  WatchTVIcon,
  SpecialStarIcon
};

const colors = {
  primary: '#ffffff',
  primaryText: '#000000',
  secondaryText: '#000000',
  black: '#000000',
  black01: 'rgba(0, 0, 0, 0.3)',
  black02: 'rgba(0, 0, 0, 0.6)',
  black03: 'rgba(0, 0, 0, 0.9)',
  transparent: 'rgba(0,0,0,0)',
  gold: '#FFD600',
  red: '#ff0000',
  red01: '#F7414B',
  white: '#ffffff',
  white01: '#FDFDFD',
  white02: '#F0F0F0',
  white03: 'rgba(255, 255, 255, 0.3)',
  white06: 'rgba(255,255,255,0.6)',
  white04: 'rgba(255, 255, 255, 0.4)',
  white005: 'rgba(0, 0, 0, 0.05)',
  gray1: '#7B7B7B',
  gray2: 'rgba(217, 217, 217, 0.25)',
  grey3: '#333333',
  gray01: 'rgba(240, 240, 240, 0.10)',
  gray02: 'rgba(255, 255, 255, 0.20)',
  gray04: '#CACACA',
  gray05: 'rgba(255, 255, 255, 0.5)',
  gray07: 'rgba(255, 255, 255, 0.7)',
  gray08: 'rgba(255, 255, 255, 0.8)',
  blue: '#1C5385',
  lightBlue: '#337FBF'
};

const transitionTimes = {
  s: '200ms',
  l: '500ms',
  xl: '1000ms'
};

const fullScreen = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const buttonBorderRadius = scaleSize(8);

const gradientFrom = 'rgba(255, 255, 255, 0.25)';
const gradientTo = 'rgba(255, 255, 255, 0)';

const fonts = [
  {
    fontFamily: 'Silka',
    src: 'Silka-Regular.woff',
    type: 'woff',
    fontWeight: 400,
    fontStyle: 'normal'
  }
];

const globalStyles = css`
  ${fonts.map(
    (font) => css`
      @font-face {
        font-family: ${font.fontFamily};
        src: url('./assets/base/fonts/${font.src}') format('${font.type}');
        font-weight: ${font.fontWeight};
        font-style: ${font.fontStyle};
      }
    `
  )}
  body {
    font-family: ${fonts.map((font) => font.fontFamily).join(',')}, sans-serif;
  }
`;

const typography = {
  fm: css`
    font-family: 'Silka', sans-serif;
    font-weight: 500;
  `,
  fb: css`
    font-family: 'Silka', sans-serif;
    font-weight: 800;
  `,
  text: css`
    font-family: 'Silka', sans-serif;
    font-weight: 400;
    font-size: ${scaleSize(28)}px;
    color: ${colors.white};
  `,
  title: css`
    font-family: 'Silka', sans-serif;
    font-weight: 600;
    font-size: ${scaleSize(48)}px;
    color: ${colors.white02};
  `,
  button: css`
    font-family: 'Silka', sans-serif;
    font-weight: 300;
    font-size: ${scaleSize(32)}px;
  `,
  secondary_title: css`
    font-family: 'Silka', sans-serif;
    font-weight: 500;
    font-size: ${scaleSize(32)}px;
    line-height: ${scaleSize(48)}px;
    color: ${colors.white02};
  `,
  description: css`
    font-family: 'Silka', sans-serif;
    font-weight: 400;
    font-size: ${scaleSize(24)}px;
    color: ${colors.gray04};
  `,
  secondary_txt_medium: css`
    font-family: 'Silka', sans-serif;
    font-weight: 600;
    font-size: ${scaleSize(24)}px;
    color: ${colors.white};
  `,
  medium_header: css`
    font-family: 'Silka', sans-serif;
    font-weight: 500;
    font-size: ${scaleSize(64)}px;
    line-height: ${scaleSize(64)}px;
    color: ${colors.white};
  `
};

const theme = {
  sizes,
  colors,
  fonts,
  gradientFrom,
  gradientTo,
  globalStyles,
  icons,
  transitionTimes,
  marginSizes,
  fullScreen,
  typography,
  buttonBorderRadius
};

export default theme;
