import React from 'react';
import theme from 'styles/theme';
import scaleSize from 'utils/scale-size';

type IconProps = {
  isFocused: boolean;
};

const CustomIcon: React.FC<IconProps> = ({ isFocused }) => {
  const size = scaleSize(32);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_212_193)">
        <mask
          id="mask0_212_193"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="-1"
          y="-2"
          width="34"
          height="34"
        >
          <path
            d="M33 -2H-1V32H33V-2Z"
            fill={isFocused ? theme.colors.white : theme.colors.white06}
          />
        </mask>
        <g mask="url(#mask0_212_193)">
          <mask
            id="mask1_212_193"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-1"
            y="-2"
            width="34"
            height="34"
          >
            <path
              d="M33 -2H-1V32H33V-2Z"
              fill={isFocused ? theme.colors.white : theme.colors.white06}
            />
          </mask>
          <g mask="url(#mask1_212_193)">
            <path
              d="M22.1944 16.496V16.0391L1.125 16.1347V19.7366L22.5344 19.6516V16.496H22.1944Z"
              fill={isFocused ? theme.colors.white : theme.colors.white06}
            />
            <path
              d="M22.5344 23.075H1.125V26.6875H23.7138L23.7987 26.6237L22.5344 24.4987V23.075Z"
              fill={isFocused ? theme.colors.white : theme.colors.white06}
            />
            <path
              d="M26.4763 23.6594L26.6463 23.9463L26.8694 23.7656L26.4763 23.6594Z"
              fill={isFocused ? theme.colors.white : theme.colors.white06}
            />
            <path
              d="M23.1188 9.09888L1.125 9.18388V12.7964L22.1944 12.7008V10.0233L23.1188 9.09888Z"
              fill={isFocused ? theme.colors.white : theme.colors.white06}
            />
            <path
              d="M30.8644 6.28751H24.9037V8.96501H30.8644V6.28751Z"
              fill={isFocused ? theme.colors.white : theme.colors.white06}
            />
            <path
              d="M24.16 15.6163V23.9144L25.8175 26.6875H30.0463L31.5975 24.2863V15.6163H24.16Z"
              fill={isFocused ? theme.colors.white : theme.colors.white06}
            />
            <path
              d="M31.0025 9.94463H24.755L23.82 10.8796V14.5028H31.9375V10.8796L31.0025 9.94463Z"
              fill={isFocused ? theme.colors.white : theme.colors.white06}
            />
            <path
              d="M28.6225 3.3125H27.135V5.54375H28.6225V3.3125Z"
              fill={isFocused ? theme.colors.white : theme.colors.white06}
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_212_193">
          <rect
            width="34"
            height="34"
            fill={isFocused ? theme.colors.white : theme.colors.white06}
            transform="translate(-1 -2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomIcon;
