import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Focusable from 'components/Navigation/Focusable';
import { UseFocusableConfig } from '@noriginmedia/norigin-spatial-navigation';

interface ButtonProps {
  title: string;
  handleClick: () => void;
  focusKey: string;
  fullWidth?: boolean;
  isFocusable?: boolean;
  className?: string;
}

interface ButtonWrapperProps extends UseFocusableConfig {
  fullWidth?: boolean;
}

const ButtonStyles = css<ButtonWrapperProps>`
  ${({ theme }) => theme.paddings(['xs', 'l'])};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.gray02};
  ${({ theme }) => theme.typography.button};
  ${({ theme }) => theme.margin('s', 'right')};
  border-radius: ${({ theme }) => theme.buttonBorderRadius}px;
  display: inline-block;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  border: solid 2px transparent;

  &.focused {
    border: solid 2px black;
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.primary};
  }

  &.focused:not(.edit-mode) {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primaryText};
  }
`;

const FakeButton = styled.div`
  ${ButtonStyles}
`;

const ButtonWrapper = styled(Focusable)<ButtonWrapperProps>`
  ${ButtonStyles}
`;

const Button: FC<ButtonProps> = ({
  title,
  handleClick,
  focusKey,
  fullWidth,
  className,
  isFocusable = true
}) => {
  const ButtonComponent: React.ElementType = isFocusable
    ? ButtonWrapper
    : FakeButton;

  const extra = isFocusable ? { onEnterRelease: handleClick } : {};

  return (
    <ButtonComponent
      {...extra}
      focusKey={focusKey}
      fullWidth={fullWidth}
      className={className}
      //@ts-ignore
      isFocusable={isFocusable}
    >
      {title}
    </ButtonComponent>
  );
};

export default Button;
