import { configureStore } from '@reduxjs/toolkit';
// import logger from 'redux-logger';
import menuReducer from 'store/features/menu/menuSlice';
import pageReducer from 'store/features/pages/pagesSlice';
import bannersReducer from 'store/features/banners/bannersSlice';
import listsReducer from 'store/features/lists/listsSlice';
import loaderReducer from 'store/features/loader/loaderSlice';
import gridsReducer from 'store/features/grids/gridsSlice';
import detailsReducer from 'store/features/details/detailsSlice';
import focusesReducer from 'store/features/focuses/focusesSlice';
import authReducer from 'store/features/auth/authSlice';
import channelsReducer from 'store/features/channels/channelsSlice';
import genresSlice from 'store/features/genres/genresSlice';
import appsSlice from 'store/features/apps/appsSlice';
import modalsSlice from 'store/features/modals/modalsSlice';
import toastsSlice from 'store/features/toasts/toastsSlice';
import bottomBarSlice from 'store/features/bottomBar/bottomBarSlice';
import navigationSlice from 'store/features/navigation/navigationSlice';
import appSlice from 'store/features/app/appSlice';

export const store = configureStore({
  reducer: {
    app: appSlice,
    menu: menuReducer,
    pages: pageReducer,
    banners: bannersReducer,
    genres: genresSlice,
    lists: listsReducer,
    loader: loaderReducer,
    grids: gridsReducer,
    details: detailsReducer,
    focuses: focusesReducer,
    auth: authReducer,
    channels: channelsReducer,
    apps: appsSlice,
    modals: modalsSlice,
    bottomBar: bottomBarSlice,
    navigation: navigationSlice,
    toasts: toastsSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(), //.concat(logger)
  devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
