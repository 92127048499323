import { createSlice, CreateSliceOptions } from '@reduxjs/toolkit';
import { fetchPage } from 'store/features/pages/actions';

interface PageState {
  isLoading: boolean;
  isSuccess?: boolean;
  errorMessage?: string;
  data: {
    collection: any[];
  };
}

type PagesState = Record<string, PageState>;

const initialState: PagesState = {};

const pageOptions: CreateSliceOptions<PagesState, {}, 'pages'> = {
  name: 'pages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPage.pending, (state, { meta }) => {
        const { pageId } = meta.arg;
        state[pageId] = state[pageId] || {
          data: { collection: [] },
          isLoading: true
        };
      })
      .addCase(fetchPage.fulfilled, (state, { payload, meta }) => {
        const { pageId } = meta.arg;
        const pageState = state[pageId];
        pageState.isLoading = false;
        pageState.isSuccess = true;
        pageState.data = {
          ...payload,
          collection: payload.collection //.slice(0, 5)
        };
      })
      .addCase(fetchPage.rejected, (state, { payload, meta }) => {
        const { pageId } = meta.arg;
        const pageState = state[pageId];
        pageState.isLoading = false;
        pageState.isSuccess = false;
        pageState.errorMessage = payload as string;
      });
  }
};
const pagesSlice = createSlice(pageOptions);

export default pagesSlice.reducer;
