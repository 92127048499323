import React, { useEffect } from 'react';
import styled from 'styled-components';
import scaleSize from 'utils/scale-size';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { removeToast } from 'store/features/toasts/actions';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToastWrapper = styled.div`
  position: absolute;
  right: ${scaleSize(32)}px;
  bottom: ${scaleSize(32)}px;
  width: ${scaleSize(385)}px;
  height: ${scaleSize(144)}px;
  z-index: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${scaleSize(12)}px;
  border-radius: ${scaleSize(16)}px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #101010;
  box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.5);
`;

const ToastText = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.button};
  line-height: ${scaleSize(48)}px;
`;
const TIMEOUT = 2000;
const Toast: React.FC = () => {
  const toast = useAppSelector((state) => state.toasts.toast);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (toast.text) {
      const timer = setTimeout(() => {
        dispatch(removeToast());
      }, TIMEOUT);

      return () => clearTimeout(timer); // This will clear the timeout if the component is unmounted before 5 seconds
    }
  }, [toast.text, dispatch]);

  if (!toast.text) {
    return null;
  }

  return (
    <Wrapper>
      <ToastWrapper>
        <ToastText>{toast.text}</ToastText>
      </ToastWrapper>
    </Wrapper>
  );
};

export default Toast;
