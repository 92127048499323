import React from 'react';
import theme from 'styles/theme';
import scaleSize from 'utils/scale-size';

type IconProps = {
  isFocused: boolean;
};

const SourceIcon: React.FC<IconProps> = ({ isFocused }) => {
  const size = scaleSize(32);
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 32 32`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1281_4449"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <path
          d="M32 0H0V32H32V0Z"
          fill={isFocused ? theme.colors.white : theme.colors.white06}
        />
      </mask>
      <g mask="url(#mask0_1281_4449)">
        <path
          d="M0 5.5V13H8V9.41599C8 8.55899 8.584 8.24599 9.3 8.72299L16.671 13.635C16.8317 13.7153 16.9668 13.8388 17.0612 13.9915C17.1557 14.1443 17.2057 14.3204 17.2057 14.5C17.2057 14.6796 17.1557 14.8557 17.0612 15.0085C16.9668 15.1612 16.8317 15.2847 16.671 15.365L9.3 20.277C8.588 20.754 8 20.44 8 19.584V16H0V19.5C0.00316545 20.5599 0.425611 21.5755 1.17507 22.3249C1.92453 23.0744 2.94011 23.4968 4 23.5H28C29.0599 23.4968 30.0755 23.0744 30.8249 22.3249C31.5744 21.5755 31.9968 20.5599 32 19.5V5.5H0Z"
          fill={isFocused ? theme.colors.white : theme.colors.white06}
        />
        <path
          d="M11 26.5L11.859 25.315C12.0545 25.0755 12.2977 24.8793 12.5732 24.739C12.8486 24.5987 13.1503 24.5173 13.459 24.5H18.532C18.8407 24.5173 19.1424 24.5987 19.4178 24.739C19.6933 24.8793 19.9365 25.0755 20.132 25.315L21 26.5H11Z"
          fill={isFocused ? theme.colors.white : theme.colors.white06}
        />
      </g>
    </svg>
  );
};

export default SourceIcon;
