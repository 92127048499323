import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks/hooks';
import useNavigation from 'hooks/useNavigation';
import { getCurrentFocusKey } from '@noriginmedia/norigin-spatial-navigation';

const NavigationManager: React.FC = () => {
  const { pathname } = useLocation();
  const { history } = useAppSelector(
    (state) => state.navigation.routes?.[pathname] || { history: [] }
  );
  const { focusLast } = useNavigation({});

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Check if getCurrentFocusKey still exists in DOM and focus
      // previous history element or default if it does not
      if (!document.getElementById(getCurrentFocusKey())) {
        // console.log('==> Focused element does not exist:', getCurrentFocusKey());
        focusLast();
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, [history]);

  return null;
};

export default NavigationManager;
