import { useConnectedInputSources } from 'hooks/useConnectedInputSources';
import useTabVisibility from 'hooks/useTabVisibility';
import { createContext, useEffect } from 'react';

type Props = {
  children: React.ReactNode;
};

export const ConnectedSourcesContext = createContext<InputSource[]>([]);

export default function ConnectedInputSourcesProvider({ children }: Props) {
  const { connectedSources, refreshSources } = useConnectedInputSources();
  const { isTabVisible } = useTabVisibility();

  useEffect(() => {
    if (isTabVisible) {
      refreshSources();
    }
  }, [isTabVisible]);

  return (
    <ConnectedSourcesContext.Provider value={connectedSources}>
      {children}
    </ConnectedSourcesContext.Provider>
  );
}
