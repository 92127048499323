import scaleSize from 'utils/scale-size';

export const FULL_WIDTH = scaleSize(1920);
export const FULL_HEIGHT = scaleSize(1080);

export const BANNERS_HEIGHT = scaleSize(344);

export const marginSizes: DefaultKeyNumberMap = {
  xxs: scaleSize(8),
  xs: scaleSize(16),
  s: scaleSize(24),
  m: scaleSize(32),
  l: scaleSize(40),
  xl: scaleSize(60),
  xxl: scaleSize(84)
};

export const sizes = {
  fullWidth: `${FULL_WIDTH}px`,
  fullHeight: `${FULL_HEIGHT}px`
};
