import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import shaka from 'shaka-player/dist/shaka-player.ui';

interface ShakaPlayerProps {
  src: string;
  config?: any;
  chromeless?: boolean;
  autoPlay?: boolean;
  className?: string;
  poster?: string;
  width?: string;
  height?: string;
}

const ShakaPlayer: React.ForwardRefRenderFunction<any, ShakaPlayerProps> = (
  { src, config, chromeless, className, ...rest },
  ref
) => {
  const uiContainerRef = useRef(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<typeof shaka.Player>(null);
  const [ui, setUi] = useState<any>(null);

  function onErrorEvent(event: any) {
    onError(event.detail);
  }

  function onError(error: any) {
    console.error('Error code', error.code, 'object', error);
    if (playerRef.current) {
      playerRef.current
        .unload()
        .catch((e: Error) => console.error(e, 'cannot unload'));
    }
  }

  function destroyPlayer() {
      playerRef.current?.removeEventListener('error', onErrorEvent);
      playerRef.current?.unload();
      playerRef.current?.destroy().catch(onError);
      playerRef.current = null;
      return true;
  }

  useEffect(() => {
    if (playerRef.current) {
      return;
    }

    const player = new shaka.Player(videoRef.current);
    playerRef.current = player;
    player.addEventListener('error', onErrorEvent);

    document.addEventListener('beforeunload', destroyPlayer);

    return () => {
      document.removeEventListener('beforeunload', destroyPlayer);
    };
  }, []);

  useEffect(() => {
    if (playerRef.current && config) {
      playerRef.current.configure(config).catch(onError);
    }
  }, [config]);

  useEffect(() => {
    if (playerRef.current && src) {
      playerRef.current.load(src).catch(onError);
    }
  }, [src]);

  useImperativeHandle(
    ref,
    () => ({
      get player() {
        return playerRef.current;
      },
      get ui() {
        return ui;
      },
      get videoElement() {
        return videoRef.current;
      }
    }),
    [ui]
  );

  return (
    <div ref={uiContainerRef} className={className}>
      <video
        ref={videoRef}
        style={{ maxWidth: '100%', width: '100%' }}
        {...rest}
      />
    </div>
  );
};

export default forwardRef(ShakaPlayer);
