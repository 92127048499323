import axios, { Method } from 'axios';
import Current from 'utils/current';
import Local from 'utils/storage/local';

// TODO: Would be a good move to have an env variable for API version
const API_VERSION = 'v1';
const API_URL =
  !!process.env.REACT_APP_API_URL?.trim() ? `${process.env.REACT_APP_API_URL}/${API_VERSION}` :
  `${window.location.protocol}//${window.location.hostname}/${API_VERSION}`;

interface QueryParams {
  [key: string]:
    | string
    | string[]
    | number
    | number[]
    | boolean
    | boolean[]
    | undefined;
}

interface RequestOptions {
  endpoint: string;
  queryParams?: QueryParams;
  method?: Method;
  data?: any;
  withAuthHeader?: boolean;
}

const getQueryParams = (queryParams: QueryParams) => {
  const urlParams = new URLSearchParams();
  Object.entries(queryParams).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => urlParams.append(`${key}[]`, String(item)));
    } else {
      urlParams.append(key, String(value));
    }
  });
  return urlParams;
};

const filterParams = (params: QueryParams = {}) => {
  return Object.fromEntries(
    Object.entries(params).filter(([, value]) => value)
  ) as QueryParams;
};

const request = ({
  endpoint,
  queryParams,
  method = 'get',
  withAuthHeader = false,
  data
}: RequestOptions) => {
  const params = {
    market: Current.market(),
    device: Current.device(),
    locale: Current.locale(),
    ...filterParams(queryParams)
  };

  let url = `${API_URL}${endpoint}?${getQueryParams(params)}`;


  const authHeader = withAuthHeader ? { Authorization: Local.getToken() } : {};

  return axios({
    method: method,
    url,
    headers: {
      Accept: 'application/json',
      ...authHeader
    },
    data
  }).then((response) => {
    return {
      authToken: response.headers.authorization,
      ...response.data
    };
  });
};

export default request;
