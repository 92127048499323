import { setFocus } from '@noriginmedia/norigin-spatial-navigation';
import Page from 'components/Page/Page';
import FOCUS_KEYS from 'constants/focus-keys';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import useBackButton from 'hooks/useBackButton';
import useTabVisibility from 'hooks/useTabVisibility';
import { useEffect } from 'react';
import { removeModal } from 'store/features/modals/actions';

const Home = () => {
  const dispatch = useAppDispatch();

  function backHandler(e: KeyboardEvent) {
    e.preventDefault();
    setFocus(FOCUS_KEYS.watchTv);
  }

  useBackButton(backHandler, true);

  const { isTabVisible } = useTabVisibility();

  useEffect(() => {
    if (!isTabVisible) {
      dispatch(removeModal());
      setFocus(FOCUS_KEYS.watchTv);
    }
  }, [isTabVisible]);

  const homePageId = useAppSelector((state) => {
    const homePage = state.menu.data.collection.find(
      (item) => item.position === 1
    );
    return homePage?.id;
  });

  if (homePageId) {
    return <Page propsPageId={homePageId} />;
  } else {
    return null;
  }
}

export default Home;
