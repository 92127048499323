import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Home from 'components/Home/Home';
import Grid from 'components/Grid/Grid';
import Page from 'components/Page/Page';
import Menu, { MenuState } from 'components/Menu/Menu';
import Details from 'components/Details/Details';
import Search from 'components/Search/Search';
import Channel from 'components/Channels/Channel';
import BottomBar from 'components/BottomBar/BottomBar';
import NavigationManager from 'components/Navigation/NavigationManager';
import Modal from 'components/Modals/Modal';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { setFocus } from '@noriginmedia/norigin-spatial-navigation';
import FOCUS_KEYS from 'constants/focus-keys';
import { removeModal } from 'store/features/modals/actions';

enum KeyStrings {
  HOME_KEY = 'HomeKey',
  APPS_KEY = 'AppsKey',
  CHANNELS_KEY = 'ChannelsKey',
  SEARCH_KEY = 'SearchKey'
}

const NavigationHandler = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const menu = useAppSelector(
    (state: { menu: MenuState }) => state.menu.data.collection
  );
  const appsId = menu.length > 0 ? menu[menu.length - 1].id : null;

  useEffect(() => {
    const handleNavigate = (e: any) => {
      dispatch(removeModal());

      switch (e.keyString) {
        case KeyStrings.HOME_KEY:
          navigate('/');
          setFocus(FOCUS_KEYS.watchTv);
          break;
        case KeyStrings.APPS_KEY:
          navigate(`/page/${appsId}`);
          break;
        case KeyStrings.CHANNELS_KEY:
          navigate('/channels');
          break;
        case KeyStrings.SEARCH_KEY:
          navigate('/search');
          break;
        default:
          navigate('/');
          break;
      }
    };

    document.addEventListener('customkeydown', handleNavigate);
    return () => {
      document.removeEventListener('customkeydown', handleNavigate);
    };
  }, [navigate, appsId]);

  return null;
};

const Router = () => (
  <BrowserRouter>
    <Menu />
    <NavigationHandler />
    <NavigationManager />
    <Modal />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/page/:pageId" element={<Page />} />
      <Route path="/movies" element={<Grid type="movie" />} />
      <Route path="/tv_shows" element={<Grid type="tv_show" />} />
      <Route path="/search" element={<Search />} />

      <Route path="/channels" element={<Channel />} />
      <Route path="/channels/:channelId" element={<Channel />} />

      <Route path="/sport_programs" element={<Grid type="sport_program" />} />
      <Route path="/details/:type/:contentId" element={<Details />} />

      <Route path="/genres/:genreId/:type" element={<Grid />} />
      <Route
        path="/channels/:channelId/channel_programs"
        element={<Grid type="channel_program" />}
      />
      <Route path="/lists/:listId/movies" element={<Grid type="movie" />} />
      <Route path="/lists/:listId/tv_shows" element={<Grid type="tv_show" />} />
      <Route
        path="/lists/:listId/sport_programs"
        element={<Grid type="sport_program" />}
      />
    </Routes>
    <BottomBar />
  </BrowserRouter>
);

export default Router;
