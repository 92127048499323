import CONTENT_TYPES from 'constants/content-types';
import { METADATA_APPS } from 'store/features/lists/actions';
import SPECIAL_APPS from 'constants/special-apps';

import onnow from './mocks/fvp_now_on.json';
import primeVideoList from './mocks/primeVideo.json';
import netflixList from './mocks/netflix.json';
import tpvApps from './mocks/tpv-apps.json';
import itvhub from './mocks/itvhub.json';
import my5 from './mocks/my5.json';

// Mock API for TVs that don't have it enabled.
if (typeof window.getDeviceContent === 'undefined') {
  window.getDeviceContent = (appName: string, IsRecommendation: boolean) => {
    return '{}';
    // Uncomment to use mocks
    //  if (appName === 'netflix') {
    //  return JSON.stringify(netflixList);
    //  }
    //  else if (appName === 'PrimeVideo') {
    //  return JSON.stringify(primeVideoList);
    //  } else if (appName === 'itvhub') {
    //   return JSON.stringify(itvhub);
    //   } else if(appName === 'fvp') {
    //  return JSON.stringify(tpvApps);
    //  } else if (appName === 'onnow') {
    //    return JSON.stringify(onnow);
    //  }
    //  else {
    //  return '{}';
    //   }
  };
}

function transformList(tpvList: Array<any>, app: App): any {
  const title =
    tpvList.find((tpvItem) => tpvItem.movieGroup === 'Profile')
      ?.movieDescription || null;

  const collection = tpvList
    .filter((tpvItem) => {
      return tpvItem.movieGroup !== 'Profile';
    })
    .map((tpvItem) => {
      return {
        type: CONTENT_TYPES.tpv_list_item,
        group: tpvItem.movieGroup || null,
        title: tpvItem.movieTitle,
        synopsis: tpvItem.movieDescription,
        duration_in_seconds: tpvItem.movieDuration,
        deeplinkings: [
          {
            app,
            url: tpvItem.movieLandingURL
          }
        ],
        images: {
          artwork_landscape: tpvItem.movieCoverImageURI,
          disableResize: true
        }
      };
    });

  return {
    collection,
    // TODO: use dictionary?
    title: title ? `for ${title}` : null
  };
}

export function fetchDeviceList(
  appName: string,
  app: any
): Promise<{ collection: any; title: string | null }> {
  return new Promise((resolve, reject) => {
    console.log(`==> [${appName}]: fetchDeviceList`);

    try {
      const tpvList = JSON.parse(window.getDeviceContent(appName, true));
      resolve(transformList(tpvList?.appInfo || [], app));
    } catch (error) {
      console.log('==> Error fetching content from device');
      reject();
    }
  });
}

export function fetchDeviceMetadata(collection: any): Promise<any> {
  const promises = collection.map((item: any) => {
    if (METADATA_APPS.includes(item.code)) {
      return new Promise((resolve, reject) => {
        try {
          const updatedValues: { name?: string; url?: string } = {};
          const content = window.getDeviceContent(item, false);
          const { appToUpdate } = JSON.parse(content);
          const { url, appTitle } = appToUpdate;
          if (url) {
            updatedValues.url = url;
            updatedValues.name = appTitle;
          }
          resolve({
            ...item,
            ...updatedValues
          });
        } catch (e) {
          console.log('error to update', e, item);
          resolve(item);
        }
      });
    } else {
      return Promise.resolve(item);
    }
  });
  return Promise.all(promises);
}

interface CollectionItem {
  id?: string | number;
  code: string;
  name?: string;
  url?: string;
  icon?: string;
}

export const DEVICE_FVP_APP_NAMES: DefaultKeyStringMap = {
  [SPECIAL_APPS.freeview_explore]: 'Freeview Explore',
  [SPECIAL_APPS.bbc_iplayer]: 'BBC iPlayer',
  [SPECIAL_APPS.all_4]: 'Channel 4',
  [SPECIAL_APPS.demand_5]: 'My5',
  [SPECIAL_APPS.itv_hub]: 'ITVX',
  [SPECIAL_APPS.bbc_sounds]: 'BBC Sounds',
  [SPECIAL_APPS.britbox]: 'britbox'
};

type EpgItem = {
  channelName: string;
  channelNumber: string;
  previewImage: string;
  previewTitleImage: string;
  isProgramImageAvailable: string;
  endTime: string;
  startTime: string;
};

function transformEpgList(epgList: Array<EpgItem[]>): any {
  return epgList.map((epgItem) => {
    return {
      id: epgItem[0].channelNumber,
      type: CONTENT_TYPES.tpv_list_item,
      title: epgItem[0].channelName,
      action: 'tune-tv-channel',
      channelNumber: epgItem[0].channelNumber,
      isProgramImageAvailable: !!Number(epgItem[0].isProgramImageAvailable),
      images: {
        artwork_landscape: epgItem[0].previewImage,
        artwork_landscape_focused: epgItem[0].previewTitleImage,
        disableResize: true
      }
    };
  });
}

type EpgData = {
  id: string;
  name: string;
  type: string;
  data: {
    collection: Array<any>;
  };
};
export function getOnTVList(): EpgData {
  const defaultResult = {
    id: 'on_now',
    name: 'On Now',
    type: 'on_now',
    data: { collection: [] }
  };
  try {
    const content = window.getDeviceContent('onnow', true);
    const onNow = JSON.parse(content);

    return {
      ...defaultResult,
      data: {
        collection: transformEpgList(onNow?.epgList || [])
      }
    };
  } catch (e) {
    console.log('==> [Error]: fetchOnTVList', e);
    return defaultResult;
  }
}

export function getTPVApps() {
  const content = window.getDeviceContent('fvp', false);
  const { appInfo = [] } =
    typeof content === 'string'
      ? (JSON.parse(content) as { appInfo: MetadataApp[] })
      : {};

  return appInfo;
}

export async function fetchDeviceFVPMetadata(
  collection: CollectionItem[]
): Promise<CollectionItem[]> {
  // All the apps hosted on the FVP portal are obtained through this request.
  const tvApps: any = getTPVApps();

  return collection.map((item: CollectionItem) => {
    if (METADATA_APPS.includes(item.code)) {
      try {
        const metadataItem = tvApps.find(
          (i: any) => i.appTitle === DEVICE_FVP_APP_NAMES[item.code]
        );
        if (!metadataItem) {
          return item;
        }
        const { appTitle, appLandingURL, appImageURI } = metadataItem;
        const updatedValues: CollectionItem = {
          code: item.code,
          url: appLandingURL,
          name: appTitle ?? item.name,
          icon: appImageURI
        };

        return {
          ...item,
          ...updatedValues
        };
      } catch (e) {
        console.error(e);
        return item;
      }
    }
    return item;
  });
}
