import React from 'react';
import scaleSize from 'utils/scale-size';

type IconProps = {
  isFocused?: boolean;
  className?: string;
  color?: string;
};

const CloseIcon: React.FC<IconProps> = ({ isFocused, className, color }) => {
  return (
    <svg
      width={scaleSize(32)}
      height={scaleSize(32)}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 8L8 24"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8L24 24"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseIcon;
