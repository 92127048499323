import CONTENT_TYPES from 'constants/content-types';
import CONTENT_TYPE_TO_ARTWORK_TYPE from 'constants/content-type-to-artwork-type';
import ARTWORK_SIZES from 'constants/artwork-sizes';

const calculateListTranslate = (items: any, focusedIndex: number) => {
  const previousItems = items.slice(0, focusedIndex);

  return previousItems.reduce(
    (accumulator: number, item: { type: CONTENT_TYPES }) => {
      const artworkType =
        CONTENT_TYPE_TO_ARTWORK_TYPE[CONTENT_TYPES[item.type]];
      const { width, marginRight } = ARTWORK_SIZES[artworkType];
      return accumulator + width + marginRight;
    },
    0
  );
};

export default calculateListTranslate;
