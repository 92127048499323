import CONTENT_TYPES from 'constants/content-types';
import ARTWORK_TYPES from 'constants/artwork-types';

const contentToArtworkType: Record<CONTENT_TYPES, ARTWORK_TYPES> = {
  [CONTENT_TYPES.tv_show]: ARTWORK_TYPES.LANDSCAPE_4_3,
  [CONTENT_TYPES.movie]: ARTWORK_TYPES.PORTRAIT,
  [CONTENT_TYPES.season]: ARTWORK_TYPES.PORTRAIT,
  [CONTENT_TYPES.sport_program]: ARTWORK_TYPES.LANDSCAPE,
  [CONTENT_TYPES.genre]: ARTWORK_TYPES.GENRE,
  [CONTENT_TYPES.youtube_video]: ARTWORK_TYPES.LANDSCAPE_4_3,
  [CONTENT_TYPES.youtube_channel]: ARTWORK_TYPES.SQUARE_TPV,
  [CONTENT_TYPES.channel_program]: ARTWORK_TYPES.LANDSCAPE_XL,
  [CONTENT_TYPES.tpv_list_item]: ARTWORK_TYPES.LANDSCAPE_TPV,
  [CONTENT_TYPES.tpv_list_profile]: ARTWORK_TYPES.SQUARE_TPV,
  [CONTENT_TYPES.channel]: ARTWORK_TYPES.PORTRAIT,
  [CONTENT_TYPES.episode]: ARTWORK_TYPES.PORTRAIT,
  [CONTENT_TYPES.service]: ARTWORK_TYPES.SERVICE,
  [CONTENT_TYPES.app]: ARTWORK_TYPES.SERVICE
};

export default contentToArtworkType;
