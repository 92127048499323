import { useLocation } from 'react-router-dom';

const usePathname = () => {
  const { pathname, search } = useLocation();

  return {
    pathname: `${pathname}${search}`
  };
};
export default usePathname;
