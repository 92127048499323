import styled from 'styled-components';
import scaleSize from 'utils/scale-size';

export const ChannelNumber = styled.div`
  position: absolute;
  top: ${scaleSize(18)}px;
  right: ${scaleSize(8)}px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${scaleSize(2)}px;
  height: ${scaleSize(10)}px;
  padding: ${scaleSize(2)}px ${scaleSize(5)}px;
  font-size: ${scaleSize(10.5)}px;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 0px 31px 0px rgba(0, 0, 0, 0.26);
`;

export const OnNowWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: 0; //calc(-100% - ${scaleSize(120)}px);
  left: 0;
  z-index: -1;
  opacity: ${({ isVisible }) => +isVisible};
  transform: translateY(${({ isVisible }) => (isVisible ? 130 : 0)}%);
  ${({ theme }) =>
    theme.transition([
      {
        value: 'transform',
        timing: theme.timings.l,
        easing: theme.easings.ease
      },
      {
        value: 'opacity',
        timing: 150,
        easing: theme.easings.ease
      }
    ])};
  transition-delay: 60ms, 200ms;
  ${({ theme }) => theme.margin('-xxl', 'left')};
`;
