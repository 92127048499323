import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';

import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import useUserInteractions from 'hooks/useUserInteractions';
import useInterval from 'hooks/useInterval';

import { addChannelToWatched } from 'store/features/channels/action';
import { hideMenu, showMenu } from 'store/features/menu/menuSlice';
import { setCurrentChannelId } from 'store/features/channels/channelsSlice';

import ChannelItem from './components/ChannelItem/ChannelItem';
import Container from './components/EPG/Container';
import VideoPlayer from 'components/VideoPlayer';

import {
  HIDE_CHANNEL_ITEM_TIMEOUT,
  HIDE_EPG_TIMEOUT,
  SWITCH_CHANNEL_MAX_WAIT_TIMEOUT,
  SWITCH_CHANNEL_TIMEOUT
} from 'constants/timeout-constants';

import { Channel as IChannel } from 'store/types';
import {
  hideBottomBar,
  showBottomBar
} from 'store/features/bottomBar/bottomBarSlice';
import useFetchData from './hooks/useFetchData';
import usePreloadEPG from './hooks/usePreloadEPG';
import { Direction } from 'constants/rc-direction';
import useBackButton from 'hooks/useBackButton';

const KEY_CODES = {
  ARROW_UP: ['ArrowUp', 'PageUp', 'Up'],
  ARROW_DOWN: ['ArrowDown', 'PageDown', 'Down']
};

const Channel = () => {
  const { channelId = '' } = useParams();

  const channels = useAppSelector((state) => state.channels?.channels);
  const lastChannelId = useAppSelector(
    (state) => state.channels?.currentChannelId
  );
  const idFromProgram = useAppSelector(
    (state) => state.channels?.channelIdFromProgram
  );

  const dispatch = useAppDispatch();

  const [isChannelItemVisible, setChannelItemVisible] = useState(true);
  const [isProgramVisible, setIsProgramVisible] = useState(false);
  const [currentChannel, setCurrentChannel] = useState<IChannel | null>(null);
  const [currentChannelIndex, setCurrentChannelIndex] = useState<number>(0);
  const [isChannelItemFocused, setChannelItemFocused] = useState(true);

  const channelsRef = useRef(channels);
  channelsRef.current = channels;

  useFetchData();
  usePreloadEPG();

  const backHandler = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      if (isProgramVisible) {
        setChannelItemFocused(true);
        setIsProgramVisible(false);
      } else {
        window.close();
      }
    },
    [isProgramVisible]
  );

  useBackButton(backHandler, true, [isProgramVisible]);

  useUserInteractions(() => {
    if (!isProgramVisible) {
      !isChannelItemVisible && setChannelItemVisible(true);
      resetChannelItemTimeout();
    }
  });

  useEffect(() => {
    const newChannel = channels.find((ch) => ch.id === Number(idFromProgram));
    newChannel && setCurrentChannel(newChannel);
    const index = channels.findIndex(
      (channel) => channel.id === newChannel?.id
    );
    setCurrentChannelIndex(index);
    idFromProgram && setCurrentChannelId({ channelId: idFromProgram });
  }, [idFromProgram]);

  useEffect(() => {
    if (channels.length === 0 || idFromProgram) {
      return;
    }

    const currentChannelId =
      channelId ||
      lastChannelId ||
      channels.find((i) => i.streamInfo.streamUrl)?.id;
    const newChannel = channels.find((ch) => ch.id == currentChannelId);

    if (!newChannel) {
      setCurrentChannel(channels[0]);
      setCurrentChannelIndex(0);
      return;
    }

    if (newChannel.id !== currentChannel?.id) {
      dispatch(
        setCurrentChannelId({ channelId: currentChannelId!.toString() })
      );
      setCurrentChannel(newChannel);
      setCurrentChannelIndex(channels.indexOf(newChannel));
    }
  }, [channels]);

  useEffect(() => {
    if (!isProgramVisible && isChannelItemFocused) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [channels, currentChannelIndex, isProgramVisible, isChannelItemFocused]);

  useEffect(() => {
    dispatch(hideMenu({ hintVisible: false }));
    dispatch(hideBottomBar());
    return () => {
      dispatch(showMenu());
      dispatch(showBottomBar());
    };
  }, []);

  const onSaveToWatched = (channelId: number, duration: number) => {
    dispatch(addChannelToWatched({ channelId, duration }));
  };

  const { reset: resetChannelItemTimeout } = useInterval(() => {
    setChannelItemVisible(false);
  }, HIDE_CHANNEL_ITEM_TIMEOUT);

  const { reset: resetProgramTimeout } = useInterval(() => {
    setChannelItemVisible(false);
  }, HIDE_EPG_TIMEOUT);

  const setChannel = useRef(
    debounce(
      async (currentChannelIndex) => {
        const newChannel = channelsRef.current[currentChannelIndex];

        if (newChannel) {
          await dispatch(
            setCurrentChannelId({ channelId: newChannel.id.toString() })
          );
          await setCurrentChannel(newChannel);
        }
      },
      SWITCH_CHANNEL_TIMEOUT,
      {
        leading: true,
        maxWait: SWITCH_CHANNEL_MAX_WAIT_TIMEOUT
      }
    )
  ).current;

  const getNextChannelIndex = (direction: Direction) => {
    if (direction === Direction.UP) {
      return currentChannelIndex === channels.length - 1
        ? 0
        : currentChannelIndex + 1;
    }
    return currentChannelIndex === 0
      ? channels.length - 1
      : currentChannelIndex - 1;
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const { key } = event;

    if (KEY_CODES.ARROW_UP.includes(key)) {
      const newIndex = getNextChannelIndex(Direction.UP);
      setCurrentChannelIndex(newIndex);
      setChannel(newIndex);
    } else if (KEY_CODES.ARROW_DOWN.includes(key)) {
      const newIndex = getNextChannelIndex(Direction.DOWN);
      setCurrentChannelIndex(newIndex);
      setChannel(newIndex);
    }
  };

  const onFocusChannelItem = () => {
    !isProgramVisible && resetChannelItemTimeout();
    !isProgramVisible && setChannelItemFocused(true);
  };

  const onFocusChannelMenu = () => {
    !isProgramVisible && resetChannelItemTimeout();
  };

  const onFocusProgram = () => {
    resetProgramTimeout();
  };

  const openProgram = () => {
    resetProgramTimeout();
    setIsProgramVisible(true);
    setChannelItemFocused(false);
    setChannelItemVisible(false);
  };

  return (
    <>
      {currentChannel && (
        <>
          <VideoPlayer
            onSaveToWatched={onSaveToWatched}
            channelId={currentChannel?.id}
            stream={{
              imgSrc:
                currentChannel?.images?.artworkPortrait ?? 'defaultImageUrl',
              ...currentChannel?.streamInfo
            }}
          />
          <ChannelItem
            isVisible={isChannelItemVisible}
            onFocusChannelItem={onFocusChannelItem}
            onFocusChannelMenu={onFocusChannelMenu}
            onBlur={() => setChannelItemFocused(false)}
            currentChannelIndex={currentChannelIndex}
            openProgram={openProgram}
          />
          {channels && isProgramVisible ? (
            <Container
              onClose={() => {
                setChannelItemFocused(true);
                setIsProgramVisible(false);
              }}
              isVisible={isProgramVisible}
              onFocus={onFocusProgram}
            />
          ) : null}
        </>
      )}
    </>
  );
};
export default Channel;
