import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store/configure-store';
import api from 'api/api';

interface BannerDataType {
  data?: {
    collection: any[]; // TODO: replace 'any' with exact type of collection's items
  };
}

export const fetchBanners = createAsyncThunk<
  BannerDataType,
  { id: string; prefix: string },
  { state: RootState }
>('banners/fetchBanners', async ({ id, prefix }, { rejectWithValue }) => {
  try {
    return await api({
      endpoint: `/lists/${id}/items`,
      queryParams: {}
    });
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.errors[0] || error.message);
  }
});
