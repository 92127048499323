const AUTH_TOKEN = 'auth_token';
const USER_ID = 'user_id';
const USER_EMAIL = 'user_email';
const DEVICE_UUID = 'device_uuid';
const THEME = 'theme';
const MARKET = 'market';
const LANGUAGE = 'language';
const CHANNEL_ID = 'channel_id';
const ANIMATIONS = 'animations';
const FVP_APP_POSITIONS_REPORTED = 'fvp_app_positions_reported';
const FVP_APP_POSITIONS = 'fvp_app_positions';

export default class Local {
  static authenticated() {
    return Local.getToken() != null;
  }

  static setToken(authToken?: string) {
    authToken && localStorage.setItem(AUTH_TOKEN, authToken);
  }

  static getToken() {
    return localStorage.getItem(AUTH_TOKEN);
  }

  static removeToken() {
    return localStorage.removeItem(AUTH_TOKEN);
  }

  static setLastWatchedChannelId(channelId: string) {
    localStorage.setItem(CHANNEL_ID, channelId);
  }

  static getLastWatchedChannelId() {
    return localStorage.getItem(CHANNEL_ID);
  }

  static deleteAuthenticatedUser() {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(USER_EMAIL);
  }

  static setUserId(userId: number) {
    localStorage.setItem(USER_ID, String(userId));
  }

  static getUserId() {
    return localStorage.getItem(USER_ID);
  }

  static setUserEmail(email: string) {
    localStorage.setItem(USER_EMAIL, email);
  }

  static getUserEmail() {
    return localStorage.getItem(USER_EMAIL);
  }

  static setDeviceUUID(deviceUUID: string) {
    localStorage.setItem(DEVICE_UUID, deviceUUID);
  }

  static getDeviceUUID() {
    return localStorage.getItem(DEVICE_UUID);
  }

  static getTheme() {
    return localStorage.getItem(THEME);
  }

  static setTheme(theme: string) {
    return localStorage.setItem(THEME, theme);
  }

  static getMarket() {
    return localStorage.getItem(MARKET);
  }

  static setMarket(market: string) {
    return localStorage.setItem(MARKET, market);
  }

  static getLanguage() {
    return localStorage.getItem(LANGUAGE);
  }

  static setLanguage(language: string) {
    return localStorage.setItem(LANGUAGE, language);
  }

  static getAnimations() {
    return localStorage.getItem(ANIMATIONS);
  }

  static setAnimations(enabled: boolean) {
    if (enabled) {
      return localStorage.setItem(ANIMATIONS, 'true');
    }

    return localStorage.removeItem(ANIMATIONS);
  }

  static getPositionsReported() {
    return localStorage.getItem(FVP_APP_POSITIONS_REPORTED);
  }

  static setPositionsReported() {
    return localStorage.setItem(FVP_APP_POSITIONS_REPORTED, 'true');
  }

  static getFvpPositions() {
    const positions = localStorage.getItem(FVP_APP_POSITIONS);
    return positions ? JSON.parse(positions) : [];
  }

  static setFvpPositions(
    positions: Array<{ code: string; app_id: null; position: number }>
  ) {
    return localStorage.setItem(FVP_APP_POSITIONS, JSON.stringify(positions));
  }

  static printAll() {
    console.log('AuthToken: ', this.getToken());
    console.log('user_id: ', this.getUserId());
    console.log('user_email: ', this.getUserEmail());
    console.log('device_uuid: ', this.getDeviceUUID());
  }
}
