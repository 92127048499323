import styled, { css, keyframes } from 'styled-components';
import Focusable from 'components/Navigation/Focusable';
import { marginSizes } from 'styles/base';

const slideIn = keyframes`
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
`;

const slideOut = keyframes`
  from {
    bottom: 0;
  }
  to {
    bottom: -100%;
  }
`;

export const ChannelProgramWrapper = styled(Focusable)<{ isVisible: boolean }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  border-top-left-radius: ${marginSizes.xs}px;
  border-top-right-radius: ${marginSizes.xs}px;
  position: absolute;
  height: 90%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  ${({ theme }) => theme.margin('m', 'right')};
  ${({ theme }) => theme.margin('m', 'left')};
  background-color: ${({ theme }) => theme.colors.black};
  animation: ${({ isVisible }) => (isVisible ? slideIn : slideOut)} 0.5s
    ease-out;
`;
