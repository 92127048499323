import Local from 'utils/storage/local';
import titan from 'styles/themes/titan/titan';
import tpv from 'styles/themes/tpv/tpv';
import aoc from 'styles/themes/aoc/aoc';
import base from 'styles/themes/base/base';
import * as marginsPaddings from './helpers/margins-paddings';
import * as animations from './helpers/animations';

export const themes: any = {
  titan,
  tpv,
  aoc
};

const DEFAULT_THEME = 'tpv';
let themeName = Local.getTheme();
if (!themeName) {
  Local.setTheme(DEFAULT_THEME);
  themeName = DEFAULT_THEME;
}

const theme = themes[themeName] || themes[DEFAULT_THEME];

export default {
  ...marginsPaddings,
  ...animations,
  ...base,
  ...theme,
  icons: {
    ...base.icons,
    ...theme.icons
  },
  colors: {
    ...base.colors,
    ...theme.colors
  },
  gradientFrom: theme.gradientFrom || base.gradientFrom,
  gradientTo: theme.gradientTo || base.gradientTo,
  globalStyles: theme.globalStyles || base.globalStyles,
  fonts: theme.fonts || base.fonts,
  typography: {
    ...base.typography,
    ...theme.typography
  }
};
