import { Image } from 'elements/Image';
import BaseText from 'elements/Text';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Genre, Channel as IChannel } from 'store/types';
import styled from 'styled-components';
import scaleSize from 'utils/scale-size';
import {
  CHANNEL_EPG_IMAGE_WIDTH,
  CHANNEL_LIST_ITEM_HEIGHT,
  EPG_TIME_SLOT_WIDTH
} from '../../../constants';
import Channel from './Channel';

export const GenreTitle = styled(BaseText)`
  ${({ theme }) => theme.typography.secondary_txt_medium};
`;
export const ChannelListContainer = styled.div<{
  translateY: number;
  translateX: number;
}>`
  width: ${EPG_TIME_SLOT_WIDTH * 12}px; //6h - 30min
  ${({ theme }) =>
    theme.transition({
      value: 'transform',
      timing: theme.timings.s,
      easing: theme.easings.ease
    })};
  flex-shrink: 0;
  transform: ${({ translateY, translateX }) =>
    `translate3d(${translateX}px, ${translateY}px, 0)`};
  padding-left: ${scaleSize(24)}px;
`;

export const Line = styled.div<{ offset: number; translateX: number }>`
  &::before {
    content: '';
    position: absolute;
    top: ${scaleSize(64)}px;
    left: ${({ offset }) =>
      scaleSize(
        offset + 240
      )}px; //add the width of the img + 4px of the padding of the NOW red container + 16px of padding
    width: 1px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.red01};
    transform: ${({ translateX }) => `translate3d(${translateX}px, 0, 0)`};
    z-index: 15;
  }
`;

export const ImageWrapper = styled.div<{ isCurrent: boolean }>`
  width: ${CHANNEL_EPG_IMAGE_WIDTH}px;
  height: ${CHANNEL_LIST_ITEM_HEIGHT}px;
  border-radius: ${scaleSize(4)}px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ isCurrent }) => (isCurrent ? 0.8 : 1)};
`;

export const GenreTitleWrapper = styled.div`
  ${({ theme }) => theme.margin('m', 'top')};
  ${({ theme }) => theme.margin('xs', 'bottom')};
`;

export const ChannelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: ${scaleSize(8)}px;
`;

export const GenreChannelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${scaleSize(8)}px;
`;

interface ChannelListProps {
  channels: IChannel[];
  genre: Genre;
  onClose: () => void;
  reportHeight: any;
  onFocus: any;
  onArrowPress: any;
  translateY: number;
  translateX: number;
  hours: number;
  isVisible: boolean;
}

const ChannelList: FC<ChannelListProps> = ({
  reportHeight,
  channels,
  genre,
  onClose,
  onFocus,
  onArrowPress,
  translateY,
  translateX,
  hours
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [currentItem, setCurrentItem] = useState(0);

  useEffect(() => {
    ref?.current && reportHeight(ref.current?.offsetHeight);
  }, [ref.current]);

  return (
      <ChannelListContainer
        ref={ref}
        translateY={translateY}
        translateX={translateX}
      >
        <GenreTitleWrapper>
          <GenreTitle>{genre.name}</GenreTitle>
        </GenreTitleWrapper>
        <GenreChannelsWrapper>
          {channels.map((channel: IChannel, index: number) => {
            return (
              <ChannelWrapper key={`${channel.id}-genre-${genre.id}${index}`}>
                <ImageWrapper isCurrent={index === currentItem}>
                  <Image
                    src={
                      channel?.images?.artworkLandscape ||
                      channel?.images?.artworkPortrait
                    }
                  />
                </ImageWrapper>
                <Channel
                  focusKey={`${channel.id}-channel-list`}
                  hours={hours}
                  isCurrent={index === currentItem}
                  genre={genre.id}
                  onArrowPress={(direction: any, itemIndex: any, array: any) =>
                    onArrowPress(direction, itemIndex, array, index, channels)
                  }
                  channel={channel}
                  onFocus={(indexHr: number, program: any) =>
                    onFocus(index, indexHr, program)
                  }
                  onClose={onClose}
                />
              </ChannelWrapper>
            );
          })}
        </GenreChannelsWrapper>
      </ChannelListContainer>
  );
};

export default ChannelList;
