const SPECIAL_APPS = {
  netflix: 'netflix',
  prime_video: 'prime_video',
  bbc_iplayer: 'bbc_iplayer',
  bbc_sounds: 'bbc_sounds',
  itv_hub: 'itv_hub',
  all_4: 'all_4',
  demand_5: 'demand_5',
  britbox: 'britbox',
  freeview_explore: 'freeview_explore',
  youtube: 'youtube',
  rakuten_tv: 'rakuten_tv',
  internet_browser: 'internet_browser',
  free_channels: 'free_channels',
  dazn: 'dazn',
  google_assistant: 'google_assistant',
  alexa: 'alexa',
  disney: 'disney',
  demo_me: 'demo_me',
  media: 'media'
};

export default SPECIAL_APPS;
