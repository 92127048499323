import { createSlice } from '@reduxjs/toolkit';
import { fetchDetails } from './actions';
import { CAST_TYPES } from 'constants/cast-types';

type CastType = keyof typeof CAST_TYPES;

interface CastItem {
  type: CastType;
  person: { name: string };
}

interface DataItem {
  casts?: CastItem[];
  title: string;
  year: number;
  classification?: { name: string; description: string };
  images: {
    screenshot_landscape?: string;
  };
  synopsis?: string;
  deeplinkings: [
    {
      app_id: number;
      app_name: string;
      url: string;
      app: App;
    }
  ];
}

interface DetailsState {
  [type: string]: {
    [contentId: number]: {
      isLoading: boolean;
      isSuccess?: boolean;
      data?: DataItem;
      errorMessage?: any;
    };
  };
}

const initialState: DetailsState = {};

export const detailsSlice = createSlice({
  name: 'details',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchDetails.pending,
        (state, { payload, meta }: ReturnType<typeof fetchDetails.pending>) => {
          const { type, contentId } = meta.arg;
          state[type] = state[type] || {};
          state[type][contentId] = state[type][contentId] || {};
          state[type][contentId].isLoading = true;
        }
      )
      .addCase(
        fetchDetails.fulfilled,
        (
          state,
          { payload, meta }: ReturnType<typeof fetchDetails.fulfilled>
        ) => {
          const { type, contentId } = meta.arg;
          state[type][contentId].isLoading = false;
          state[type][contentId].isSuccess = true;
          state[type][contentId].data = payload;
        }
      )
      .addCase(
        fetchDetails.rejected,
        (state, { error, meta }: ReturnType<typeof fetchDetails.rejected>) => {
          const { type, contentId } = meta.arg;
          state[type][contentId].isLoading = false;
          state[type][contentId].isSuccess = false;
          state[type][contentId].errorMessage = {
            message: error.message ?? ''
          };
        }
      );
  }
});

export default detailsSlice.reducer;
