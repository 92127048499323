import React, { useEffect } from 'react';
import styled from 'styled-components';
import scaleSize from 'utils/scale-size';
import Button from 'elements/Button';
import markets from 'constants/markets';
import Local from 'utils/storage/local';
import { setFocus } from '@noriginmedia/norigin-spatial-navigation';

const savedMarket = Local.getMarket();

const ModalWrapper = styled.div`
  width: ${scaleSize(1620)}px;
  border-radius: ${scaleSize(16)}px;
  background: ${({ theme }) => theme.colors.grey3};
  max-height: 80vh;
  overflow: hidden;
`;

const ButtonsWrapper = styled.div`
  ${({ theme }) => theme.margin('l', 'top')};
  padding-bottom: ${({ theme }) => theme.marginSizes.s}px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled(Button)`
  width: ${scaleSize(50)}px;
  display: block;
  ${({ theme }) => theme.margin('l', 'right')};
  ${({ theme }) => theme.margin('l', 'left')};
  ${({ theme }) => theme.margin('s', 'bottom')};
  text-align: center;
`;

const MarketsModal: React.FC<{ data: any; closeModal: any }> = ({
  data,
  closeModal
}) => {

  useEffect(() => {
    setFocus(`set-market-${savedMarket}`);
  }, []);
  const handleClick = (market: string) => {
    closeModal();
    Local.setMarket(market);
    window.location.href = '/';
  };

  return (
    <ModalWrapper>
      <ButtonsWrapper>
        {markets.sort().map((market: any) => (
          <ButtonWrapper
            key={`market-${market}`}
            title={`${market}`}
            handleClick={() => handleClick(market)}
            focusKey={`set-market-${market}`}
          />
        ))}
      </ButtonsWrapper>
    </ModalWrapper>
  );
};

export default MarketsModal;
