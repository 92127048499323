import { createGlobalStyle } from 'styled-components';

const globalStyle = (themeGlobalStyles: any) => createGlobalStyle`
  body {
    margin: 0;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }
  ${themeGlobalStyles};
`;

export default globalStyle;
