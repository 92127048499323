import Local from './storage/local';

const MARKET_TO_LANGUAGE: DefaultKeyStringMap = {
  gb: 'en',
  es: 'es',
  li: 'de',
  de: 'de',
  it: 'it',
  lu: 'de',
  sm: 'it',
  va: 'it',
  al: 'sq',
  am: 'hy',
  at: 'de',
  az: 'az',
  bs: 'az',
  be: 'de',
  bg: 'bg',
  by: 'be',
  ch: 'de',
  cz: 'cs',
  dk: 'az',
  ee: 'et',
  fi: 'fi',
  fr: 'fr',
  ge: 'ka',
  gr: 'el',
  hr: 'hr',
  hu: 'az',
  ie: 'ga',
  kz: 'kk',
  lt: 'lt',
  lv: 'lv',
  me: 'en',
  mk: 'mk',
  nl: 'fy',
  no: 'no',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  rs: 'sr',
  ru: 'ru',
  se: 'sv',
  si: 'sl',
  sk: 'sk',
  tr: 'tr',
  ua: 'uk'
};

export default class Current {
  static market() {
    const urlParams = new URLSearchParams(window.location.search);
    let tvMarket = urlParams.get('country');
    if (tvMarket) {
      tvMarket = tvMarket.toLowerCase();
    }

    const localStorageMarket = Local.getMarket();

    if (tvMarket && tvMarket !== localStorageMarket) {
      Local.setMarket(tvMarket);
    }

    const finalMarket = tvMarket || localStorageMarket || 'gb';

    Local.setMarket(finalMarket);

    return finalMarket;
  }

  static device() {
    return 'tv';
  }

  static locale() {
    const urlParams = new URLSearchParams(window.location.search);
    let language = urlParams.get('language');

    const localStorageLanguage = Local.getLanguage();
    return (
      localStorageLanguage
        ? localStorageLanguage
        : language || MARKET_TO_LANGUAGE[this.market()] || 'en'
    ).toLowerCase();
  }

  static deviceId() {
    const urlParams = new URLSearchParams(window.location.search);
    let deviceId = urlParams.get('deviceid');
    if (deviceId) {
      deviceId = deviceId.toLowerCase();
    }
    const localStorageDeviceId = Local.getDeviceUUID();
    const finalDeviceId = deviceId || localStorageDeviceId;

    // @ts-ignore
    Local.setDeviceUUID(finalDeviceId);

    return finalDeviceId;
  }
}
