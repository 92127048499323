import { css } from 'styled-components';
import scaleSize from 'utils/scale-size';
import base from '../base/base';
import SearchIcon from './icons/SearchIcon';
import EpgIcon from './icons/EpgIcon';
import SourceIcon from './icons/SourceIcon';
import SettingsIcon from './icons/SettingsIcon';

const icons = {
  SearchIcon,
  EpgIcon,
  SourceIcon,
  SettingsIcon
};

const colors = {
  ...base.colors,
  primary: '#2BB8C2'
};

const gradientFrom = 'rgba(43, 184, 194, 0.25)';
const gradientTo = 'rgba(43, 184, 194, 0)';

const fonts = [
  {
    fontFamily: 'OpenSans',
    src: 'OpenSans-Regular.ttf',
    type: 'truetype',
    fontWeight: 400,
    fontStyle: 'normal'
  }
];

const globalStyles = css`
  ${fonts.map(
    (font) => css`
      @font-face {
        font-family: ${font.fontFamily};
        src: url('./assets/aoc/fonts/${font.src}') format('${font.type}');
        font-weight: ${font.fontWeight};
        font-style: ${font.fontStyle};
      }
    `
  )}
  body {
    font-family: ${fonts.map((font) => font.fontFamily).join(',')}, sans-serif;
  }
`;
const typography = {
  fm: css`
    font-family: 'OpenSans', sans-serif;
    font-weight: 500;
  `,
  fb: css`
    font-family: 'OpenSans', sans-serif;
    font-weight: 800;
  `,
  text: css`
    font-family: 'OpenSans', sans-serif;
    font-weight: 400;
    font-size: ${scaleSize(28)}px;
    color: ${colors.white};
  `,
  title: css`
    font-family: 'OpenSans', sans-serif;
    font-weight: 600;
    font-size: ${scaleSize(48)}px;
    color: ${colors.white02};
  `,
  button: css`
    font-family: 'OpenSans', sans-serif;
    font-weight: 300;
    font-size: ${scaleSize(32)}px;
  `,
  secondary_title: css`
    font-family: 'OpenSans', sans-serif;
    font-weight: 300;
    font-size: ${scaleSize(32)}px;
    line-height: ${scaleSize(48)}px;
    color: ${colors.white02};
  `,
  medium_header: css`
    font-family: 'OpenSans', sans-serif;
    font-weight: 500;
    font-size: ${scaleSize(64)}px;
    line-height: ${scaleSize(64)}px;
    color: ${colors.white};
  `,
  description: css`
    font-family: 'OpenSans', sans-serif;
    font-weight: 400;
    font-size: ${scaleSize(24)}px;
    font-style: normal;
    color: ${colors.gray04};
  `,
  secondary_txt_medium: css`
    font-family: 'OpenSans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: ${scaleSize(24)}px;
    color: ${colors.white};
    line-height: ${scaleSize(32)}px;
  `
};

const theme = {
  title: 'AOC',
  fonts,
  icons,
  gradientFrom,
  gradientTo,
  globalStyles,
  colors,
  typography
};

export default theme;
