import CONTENT_TYPES from 'constants/content-types';
import COLLECTION_TYPES from 'constants/collection-types';

export const searchCategories = {
  collection: [
    {
      id: 'apps_search_list',
      type: COLLECTION_TYPES.apps_list,
      name: 'Apps' // TODO use dictionary
    },
    {
      id: 'search_list',
      type: CONTENT_TYPES.movie,
      name: 'Movies' // TODO use dictionary
    },
    {
      id: 'youtube_search_list',
      type: CONTENT_TYPES.youtube_video,
      name: 'Youtube' // TODO use dictionary
    },
    {
      id: 'search_list',
      type: CONTENT_TYPES.tv_show,
      name: 'TV Shows' // TODO use dictionary
    },
    {
      id: 'search_list',
      type: CONTENT_TYPES.channel,
      name: 'Channels' // TODO use dictionary
    },
    {
      id: 'search_list',
      type: CONTENT_TYPES.sport_program,
      name: 'Sport programs' // TODO use dictionary
    },
    {
      id: 'search_list',
      type: CONTENT_TYPES.season,
      name: 'Seasons' // TODO use dictionary
    },
    {
      id: 'search_list',
      type: CONTENT_TYPES.episode,
      name: 'Episodes' // TODO use dictionary
    }
  ]
};
