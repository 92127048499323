import { DEVICE_FVP_APP_NAMES } from '../devices/TPV/fetchDeviceList';
import CONTENT_TYPES from '../constants/content-types';
import Local from './storage/local';

export default function getFVPSortedApps(
  collection: any,
  tvApps: MetadataApp[],
  sort: boolean
) {
  const result = [];

  const localApps = Local.getFvpPositions();

  result.push(collection[0]);
  result.push(collection[1]);

  // TV apps with description 0
  for (const app of tvApps) {
    if (app.appDescription === 0) {
      const matchingIndex = collection.findIndex(
        (item: any) =>
          item.code in DEVICE_FVP_APP_NAMES &&
          DEVICE_FVP_APP_NAMES[item.code] === app.appTitle
      );

      if (matchingIndex !== -1) {
        result.push({
          ...collection[matchingIndex],
          icon: app.appImageURI,
          url: app.appLandingURL,
          isFVPApp: true
        });
        collection.splice(matchingIndex, 1);
      } else {
        const appCode = app.appTitle.toLowerCase().replace(' ', '_');
        result.push({
          id: `_fvp_${appCode}`,
          type: CONTENT_TYPES.app,
          code: appCode,
          name: app.appTitle,
          icon: app.appImageURI,
          url: app.appLandingURL,
          position:
            localApps.find((app: any) => app.code === appCode)?.position ||
            null,
          isFVPApp: true
        });
      }
    }
  }

  // Other apps from the collection
  for (let i = 2; i < collection.length; i++) {
    result.push(collection[i]);
  }

  // TV apps with description 1
  for (const app of tvApps) {
    if (app.appDescription === 1) {
      const appCode = app.appTitle.toLowerCase().replace(' ', '_');
      result.push({
        id: `_fvp_${appCode}`,
        type: CONTENT_TYPES.app,
        code: appCode,
        name: app.appTitle,
        icon: app.appImageURI,
        url: app.appLandingURL,
        position:
          localApps.find((app: any) => app.code === appCode)?.position || null,
        isFVPApp: true
      });
    }
  }

  if (sort) {
    return result.sort((a: any, b: any) => a.position - b.position);
  }

  return result.map((item: any, position: number) => ({ ...item, position }));
}
