import React from 'react';
import styled from 'styled-components';
import scaleSize from 'utils/scale-size';
import Button from 'elements/Button';
import { themes } from 'styles/theme';
import Local from 'utils/storage/local';

const ModalWrapper = styled.div`
  width: ${scaleSize(720)}px;
  border-radius: ${scaleSize(16)}px;
  background: ${({ theme }) => theme.colors.grey3};
  max-height: 80vh;
  overflow: hidden;
`;

const ButtonsWrapper = styled.div`
  ${({ theme }) => theme.margin('l', 'top')};
  padding-bottom: ${({ theme }) => theme.marginSizes.s}px;
`;

const ButtonWrapper = styled(Button)`
  display: block;
  ${({ theme }) => theme.margin('l', 'right')};
  ${({ theme }) => theme.margin('l', 'left')};
  ${({ theme }) => theme.margin('s', 'bottom')};
  text-align: center;
`;

const ThemeModal: React.FC<{ data: any; closeModal: any }> = ({
  data,
  closeModal
}) => {
  const handleClick = (theme: string) => {
    closeModal();
    Local.setTheme(theme);
    window.location.reload();
  };

  return (
    <ModalWrapper>
      <ButtonsWrapper>
        {Object.keys(themes).map((theme: any) => (
          <ButtonWrapper
            key={`theme-${themes[theme].title}`}
            title={`${themes[theme].title}`}
            handleClick={() => handleClick(theme)}
            focusKey={`set-theme-${themes[theme].title}`}
          />
        ))}
      </ButtonsWrapper>
    </ModalWrapper>
  );
};

export default ThemeModal;
