import collectionTypes from 'constants/collection-types';
import { DEVICE_FVP_APP_NAMES } from 'devices/TPV/fetchDeviceList';

const FVP_LISTS: (string | keyof typeof collectionTypes)[] = [
  collectionTypes.bbc_list,
  collectionTypes.itvhub_list,
  collectionTypes.all4_list,
  collectionTypes.freeview_list,
  collectionTypes.my5_list
];

export default function isFVPElement(
  identifier: string | keyof typeof collectionTypes
): boolean {
  return (
    DEVICE_FVP_APP_NAMES.hasOwnProperty(identifier) || isFVPList(identifier)
  );
}

function isFVPList(identifier: string | keyof typeof collectionTypes): boolean {
  return FVP_LISTS.includes(identifier);
}
