import { marginSizes } from '../base';
import { css } from 'styled-components';

const getValue = (size: string | number): string => {
  let sizeValue = `${size}`.replace('-', '');
  let sizeSign = `${size}`.indexOf('-') === 0 ? '-' : '';

  if (sizeValue && marginSizes[sizeValue]) {
    return `${sizeSign}${marginSizes[sizeValue]}px`;
    // @ts-ignore
  } else if (!isNaN(sizeValue)) {
    return `${sizeSign}${sizeValue}px`;
  } else {
    console.warn(`Size [${sizeValue}] is unsupported`);
    return `0px`;
  }
};

const getMarginPadding = (
  values: Array<string | number> | string | number,
  side: string,
  isMargin: boolean
): any => {
  const mp = isMargin ? `margin${side}` : `padding${side}`;
  if (Array.isArray(values)) {
    return css`
      ${mp}: ${values.map((value) => getValue(value)).join(' ')};
    `;
  } else {
    return css`
      ${mp}: ${getValue(values)};
    `;
  }
};

export const margins = (values: Array<string | number> | string | number) => {
  return getMarginPadding(values, '', true);
};

export const paddings = (values: Array<string | number> | string | number) => {
  return getMarginPadding(values, '', false);
};

export const margin = (value: string, side: string) => {
  return getMarginPadding(value, `-${side}`, true);
};

export const padding = (value: string, side: string) => {
  return getMarginPadding(value, `-${side}`, false);
};
