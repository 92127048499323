import React, { useEffect, useState } from 'react';
import { showModal } from 'store/features/modals/actions';
import MODAL_TYPES from 'constants/modal-types';
import { useAppDispatch } from 'hooks/hooks';
import Local from 'utils/storage/local';

export interface DevActionWithModal {
  type: 'modal';
  modal: keyof typeof MODAL_TYPES;
  title: string;
  isHidden?: boolean;
}

export interface DevActionWithFunction {
  type: 'function';
  title: string;
  fn: () => void;
  isHidden?: boolean;
}

type DevAction = DevActionWithModal | DevActionWithFunction;

type DevActions = {
  [key: string]: DevAction;
};

export const devActions: DevActions = {
  ArrowLeft1111ArrowRight: {
    type: 'modal',
    modal: MODAL_TYPES.DEV_ACTIONS,
    title: 'List all actions',
    isHidden: true
  },
  ArrowLeft1122ArrowRight: {
    type: 'modal',
    modal: MODAL_TYPES.THEME,
    title: 'Change theme'
  },
  ArrowLeft3344ArrowRight: {
    type: 'modal',
    modal: MODAL_TYPES.MARKETS,
    title: 'Change market'
  },
  ArrowLeft4455ArrowRight: {
    type: 'modal',
    modal: MODAL_TYPES.VERSION,
    title: 'Show current version'
  },
  ArrowLeft5566ArrowRight: {
    type: 'function',
    title: 'Toggle animations',
    fn: () => {
      const animationsEnabled = Local.getAnimations();
      Local.setAnimations(!animationsEnabled);
      window.location.reload();
    }
  }
};

const DevTools: React.FC = () => {
  const dispatch = useAppDispatch();
  const [currentInput, setCurrentInput] = useState('');
  const actions: DefaultKeyFunctionMap = {
    ArrowLeft1122ArrowRight: () =>
      dispatch(showModal(MODAL_TYPES.THEME, {}, '')),
    ArrowLeft2233ArrowRight: () =>
      dispatch(showModal(MODAL_TYPES.ENVS, {}, '')),
    ArrowLeft3344ArrowRight: () =>
      dispatch(showModal(MODAL_TYPES.MARKETS, {}, '')),
    ArrowLeft4455ArrowRight: () =>
      dispatch(showModal(MODAL_TYPES.VERSION, {}, ''))
  };

  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      setCurrentInput((input) => `${input}${e.key}`.slice(-100));
    };
    document.addEventListener('keyup', onKeyUp);

    return () => document.removeEventListener('keyup', onKeyUp);
  }, []);

  useEffect(() => {
    Object.keys(devActions).forEach((command) => {
      if (currentInput.slice(-command.length) === command) {
        const action = devActions[command];
        if (action.type === 'modal') {
          dispatch(showModal(MODAL_TYPES[action.modal], {}, ''));
        } else if (action.type === 'function') {
          action.fn();
        }
      }
    });
  }, [currentInput]);

  return <></>;
};

export default DevTools;
