import { useEffect } from 'react';
import {
  APP_ROW_UPDATES,
  APP_ROW_UPDATES_CLEAR
} from 'constants/app-rows-updates-lists';

const useAppRowUpdate = (listType: string, callback: CallableFunction) => {
  useEffect(() => {
    const onAppRowUpdate = (e: any) => {
      if (APP_ROW_UPDATES[listType] === e.AppName) {
        console.log('==> onAppRowUpdate', listType, e.AppName);
        callback();
      }
      if (APP_ROW_UPDATES_CLEAR[listType] === e.AppName) {
        console.log('==> onAppRowClear', listType, e.AppName);
        callback(APP_ROW_UPDATES_CLEAR[listType]);
      }
    };

    document.addEventListener('AppRowUpdate', onAppRowUpdate);

    return () => document.removeEventListener('AppRowUpdate', onAppRowUpdate);
  }, []);

  return null;
};
export default useAppRowUpdate;
