import React from 'react';
import theme from 'styles/theme';
import scaleSize from 'utils/scale-size';

type IconProps = {
  isFocused: boolean;
};

const SourceIcon: React.FC<IconProps> = ({ isFocused }) => {
  const size = scaleSize(32);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
    >
      <mask
        id="mask0_212_147"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="-1"
        y="-1"
        width="34"
        height="34"
      >
        <path
          d="M33 -1H-1V33H33V-1Z"
          fill={isFocused ? theme.colors.white : theme.colors.white06}
        />
      </mask>
      <g mask="url(#mask0_212_147)">
        <mask
          id="mask1_212_147"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="-1"
          y="-1"
          width="34"
          height="34"
        >
          <path
            d="M33 -1H-1V33H33V-1Z"
            fill={isFocused ? theme.colors.white : theme.colors.white06}
          />
        </mask>
        <g mask="url(#mask1_212_147)">
          <path
            d="M26.625 3.25L30.875 7.5V24.5H26.625V3.25Z"
            fill={isFocused ? theme.colors.white : theme.colors.white06}
          />
          <path
            d="M2.53812 7.5V13.1737H6.78812V7.5H25.2119V3.25H6.78812L2.53812 7.5Z"
            fill={isFocused ? theme.colors.white : theme.colors.white06}
          />
          <path
            d="M22.375 16.5313L14.8631 9.20001V14.4573H1.125V18.601H14.8631V23.8625L22.375 16.5313Z"
            fill={isFocused ? theme.colors.white : theme.colors.white06}
          />
          <path
            d="M6.78812 25.5625V19.8887H2.53812V25.5625L6.78812 29.8125H26.625L30.875 25.5625H6.78812Z"
            fill={isFocused ? theme.colors.white : theme.colors.white06}
          />
        </g>
      </g>
    </svg>
  );
};

export default SourceIcon;
