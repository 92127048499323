import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEVICE_LISTS, fetchList } from 'store/features/lists/actions';
import CONTENT_TYPES from 'constants/content-types';
import COLLECTION_TYPES from 'constants/collection-types';

interface ListPagination {
  currentPage: number;
  nextPage?: number | null;
  prevPage?: number | null;
  totalPages: number;
  totalCount: number;
  perPage: number;
}

export interface ListStatus {
  isLoading: boolean;
  lastQuery?: string;
  isSuccess: boolean;
  errorMessage?: string;
  pagination: ListPagination;
  data: ListData;
}

interface ListData {
  title: string | null;
  collection: ListContent[];
}

interface ListContent {
  id: number;
  type: CONTENT_TYPES;
  position: number;
}

type ListState = {
  [key: string]: ListStatus;
};

const initialListStatus: ListStatus = {
  isLoading: false,
  isSuccess: false,
  errorMessage: undefined,
  lastQuery: undefined,
  pagination: {
    currentPage: 0,
    nextPage: null,
    prevPage: null,
    totalPages: 0,
    totalCount: 0,
    perPage: 0
  },
  data: {
    title: null,
    collection: []
  }
};

const initialState: ListState = {};

export const listsSlice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    removeListsByPrefix: (
      state: ListState,
      { payload }: PayloadAction<{ prefix: string }>
    ) => {
      Object.keys(state).forEach((listKey) => {
        if (listKey.indexOf(payload.prefix) === 0) {
          delete state[listKey];
        }
      });
    },
    addChannelToListFavorites: (state, { payload: { channel } }) => {
      Object.keys(state).forEach((listKey) => {
        const list = state[listKey];
        listKey.includes(COLLECTION_TYPES.favorites_list) &&
          list.data.collection.push(channel);
      });
    },
    deleteChannelFromListFavorites: (state, { payload: { channel } }) => {
      Object.keys(state).forEach((listKey) => {
        const list = state[listKey];
        if (listKey.includes(COLLECTION_TYPES.favorites_list)) {
          list.data.collection = list.data.collection.filter(
            (i) => i.id !== channel.id
          );
        }
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchList.pending,
        (state, { meta }: ReturnType<typeof fetchList.pending>) => {
          const { listId, query } = meta.arg;

          const overwriteList =
            state[listId]?.lastQuery &&
            query &&
            state[listId].lastQuery !== query;
          const listData = overwriteList ? initialListStatus : state[listId];
          state[listId] = listData || {};
          state[listId].isLoading = true;
          state[listId].lastQuery = query;
        }
      )
      .addCase(
        fetchList.fulfilled,
        (
          state: ListState,
          { payload, meta }: ReturnType<typeof fetchList.fulfilled>
        ) => {
          const { listId } = meta.arg;
          //TODO check if we really receive data with duplicates
          // and need this piece of code
          const existingCollectionIds = state[listId]?.data?.collection
            ? new Set(
                state[listId].data.collection.map(
                  (item) => `${item.id}-${item.type}`
                )
              )
            : new Set();
          const listType = meta?.arg?.data?.type;
          let collection: any[];
          if (listType && DEVICE_LISTS.includes(listType)) {
            collection = payload?.collection || [];
          } else {
            const newCollectionItems =
              payload?.collection?.filter(
                (item: ListContent) =>
                  !existingCollectionIds.has(`${item.id}-${item.type}`)
              ) || [];
            collection = [
              ...(state[listId]?.data?.collection || []),
              ...newCollectionItems
            ];
          }
          // When performing search the results may have already been
          // removed, so checking if the list still exists
          if (state[listId]) {
            state[listId].isLoading = false;
            state[listId].isSuccess = true;
            state[listId].pagination = payload?.pagination;
            state[listId].data = {
              collection,
              title: payload.title || null
            };
          }
        }
      )
      .addCase(fetchList.rejected, (state, { payload, meta }) => {
        const { listId } = meta.arg;
        if (state[listId]) {
          state[listId].isLoading = false;
          state[listId].isSuccess = false;
          state[listId].errorMessage = payload as string;
        }
      });
  }
});

export default listsSlice.reducer;
