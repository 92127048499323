import React, { FC, useCallback, useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/hooks';

import { showModal } from 'store/features/modals/actions';
import { setColor } from 'store/features/app/actions';

import artworkImage from 'utils/artwork-image';
import resizeImage from 'utils/resize-image';
import getImageColor from 'utils/get-image-color';
import formatDuration from 'utils/format-duration';
import { ActionSource, executeAction } from 'utils/actions';

import CONTENT_TYPES from 'constants/content-types';
import ARTWORK_SIZES from 'constants/artwork-sizes';
import MODAL_TYPES from 'constants/modal-types';
import CONTENT_TYPE_TO_ARTWORK_TYPE from 'constants/content-type-to-artwork-type';

import theme from 'styles/theme';
import Marquee from 'components/Marquee';
import {
  AppTitle,
  AppTitleContainer,
  EditChevronLeft,
  EditChevronRight,
  ListItemTitle,
  ListItemWrapper,
  Metadata,
  RemoveButton
} from './styles';
import { ListItemProps } from './types';
import Artwork from 'components/Artwork';

const { CloseIcon } = theme.icons;

const SET_AMBILIGHT_COLOR = false;
const MAX_APP_TITLE_LENGTH = 9;
const MAX_RECOMENDATION_TITLE_LENGTH = 34;

const ListItem: FC<ListItemProps> = ({
  data,
  focusKey,
  itemIndex,
  onArrowPress,
  onEnterPress,
  editMode = false,
  isEdited = false,
  openAppDirectly,
  onFocus,
  onBlur,
  hideMetadata,
  showArtworkTitle,
  removeFocused,
  preventDefaultOnEnter,
  canMoveLeft,
  canMoveRight,
  ArtworkOverlayComponent,
  children
}) => {
  const navigate = useNavigate();
  const artworkType = CONTENT_TYPE_TO_ARTWORK_TYPE[data.type];
  const imageColorRef = useRef('#000000');
  const dispatch = useAppDispatch();
  const [isFocused, setIsFocused] = useState(false);

  const imageSize = {
    width: String(Math.round(ARTWORK_SIZES[artworkType]?.width * 2)),
    height: String(Math.round(ARTWORK_SIZES[artworkType]?.height * 2))
  };

  const src = resizeImage(artworkImage(data) || null, imageSize);
  const focusedSrc = resizeImage(artworkImage(data, true) || null, imageSize);

  const imgSrc = isFocused && focusedSrc ? focusedSrc : src;

  useEffect(() => {
    SET_AMBILIGHT_COLOR &&
      getImageColor(src, data).then((color) => {
        imageColorRef.current = color;
      });
  }, []);

  const onEnterPressCallback = useCallback(() => {
    if (!isEdited && !editMode) {
      onEnterPress && onEnterPress(focusKey);
      if (preventDefaultOnEnter) {
        return;
      }
      if (data.action) {
        executeAction(data.action, data);
      } else if (data.type === CONTENT_TYPES.genre) {
        navigate(`/search/?q=${data.name}`, { replace: true });
      } else if (data.type === CONTENT_TYPES.channel) {
        navigate(`/channels/${data.id}`);
      } else if (data.type === CONTENT_TYPES.app) {
        if (openAppDirectly) {
          executeAction('launch-app', {
            app: data,
            source: ActionSource.FAVOURITE_APPS
          });
        } else {
          navigate(`/details/${data.type}/${data.id}`, {
            state: { app: data }
          });
        }
      } else if (!data.id && data.deeplinkings) {
        executeAction('launch-program', data.deeplinkings[0]);
      } else {
        dispatch(showModal(MODAL_TYPES.DEEPLINK, data, focusKey));
      }
    }
  }, [data, focusKey, editMode, isEdited, onEnterPress]);

  const classNames = [];
  isEdited && classNames.push('is-edited');
  editMode && classNames.push('edit-mode');

  let metadata = [];
  if (data.type !== CONTENT_TYPES.app && data.title && isFocused) {
    data.year && metadata.push(data.year);
    data.duration_in_seconds &&
      metadata.push(formatDuration(data.duration_in_seconds));
  }

  return (
    <ListItemWrapper
      type={artworkType}
      focusKey={focusKey}
      onFocus={() => {
        SET_AMBILIGHT_COLOR && dispatch(setColor(imageColorRef.current));
        setIsFocused(true);
        onFocus && onFocus();
      }}
      onBlur={() => {
        onBlur && onBlur();
        setIsFocused(false);
      }}
      editMode={editMode}
      className={classNames.join(' ')}
      onEnterRelease={!isEdited && !editMode ? onEnterPressCallback : () => {}}
      onArrowPress={onArrowPress}
      extraProps={{
        'data-item-name': data.name || data.title || data.appId || data.position,
        itemIndex
      }}
    >
      <Artwork src={imgSrc} type={artworkType} />
      {ArtworkOverlayComponent && <ArtworkOverlayComponent />}
      {showArtworkTitle && <ListItemTitle>{data.name}</ListItemTitle>}
      {isEdited && !removeFocused && canMoveLeft && (
        <EditChevronLeft color={'white'} />
      )}
      {isEdited && !removeFocused && canMoveRight && (
        <EditChevronRight color={'white'} />
      )}
      {isEdited && (
        <RemoveButton isFocused={removeFocused}>
          <CloseIcon color={removeFocused ? 'black' : 'white'} />
        </RemoveButton>
      )}

      {data.type === CONTENT_TYPES.app &&
        data.name &&
        isFocused &&
        !editMode && (
          <AppTitleContainer type={artworkType}>
            <AppTitle>
              <Marquee
                enabled={data.name.length > MAX_APP_TITLE_LENGTH}
                text={data.name}
              />
            </AppTitle>
          </AppTitleContainer>
        )}
      {data.type !== CONTENT_TYPES.app &&
        data.title &&
        isFocused &&
        !hideMetadata && (
          <Metadata type={artworkType}>
            {showArtworkTitle && (
              <Marquee
                enabled={data.title.length > MAX_RECOMENDATION_TITLE_LENGTH}
                text={data.title}
              />
            )}
            <span>{metadata.join(' • ')}</span>
          </Metadata>
        )}
      {children}
    </ListItemWrapper>
  );
};
export default ListItem;
