import { differenceInMinutes, parseISO } from 'date-fns';
import { FC } from 'react';
import { Channel as IChannel, Timeline as ITimeline } from 'store/types';
import styled from 'styled-components';
import { roundToNearestHalfHour } from 'utils/helpers';
import scaleSize from 'utils/scale-size';
import { CHANNEL_LIST_ITEM_HEIGHT } from '../../../constants';
import Program from './Program';

const removeDuplicates = (arr: ITimeline[]): ITimeline[] | boolean => {
  const seen = new Map();

  return arr.filter((item) => {
    if (seen.has(item.content.id)) {
      return false;
    } else {
      seen.set(item.content.id, true);
      return true;
    }
  });
};

const getOffset = (
  isFirstItem: boolean,
  totalDuration: number,
  program: any,
  prevProgram?: any
) => {
  let prev = roundToNearestHalfHour(new Date());
  if (!isFirstItem) {
    prev = parseISO(prevProgram.endTime);
  }
  const start = parseISO(program.startTime);
  if (start.getTime() > prev.getTime()) {
    return (differenceInMinutes(start, prev) / totalDuration) * 100;
  } else {
    return 0;
  }
};

interface ChannelProps {
  genre: number;
  hours: number;
  channel: IChannel;
  focusKey?: string;
  isCurrent?: boolean;
  onArrowPress?: any;
  onClose: () => void;
  onFocus?: any;
}

export const List = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${CHANNEL_LIST_ITEM_HEIGHT}px;
  gap: ${scaleSize(8)}px;
`;

const sortTimeline = (channel: IChannel): any => {
  const now = new Date();
  const rounded = roundToNearestHalfHour(now);
  const sorted = [...channel?.timeline]
    .filter(
      (timelineItem: ITimeline) =>
        new Date(timelineItem?.endTime).getTime() > rounded.getTime()
    )
    .sort(
      (a: ITimeline, b: ITimeline) =>
        new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
    );
  return removeDuplicates(sorted);
};

const Channel: FC<ChannelProps> = ({
  channel,
  onFocus,
  genre,
  onArrowPress,
  onClose,
  hours
}) => {

  const onArrowPressHandler = (
    direction?: string,
    extraProps?: { itemIndex: number }
  ) => {
    if (direction === 'right' && extraProps) {
      return extraProps.itemIndex < channel?.timeline?.length;
    }
    if (direction === 'left') {
      return true;
    }

    onArrowPress(direction, extraProps?.itemIndex, channel?.timeline);
  };

  const sortedPrograms = sortTimeline(channel);
  let runningOffset = 0;

  return (
    <List key={`focusable-${channel.id}`}>
        {sortedPrograms?.map((pr: ITimeline, index: number) => {
          if (
            new Date(pr.endTime).getTime() <=
            roundToNearestHalfHour(new Date()).getTime()
          ) {
            return null;
          }
          const totalDuration = hours * 60;
          const offsetPercentage = getOffset(
            index === 0,
            totalDuration,
            pr,
            sortedPrograms[index - 1]
          );
          runningOffset += offsetPercentage;
          return (
            <Program
              program={pr}
              key={`${pr.content.id}-genre${genre}`}
              focusKey={`${pr.content.id}-parentId-${pr.content.parentId}`}
              onClose={onClose}
              hours={hours}
              offset={runningOffset}
              onArrowPress={onArrowPressHandler}
              onFocus={() => onFocus(index, pr)}
              itemIndex={index}
            />
          );
        })}
    </List>
  );
};

export default Channel;
