import React from 'react';
// import { useAppSelector } from 'hooks/hooks';

interface LoaderProps {
  showLoader: boolean;
}

// let loaderTimeout: ReturnType<typeof setTimeout>;

const Loader: React.FC<LoaderProps> = ({ showLoader }) => {
  // console.log('==> showLoader', showLoader);
  // const progress = useAppSelector((state) => state.loader.progress);
  /*useEffect(() => {
    const loader = document.getElementById('loader');
    if (loader) {
      clearTimeout(loaderTimeout);
      loader.style.opacity = showLoader ? '1' : '0';
      loaderTimeout = setTimeout(
        () => {
          loader.style.display = showLoader ? 'flex' : 'none';
        },
        showLoader ? 0 : 1000
      );
    }
  }, [showLoader]);

  useEffect(() => {
    const progressElement = document.querySelector('.progress-bar-fill') as HTMLElement;
    if (progressElement) {
      progressElement.style.width = `${progress}%`;
    }

  }, [progress]);*/

  return null;
};

export default Loader;
