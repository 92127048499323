import { init as initLayoutEngine } from '@noriginmedia/norigin-spatial-navigation';
import App from 'components/App';
import { initializeDevice } from 'devices/initializeDevice';
import 'locales/i18n';
import { ObservabilityProvider } from 'providers/Observability';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'store/configure-store';
import { ThemeProvider } from 'styled-components';
import globalStyle from 'styles/Global.styles';
import theme from 'styles/theme';
const GlobalStyle = globalStyle(theme.globalStyles);

initLayoutEngine({
  throttle: 100,
  debug: process.env.NODE_ENV === 'development',
  // visualDebug: true
});

(async () => {
  const deviceInfo = await initializeDevice();
  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
    <React.StrictMode>
      <ObservabilityProvider>
        <Provider store={store}>
          <GlobalStyle />
          <ThemeProvider theme={theme}>
            <App deviceInfo={deviceInfo} />
          </ThemeProvider>
        </Provider>
      </ObservabilityProvider>
    </React.StrictMode>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
