import { setFocus } from '@noriginmedia/norigin-spatial-navigation';
import { useAppSelector } from './hooks';
import usePathname from 'hooks/usePathname';

interface ListsContainerProps {
  overridePathname?: string;
  defaultFocusKey?: string;
}

const DEFAULT_FALLBACK = 'menu';

const useNavigation = ({
  overridePathname,
  defaultFocusKey
}: ListsContainerProps) => {
  const { pathname } = usePathname();
  const { history } = useAppSelector(
    (state) => state.navigation.routes?.[pathname] || { history: [] }
  );

  const focusLast = () => {
    const last = history
      .slice()
      .reverse()
      .find((key) => {
        const focusableExists = !!document.getElementById(key);
        return focusableExists;
      });

    setFocus(last || defaultFocusKey || DEFAULT_FALLBACK);
  };

  return { focusLast };
};
export default useNavigation;
