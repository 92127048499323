import styled from 'styled-components';
import Focusable from 'components/Navigation/Focusable';
import scaleSize from 'utils/scale-size';

export const MenuWrapper = styled.div<{
  isMenuVisible: boolean;
  menuOpacity?: number;
  isBlurred?: boolean;
}>`
  display: ${({ isMenuVisible }) => (isMenuVisible ? 'flex' : 'none')};
  flex-direction: row;
  position: absolute;
  align-content: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  ${({ theme }) => theme.padding('xxl', 'left')};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray02}`};
  height: ${scaleSize(112)}px;
`;

export const MenuHidden = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.marginSizes.s}px;
  left: 50%;
  transform: translateX(-50%);
  width: ${scaleSize(120)}px;
  height: ${scaleSize(48)}px;
  border-radius: 100px;
  //border: ${({ theme }) => `1px solid ${theme.colors.white04}`};
  //background: ${({ theme }) => theme.colors.gray02};
  border: ${scaleSize(1)}px solid rgba(255, 255, 255, 0.2);
  background: rgba(53, 53, 53, 0.4);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const MenuItem = styled(Focusable)<{
  isCurrent: boolean;
  isFocused: boolean;
  isBlurred: boolean;
}>`
  ${({ theme }) => theme.typography.secondary_title};
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 1px;
  padding: ${scaleSize(8)}px ${scaleSize(24)}px ${scaleSize(8)}px
    ${scaleSize(24)}px;
  color: ${({ isBlurred, isFocused, isCurrent, theme }) =>
    (!isBlurred && !isFocused) || (isBlurred && isCurrent) ? theme.colors.white06 : theme.colors.gray03};

  &:before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: ${scaleSize(4)}px;
    background: ${({ isCurrent, isFocused, isBlurred, theme }) =>
      (isFocused && isCurrent) || (isBlurred && isCurrent)
        ? `${theme.colors.white06}`
        : 'transparent'};
  }

  &:hover,
  &:focus,
  &.focused {
    color: ${({ theme, isFocused, isCurrent }) =>
      isFocused || isCurrent ? theme.colors.white : theme.colors.white04};
    background: linear-gradient(
      to top,
      ${({ theme }) => theme.gradientFrom} 0%,
      ${({ theme }) => theme.gradientTo}
    );

    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: ${scaleSize(4)}px;
      background: ${({ theme }) => theme.colors.primary};
    }
  }
`;
