import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';
import { RootState } from 'store/configure-store';

interface FetchPagePayload {
  pageId: string;
}

const DEFAULT_PAGE = 1;
const PER_PAGE = 50;

export const fetchPage = createAsyncThunk<
  any,
  FetchPagePayload,
  { state: RootState }
>('pages/fetchPage', async ({ pageId }, { rejectWithValue }) => {
  try {
    return await api({
      endpoint: `/pages/${pageId}/items`,
      withAuthHeader: true,
      queryParams: {
        page: DEFAULT_PAGE,
        per_page: PER_PAGE
      }
    });
  } catch (err) {
    let error: Error = err as Error;
    return rejectWithValue(error.message);
  }
});
