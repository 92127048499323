import { createSlice } from '@reduxjs/toolkit';
import { ACTION_TYPES } from 'store/features/toasts/actions';

const initialState = {
  toast: {
    text: null
  }
};

export const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => action.type === ACTION_TYPES.SHOW_TOAST,
        (state, action) => {
          state.toast.text = action.payload.toastText;
        }
      )
      .addMatcher(
        (action) => action.type === ACTION_TYPES.REMOVE_TOAST,
        (state) => {
          state.toast.text = null;
        }
      );
  }
});

export default toastsSlice.reducer;
