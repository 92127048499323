import { css } from 'styled-components';
import scaleSize from 'utils/scale-size';
import base from '../base/base';
import SettingsIcon from './icons/SettingsIcon';
import SourceIcon from './icons/SourceIcon';
import EpgIcon from './icons/EpgIcon';

const icons = {
  SettingsIcon,
  SourceIcon,
  EpgIcon
};

const colors = {
  ...base.colors,
  primary: '#337FBF',
  primaryText: '#ffffff',
  secondaryText: '#ffffff',
  white1: 'rgba(255, 255, 255, 0.1)',
  white005: 'rgba(255, 255, 255, 0.05)',
  gray03: 'rgba(255, 255, 255, 0.3)'
};

const buttonBorderRadius = scaleSize(60);

const fonts = [
  {
    fontFamily: 'NeueFrutigerWorld',
    src: 'NeueFrutigerWorld-Regular.ttf',
    type: 'truetype',
    fontWeight: 500,
    fontStyle: 'normal'
  },
  {
    fontFamily: 'NeueFrutigerWorldMedium',
    src: 'NeueFrutigerWorld-Medium.ttf',
    type: 'truetype',
    fontWeight: 300,
    fontStyle: 'normal'
  },
  {
    fontFamily: 'NeueFrutigerWorldBlack',
    src: 'NeueFrutigerWorld-Black.ttf',
    type: 'truetype',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  {
    fontFamily: 'NeueFrutigerWorldIt',
    src: 'NeueFrutigerWorld-BlackIt.ttf',
    type: 'truetype',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  {
    fontFamily: 'NeueFrutigerWorldBold',
    src: 'NeueFrutigerWorld-Bold.ttf',
    type: 'truetype',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  {
    fontFamily: 'NeueFrutigerWorldHeavy',
    src: 'NeueFrutigerWorld-Heavy.ttf',
    type: 'truetype',
    fontWeight: 400,
    fontStyle: 'normal'
  }
];

const globalStyles = css`
  ${fonts.map(
    (font) => css`
      @font-face {
        font-family: ${font.fontFamily};
        src: url('./assets/tpv/fonts/${font.src}') format('${font.type}');
        font-weight: ${font.fontWeight};
        font-style: ${font.fontStyle};
      }
    `
  )}
  body {
    font-family: ${fonts.map((font) => font.fontFamily).join(',')}, sans-serif;
  }
`;

const gradientFrom = 'rgba(51, 127, 191, 0.25)';
const gradientTo = 'rgba(51, 127, 191, 0)';

const typography = {
  fm: css`
    font-family: 'NeueFrutigerWorld', sans-serif;
    font-weight: 500;
  `,
  fb: css`
    font-family: 'NeueFrutigerWorld', sans-serif;
    font-weight: 800;
  `,
  text: css`
    font-family: 'NeueFrutigerWorld', sans-serif;
    font-weight: 400;
    font-size: ${scaleSize(28)}px;
    color: ${colors.white};
  `,
  title: css`
    font-family: 'NeueFrutigerWorld', sans-serif;
    font-weight: 600;
    font-size: ${scaleSize(48)}px;
    color: ${colors.white02};
  `,
  button: css`
    font-family: 'NeueFrutigerWorld', sans-serif;
    font-weight: 300;
    font-size: ${scaleSize(32)}px;
  `,
  secondary_title: css`
    font-family: 'NeueFrutigerWorldMedium', sans-serif;
    font-weight: 300;
    font-size: ${scaleSize(32)}px;
    line-height: ${scaleSize(48)}px;
    color: ${colors.white02};
  `,
  medium_header: css`
    font-family: 'NeueFrutigerWorld', sans-serif;
    font-weight: 500;
    font-size: ${scaleSize(64)}px;
    line-height: ${scaleSize(64)}px;
    color: ${colors.white};
  `,
  description: css`
    font-family: 'NeueFrutigerWorld', sans-serif;
    font-weight: 400;
    font-size: ${scaleSize(24)}px;
    font-style: normal;
    color: ${colors.gray04};
  `,
  secondary_txt_medium: css`
    font-family: 'NeueFrutigerWorld', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: ${scaleSize(24)}px;
    color: ${colors.white};
    line-height: ${scaleSize(32)}px;
  `
};

const theme = {
  title: 'Philips',
  icons,
  gradientFrom,
  gradientTo,
  globalStyles,
  colors,
  fonts,
  typography,
  buttonBorderRadius
};

export default theme;
